import React from "react";

// Customizable Area Start
import { Box, Grid , styled , Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper , Button ,IconButton , Menu, MenuItem ,   Dialog,
  Tabs,Tab,Divider,
  DialogTitle,
  DialogContent,
  DialogActions ,
  DialogContentText ,Avatar,TextField,InputAdornment , ListItemIcon, ListItemText,
  withStyles} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { StyledRoot } from "../../dashboard/src/utility.web";
import { withSnackbar } from "../../../components/src/toastweb";
import { withTranslation } from "react-i18next";
import {  imgApproved , imgRejected , imgPending, invoicesIcon, customersIcon } from "./assets";
import CustomDatePicker from "../../../components/src/DatePicker";
import {  configJSON } from "./TimesheetController.web";
import withPermission from "../../../components/src/withPermission.web"
import moment from "moment";
import CloseIcon from '@material-ui/icons/Close';
import { CONSTANTS } from '../../../components/src/theme';
import Autocomplete from '@material-ui/lab/Autocomplete';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { searchIcon } from "../../CustomisableUserProfiles/src/assets";
import {ThreedotsIcon} from "../src/assets"
// Customizable Area End

import TimesheetController, {
  Props,
} from "./TimesheetController.web";
import { EmailAccount } from "./TimeSheetWeeklyController";
import { t } from "i18next";

// Customizable Area Start
interface TimesheetAttributes {
  id: number;
  account_id: number;
  associate_id: number;
  associate_type: string;
  status: string | null;
  date: string;
  start_time: string;
  end_time: string;
  employee_id: number;
  description: string | null;
  rate: number | null;
  billable: boolean;
  created_at: string;
  updated_at: string;
  customer_name: string;
  duration: string
}

export interface TimesheetType {
  id: string;
  type: string;
  attributes: TimesheetAttributes;
}
// Customizable Area End

export class Timesheet extends TimesheetController {
  constructor(props: Props) {
    super(props);
  }
 // istanbul ignore next
 getStyle=(lang:string)=> {
  return lang === "ar"?"table-align":""
}
  /* istanbul ignore next */ 
  empDetails=() =>{
    return (
      <Grid item  
      style={webStyle.userDetails}>
      {this.state.selectedEmployee && this.state.tabValue !== 0 &&
        <Grid item  style={webStyle.userDetails}>
        <Avatar src={this.state.selectedEmployee.attributes?.employee_image?.url}
         style={webStyle.userPhoto} alt="User" />
        <div className="widthfull">
          <p>{this.state.selectedEmployee.attributes.full_name}</p>
          <p>{this.state.selectedEmployee.attributes.email}</p>
        </div>
      </Grid>
      }
        {this.state.role === "false" ?
        this.addDailyviewCondition()
          : this.employeeContentt()}
      
      </Grid>
    );
  }
  /* istanbul ignore next */
  addDailyviewCondition = () => {
    return (
      this.state.tabValue === 0 ? this.alternativeContentt() : this.timeSheetContentt()
    )
  }
  /* istanbul ignore next */ 
  employeeContentt = () => {
    return(
      <>
      <Grid item xs={12} sm={3} 
      style={webStyle.userDetails}>
    <Avatar src={this.state.empImg} style={webStyle.userPhoto}
     alt="User" />
    <div className="widthfullName">
      <p>{this.state.empName}</p>
      <p>{this.state.empEmail}</p>
    </div>
  </Grid>
      </>
    )
  }
  /* istanbul ignore next */ 
  alternativeContentt = () => {
    return(
      <>
       <Grid item xs={12} sm={3} 
       style={webStyle.userDetailss}>
    <Avatar src={this.state.boProfilImage} style={webStyle.userPhoto} 
    alt="User" />
    <div className="widthfullName">
      <p>{this.state.boName} {this.state.boLastName}</p>
      <p>{this.state.empEmail}</p>
    </div>
  </Grid>
      </>
    )
  }
  /* istanbul ignore next */ 
  timeSheetContentt = () => {
    return(
      <>
      
       <Box style={webStyle.searchContainerr}>
    <Autocomplete
      id="free-solo-2-demo"
      disableClearable
      style={webStyle.searchInputt}
      fullWidth
      data-test-id="searchInput"
      onChange={(event, newValue) => this.handleSearchTimeSheet(newValue)}
      value={this.state.selectedEmployee as EmailAccount}
      options={this.state.employeeState}
      getOptionLabel={(option) => `${option?.attributes.full_name}`}
      renderOption={(option) => (
        <React.Fragment>
          <Grid item xs={12} sm={3} style={webStyle.userDetails}>
            <Avatar src={option?.attributes?.employee_image?.url} style={webStyle.userPhotosmm} alt="User" />
            <div className="widthfull">
              <p style={webStyle.fontSmm}>{option?.attributes.full_name}</p>
              <p style={webStyle.fontSmm}>{option?.attributes.email}</p>
            </div>
          </Grid>
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="normal"
          placeholder={`${t('reports.searchEmployee')}`}
          fullWidth
          InputProps={{
            ...params?.InputProps,
            type: 'search',
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <img style={webStyle.searchIconRequestt} src={searchIcon} alt="" />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  </Box>
      </>
    )
  }
  render() {
    // Customizable Area Start
    const { anchorEl, timeSheetData ,isEmployee, tabValue, statusEl, purchaseDate,selectLan} = this.state;
    const open = Boolean(anchorEl);
    const statusOpen = Boolean(statusEl);
    const employeeId = localStorage.getItem("employeeId");
    const{t}=this.props
    //istanbul ignore next
    return (
      <>
        <DashboardSideBar permissions={undefined} oldProps={undefined} history={undefined} {...this.props} navMenuItem={this.props.t('dashboardNavbar.timesheet')} active={9} active1={false} active2={false} />
        <CustomFormControlLabel >
        <Box 
         className={selectLan === "ar" ? "scrollable-container" : ""}
        >
        {  
              isEmployee ? <BOHeaderStyle><h3 className="textsize">{t('timesheet.timesheetHours')}</h3>
              </BOHeaderStyle>
              :  
              <BOHeaderStyle>
              <h3 className="textsize">{t('timesheet.timesheetSettings')}</h3>
              <TabTimeSheet aria-label="simple tabs example" data-test-id="tab_imeSheet"  value={tabValue} onChange={(event, newValue) => this.handleTabValue(newValue)}>
                        <Tab className = "timesheetBtncontainer" data-test-id="BO_Timesheet" icon={<img src={invoicesIcon} alt="invoicesIcon" style={this.JObTabIconStyle(tabValue, webStyle)}/>} label={t('timesheet.mytimesheetButon')} style={this.JObTabStyle(tabValue, webStyle)} />
                        <Tab className = "EmptimesheetBtncontainer" data-test-id="Employee_Timesheet" icon={<img src={customersIcon} alt="invoicesIcon" style={this.RequestTabIconStyle(tabValue, webStyle)}/>} label={t('timesheet.employeeTimesheet')} style={this.RequestTabStyle(tabValue, webStyle)} />
              </TabTimeSheet>
            </BOHeaderStyle>
          }
          <Divider />

        <Box 
        // className="boxResponse"
        className= {selectLan === "ar" ?"boxResponsAr":"boxResponse"}
        >

            <Grid container style={webStyle.mainGrid} className="gridrespo">
            {this.empDetails()}
                  <Grid item xs={12} style={webStyle.controls}>
                   
                      <span style={webStyle.arrowIcon} data-test-id = "prevDate" onClick={() => this.handleClickPrevDate()}>{"<"}</span>
                      <Button className="button" >{t('timesheet.todayButton')}</Button>
                      <StyledDatePickerWrapper style={webStyle.datePickerContainer}>
                      <CustomDatePicker
                        data-test-id="handleDateChange_timesheet"
                        selectedDate={this.state.selectDate}
                        dateChange={this.handleDateChange}
                        />
                      <span style={webStyle.arrowIcon} data-test-id="nextDate" onClick={() => this.handleClickNextDate()}>{">"}</span>
                      </StyledDatePickerWrapper>
                    <Button className="button" data-test-id="timesheet_daily" onClick={() => this.handleView("daily")}>{t('timesheet.dailyButton')}</Button>
                    <Button className="buttonweekly" data-test-id="timesheet_weekly"  onClick={() =>this.navigateTo({props:this.props, screenName:"TimeSheetWeekly"})}>{t('timesheet.weeklyButton')}</Button>
                  </Grid>
                  <Grid item xs={12} sm={3} style={webStyle.addEntryButton}>
                  {this.state.addEntriesToggle && (
                    <Button
                      className="buttonAdd"
                      data-test-id="timesheet_entries"
                      onClick={() => this.navigateTo({ props: this.props, screenName: "TimeSheetHours" })}
                    >
                      +{t('timesheet.addEntriesButton')}
                    </Button>
                  )}
                  </Grid>
                </Grid>
        
              <TableContainer component={Paper} style={webStyle.tableContainer} className="scroll-container">   
                <Table aria-label="simple table" className="overflow">   
                  <TableHead>
                    <TableRow className="textStart">
                      <TableCell className="tablepad">{t('dashboardSidebar.job')}</TableCell>
                      <TableCell className="tablepad">{t('settings.date')}</TableCell>
                      <TableCell className="tablepad">{t('timesheetTable.starts')}</TableCell>
                      <TableCell className="tablepad">{t('timesheetTable.ends')}</TableCell>
                      <TableCell className="tablepad">{t('jobs.label.duration')}</TableCell>
                      {this.state.addEntriesToggle === false &&
                      <TableCell className="tablepad">{t('dashboard.status')}</TableCell>
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(timeSheetData  || [])?.map((item:TimesheetType, index : number) => (
                      <TableRow key={index} >
                        <TableCell data-test-id="styleId" className={this.getStyle(selectLan)}>{moment(item.attributes.date).format("MMM DD, YYYY")}</TableCell>
                        <TableCell data-test-id="styleId" className={this.getStyle(selectLan)}>{item.attributes.customer_name}</TableCell>
                        <TableCell data-test-id="styleId" className={this.getStyle(selectLan)}>{moment(item.attributes.start_time).utc().format("hh:mm A")}</TableCell>
                        <TableCell data-test-id="styleId" className={this.getStyle(selectLan)}>{moment(item.attributes.end_time).utc().format("hh:mm A")}</TableCell>
                        <TableCell data-test-id="styleId" className={this.getStyle(selectLan)}>{item.attributes?.duration || "00:00"}</TableCell>
                        <TableCell data-test-id="styleId" className={this.getStyle(selectLan)}>
{
  isEmployee &&
  <>
                          {item.attributes.status === 'pending' && <Button className="btnPending" > <img src={imgPending} style={webStyle.imgButton} alt="Pending" /> {t('quote.pending')}</Button>}
                          {item.attributes.status === 'approved' && <Button className="btnApproved" > <img src={imgApproved} style={webStyle.imgButton} alt="Approved" />{t('quote.approved')} </Button>}
                          {item.attributes.status === 'rejected' && <Button className="btnRejected" ><img src={imgRejected} style={webStyle.imgButton} alt="Rejected" />{t('timesheet.rejected')}  </Button>}
  </> }
  {this.state.addEntriesToggle === false &&
  <>
                          {item.attributes.status === 'pending' && <Button className="btnPending" data-test-id="pending" onClick={(event: React.MouseEvent<HTMLElement>) => this.handleSatus(item.id, event)}> <img src={imgPending} style={webStyle.imgButton} alt="Pending" /> {t('quote.pending')}</Button>}
                          {item.attributes.status === 'approved' && <Button className="btnApproved" data-test-id="approved" onClick={(event: React.MouseEvent<HTMLElement>) => this.handleSatus(item.id, event)}> <img src={imgApproved} style={webStyle.imgButton} alt="Approved" />{t('quote.approved')} </Button>}
                          {item.attributes.status === 'rejected' && <Button className="btnRejected"  data-test-id="rejected" onClick={(event: React.MouseEvent<HTMLElement>) => this.handleSatus(item.id, event)}><img src={imgRejected} style={webStyle.imgButton} alt="Rejected" />{t('timesheet.rejected')}  </Button>}
                          <CustomMenu
                          anchorEl={statusEl}
                          open={statusOpen}
                          onClose={this.handleCloseSatus}
                          data-test-id="iconclick_time" 
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          PaperProps={{
                            style: {
                              maxHeight: 48 * 4.5,
                              width: '20ch',
                            },
                          }}
                        >
                          <MenuItem data-test-id="approved_timesheet" onClick={()=>this.handleEmployeeeStatus("approved")} style={webStyle.btnApproved}><img src={imgApproved} style={webStyle.imgButton} alt="Approved" />{t('quote.approved')}</MenuItem>
                          <MenuItem data-test-id="pending_timesheet" onClick={()=>this.handleEmployeeeStatus("pending")} style={webStyle.btnPending}><img src={imgPending} style={webStyle.imgButton} alt="Approved" />{t('quote.pending')}</MenuItem>
                          <MenuItem data-test-id="rejected_timesheet" onClick={()=>this.handleEmployeeeStatus("rejected")} style={webStyle.btnRejected}><img src={imgRejected} style={webStyle.imgButton} alt="Rejected" />{t('timesheet.rejected')}</MenuItem>
                        </CustomMenu>
  </>
}                          

                          </TableCell>
                        
                        <IconButton data-testid="more_vertIcon" onClick={(event: React.MouseEvent<HTMLElement> ) =>this.handleClick(event, item.attributes)}
                        data-test-id="iconbtn_time" 
                        >
                           <img  style={webStyle.threeDotStyle} src={ThreedotsIcon}></img>
                         
                          
                        </IconButton>
                        <CustomMenu2
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={this.handleClose}
                          data-test-id="iconclick_time" 
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          PaperProps={{
                            style: {
                              maxHeight: 48 * 4.5,
                              width: '20ch',
                            },
                          }}
                        >
                          <li data-test-id="timesheet_MoreAction" className="main-title-menu">{t('Calendar.button.action')}</li>
                         
                          <StyledMenuItem data-test-id="timesheet_edit" onClick={this.handleEdit}>
                            <ListItemIcon>
                              <EditOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('boEmployees.edit')} />
                          </StyledMenuItem>
                          <StyledMenuItem data-test-id="delete_timesheet" onClick={this.handleDeleteButton}>
                            <ListItemIcon >
                              <DeleteOutlineOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('boEmployees.delete')} />
                          </StyledMenuItem>
                        </CustomMenu2>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
               
              <StyledRoot>
                <CustomTimeSheet
                open={this.state.isPopupOpen}
                onClose={this.handlePopupClose}
                data-test-id="custom_TimeSheet" 
              >
                <div className="display">   
                <DialogTitle style={webStyle.textlg}>Delete Timesheet Entry</DialogTitle>
                <IconButton onClick={this.handlePopupClose} data-test-id="btncli-close" >
                  <CloseIcon />
              </IconButton>
              </div>
                <DialogContent>
                  <DialogContentText style={webStyle.textsm}>
                  Are you sure you want to permanently delete timesheet entry?
                  </DialogContentText>
                </DialogContent>
                <DialogActions >
                  <Button onClick={this.handlePopupClose} data-test-id="btncli" className="btnCancelpopup">
                    Cancel
                  </Button>
                  <Button onClick={this.handlePopupDelete} className="btndeletepopup" data-test-id="btnclicks_times">
                    Delete
                  </Button>
                </DialogActions>
              </CustomTimeSheet>
              </StyledRoot>
              </TableContainer>
            </Box>

            
          </Box>    
        </CustomFormControlLabel>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start


const webStyle = {
  userPhoto: {
    marginRight:"10px",
  },
  imgButton: {
    width: "18px",
    height: "18px",
    margin:"0 10px 0 0"
  },
  flex:{
    display: "flex",
    alignItem: "center",
    justifyContent: "space-between",   
  },
  gridBox:{
    display: "flex",
    padding: "20px 0",
  },
  tableContainer:{
    paddingTop: "40px",
  },
  arrowIcon: {
    fontSize: '20px',
    fontWeight: 600,
    padding: "8px",
    color: "#ccc",
    cursor: "pointer",
  },
  mainGrid: {
    justifyContent: "space-between",
    display: "flex",
    padding: "20px 0",
  },
  userDetails: {
    display: "flex",
    alignItems: "center",
  },
  userDetailss: {
    display: "flex",
    alignItems: "center",
    width:"298px"
  },
  controls: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
  },
  datePickerContainer: {
    display: "flex",
    margin: "0 10px",
    alignItems: "center",
  },
  addEntryButton: {
    alignItems: "center",
    justifyContent: "flex-end",
    display: "flex",
    paddingRight:"20px",
  },
  textsm:{
    fontSize:"16px"
  },
  textlg:{
    fontSize:"22px"
  },
  activeTab: {
    background: "#1C18AF",
    color: "#fff",
    borderRadius: "10px",
    paddingBlock: "0px",
    fontSize: "15px",
    height: "54px",
    minHeight: "20px",
    width: "60%",
    textTransform: "capitalize" as "capitalize",
    maxWidth: "230px",
    fontWeight: 500,
    justifyContent: "start" as 'start'
  },
  tabstyle: {
    paddingBlock: "0px",
    height: "54px",
    padding: "10px 20px",
    minHeight: "20px",
    cursor: "pointer",
    border: "1px solid transparent",
    color: "#000",
    fontWeight: 400,
    transition: "background-color 0.3s ease, color 0.3s ease",
    textTransform: "capitalize" as "capitalize",
    width: "60%",
    fontSize: "15px",
    maxWidth: "260px",
    justifyContent: "start" as 'start'
  },
  imageIcon: {
    width: "20px",
    margin: "0 10px 0 10px",
  },
  activeImageIcon: {
    width: "20px",
    margin: "0 10px 0 10px",
    filter:" brightness(0) invert(1)" as 'brightness(0) invert(1)'
  },
  btnApproved:{
    height:"44px",
    borderRadius:"10px",
    color:"#1C18AF",
  },
  btnPending:{
    height:"44px",
    borderRadius:"10px",
    color:"#FA8334",
  },
  btnRejected:{
    height:"44px",
    borderRadius:"10px",
    color:"#FA3434",
  },
  searchContainerr : {
    display: "flex",
    height:"48px",
    alignItems: "center",
    borderRadius: "8px",
    width:"290px",
    minHeight: "48px",
    border:"2px solid #1C18AF",
    marginLeft:"10px"
  },
  searchInputt : {
    fontSize: "1.1667rem",
    lineHeight: "16px",
  },
  userPhotosmm :{
    width: "28px",
    height: "28px",
    margin :"0 10px 0 0"
  },
  fontSmm: {
    fontSize:"16px"
  },
  searchIconRequestt :{
    height: "20px",
    width: "20px",
    margin: "1px 0 0 12px",
  },
  threeDotStyle : {
    marginTop:"16px",
    height:"22px",
    width:"22px"
  }
}

const CustomFormControlLabel = styled(StyledRoot)({

  "& .scrollable-container" : {
     marginRight:"295px",
     "@media (max-width: 1279px)": {
      marginRight:"15px",
    },
  },
  "& .timesheetBtncontainer" : {
    "@media (max-width: 600px)": {
      height:"110px !important",
      width:"112px !important",
      display:"block ruby"
    }
  },
  "& .EmptimesheetBtncontainer" : {
    "@media (max-width: 600px)": {
      height:"110px !important",
      width:"112px !important",
      display:"block"
    }
  },
  "& .boxResponse": {
    marginTop: "1rem",
    marginLeft:"310px",
    marginRight:"30px",
    "@media (max-width: 1279px)": {
      marginLeft:"15px",
    },
  },
  "& .boxResponsAr" : {
    marginTop: "1rem",
    marginRight:"30px",
    "@media (max-width: 1279px)": {
      marginRight:"15px",
    },
  },
  
  "& .button":{
    backgroundColor: "#1C18AF",
    color: "white",
    padding: "10px 20px",
    border: "none",
    cursor: "pointer",
    margin: "0 5px",
    borderRadius: "5px",
    outline: "none",
    height:"48px",
    textTransform: "none" as const,
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      color: "white",
      cursor: "pointer",
    },
  },
  "& .buttonweekly":{
    backgroundColor: "#FFFFFF",
    color: "#808191",
    border: "none",
    padding: "10px 20px",
    cursor: "pointer",
    outline: "none",
    borderRadius: "5px",
    margin: "0 5px",
    height:"48px",
    textTransform: "none" as const,
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      color: "white",
      cursor: "pointer",
    },
  },
  "& .btnApproved":{
    width :"168px",
    height:"44px",
    borderRadius:"25px",
    color:"#1C18AF",
    border:"1px solid #1C18AF",
    backgroundColor:"#E9E9FF",
    textTransform: "none" as const
  },
  "& .btnPending":{
    textTransform: "none" as const,
    height:"44px",
    width :"168px",
    border:"1px solid #FA8334",
    borderRadius:"25px",
    color:"#FA8334",
    backgroundColor:"#FFF5EF",
  },
  "& .buttonAdd":{
    backgroundColor: "#1C18AF",
    color: "white",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    margin: "0 5px",
    outline: "none",
    height:"48px",
    width:"150px",
    fontWeight:500,
    textTransform: "none" as const,
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      color: "white",
      cursor: "pointer",
    },
  },
  "& .btnRejected":{
    width :"168px",
    height:"44px",
    textTransform: "none" as const,
    borderRadius:"25px",
    border:"1px solid #FA3434",
    color:"#FA3434",
    backgroundColor:"#FFEFEF",
  },
  "& .textStart":{
    textAlignLast:"start",
  },
  "& .gridrespo": {
    flexWrap:"nowrap",
    "@media (max-width: 768px)": {
      width: "140ch"
    }, 
  },
  "& .MuiTableContainer-root": { 
    overflowX:"hidden",
    "@media (max-width: 800px)": {
      width:"140ch",
    },
  },
  "& .widthfull": {  
    "@media (max-width: 768px)": {
      width:"220px"
    },
  },
  "& .scroll-container": { 
    paddingTop: "40px",
    overflowX: 'inherit', 
  },
  "& .MuiButton-label": {  
      width: "100%",
      display: "inherit",
      alignItems: "inherit",
      justifyContent: "start",
      padding:"0 5px"
  },
  "& .textsize": {  
    fontSize:"24px",
    color: "rgb(41, 46, 52)",
    fontWeight: 500,
  },
  "& .tablepad": {
    padding:"15px !important"
  },
  "& .table-align":{
    textAlign:"right"
  },
  "& .CustomDatePicker-inputBaseStyle-60": {
    width:"165px",
    border:"none !important",
    "@media (max-width: 768px)": {
      width:"140px",
      border:"none",
    },
  },
  "& .MuiInputBase-root":{
    border:"none !important",
  },
  
})
const CustomMenu = styled(Menu)({
  "& .MuiListItem-button:hover": {
    backgroundColor: '#9B99D8 !important',
  },
})

const CustomMenu2 = styled(Menu)({
  "& .MuiList-root":{
    padding:0
  },
  "& .MuiListItem-button:hover": {
    backgroundColor: '#1C18AF !important',
    color: '#FFFFFF !important',
  },
  "& .main-title-menu":{
    padding: "10px 16px",
    color: 'black',
    fontSize: 16,
  },
  "& .radeous": {
    borderRadius:"20px"
  },
  "& .MuiPopover-paper":{
    borderRadius:"10px"
  }
})

const CustomTimeSheet = styled(Dialog)({
  "& .MuiPaper-rounded":{
    padding:"15px !important",
    borderRadius:"15px !important",
    "@media (max-width: 420px)": {
      padding:"10px !important",
    },
  },
  "& .btnCancelpopup": {
    height: "56px",
    width: "156px",
    color: "#999999",
    fontSize: "22px",
    fontWeight: 500,
    textAlign: "center" as const,
    textTransform: "capitalize" as const,
    background: "#ffffff",
    borderRadius:"10px",
    border: "1px solid #999999",
    transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
    '&:hover': {
      backgroundColor: '#999999',
      color: "white",
      cursor: "pointer"
    },
  },
  "& .btndeletepopup":{
    height:"56px",
    width:"156px",
    borderRadius:"10px",
    backgroundColor:"#1C18AF",
    color:"#FFFFFF",
    fontSize:"22px",
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      color: "white",
      cursor: "pointer"
    },
  },
  "& .MuiButton-label":{
    textTransform:"capitalize",
    fontWeight:"normal",
  },
  "& .MuiDialogActions-root":{
   justifyContent:"space-between"
  },
  "& .MuiTypography-h6":{
    color:"black !important",
    fontSize:"25px !important",
    "@media (max-width: 420px)": {
      fontSize:"20px !important",
      width:"21vh" 
    },
   },
   "& .display":{
    display:"flex",
    justifyContent:"space-between",
   },
})

const TabTimeSheet = styled(Tabs)({
  gap: "10px",
  margin: "30px 0px",
  "& div" : {
    "& span" : {
      display: "contents"
    }
  }
})

const BOHeaderStyle = styled(Box)({
  marginTop:"8rem",
  marginLeft:"310px",
  "@media (max-width: 1279px)": {
    marginLeft:"15px",
  },
})

const StyledMenuItem = withStyles(({
  root: {
    borderRadius:10,
    '& .MuiListItemIcon-root': {
      color: "rgb(28, 24, 175)",
      minWidth: 35
    },
    '&:hover': {
      backgroundColor: "rgb(28, 24, 175)",
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: "white",
      },
    },
  },
}))(MenuItem);
const StyledDatePickerWrapper = styled(Grid)({
  "& .spacingSmall" : {
    border: "none",      
    boxShadow: "none"   
  },
  "& .MuiBox-root": {
    border:"none !important",
    "@media (max-width: 768px)": {
      border:"none !important",
    },
  },

});

const TimesheetSnackbar = withSnackbar(Timesheet)
export default withTranslation()(withPermission(TimesheetSnackbar));
// Customizable Area End