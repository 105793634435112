import React from 'react'
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { WithStyles } from "@material-ui/core/styles"
import { extensionDoc, extensionJpg, extensionPdf, extensionPng, extensionPpt, extensionTxt, extensionXls } from "../../JobListing2/src/assets";
import { setStorageData, getStorageData } from '../../../framework/src/Utilities';
import { apiCall } from "../../../components/src/CommonFunction";
import {TaxRateResponse} from "./PriceSectionController.web"
import { performInvoiceAuthorizationCheck,performInvoiceDeleteAuthorizationCheck } from "../../CustomisableUserProfiles/src/utility.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any
  location: any
  openToastHandler: any
  t: (key: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isQuoteTitleEdit: boolean
  isQuoteErrorformData: any
  quoteForm: {
    quoteId: string,
    quoteTitle: string,
    serviceDetails: string,
    quoteDate: any,
    dueDate: any
  }
  customerSelected: boolean,
  selectedQuoteCustomer: any,
  cutstomerDailogOpen: boolean,
  defaultLocation: {
    lat: any;
    lng: any;
  },
  selectLanguage:string
  getCustomerPlace: any,
  siteDetails: any,
  productServices: any,
  completed: boolean
  files: any
  progress: number;
  isEditable: boolean
  count: any
  extensionIcons: any,
  fileToaster: any,
  imgToasterText: any;
  showErrorMessage: boolean;
  errorMessage: string;
  productSearch: string;
  productData: any
  priceSectionData: any,
  collectedPaymentForm: any
  openNextModal: string
  collectedPayment: boolean
  nextModal: string
  anchorEl: any;
  anchorElV1: any;
  internalNotes: string,
  internalAttachments: any
  quoteDeletedailog: boolean
  quoteSendEmailDailog: boolean
  currency:string
  singleQuoteDetails: any;
  toaster: boolean;
  toasterText: string;
  createQuoteLoading: boolean;
  getQuoteLoading: boolean;
  updateQuoteLoading: boolean;
  softDeleteQuoteLoading: boolean;
  isEditQuote: boolean;
  jobConvertedFiles: any
  jobEditedFiles: any;
  openPdfStatus: boolean;
  savingStatus: string;
  drawerStatus: boolean;
  requestDetails: any
  selectCustomerButton: boolean
  discountDialog: boolean
  selectCurrency:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class QuoteActionsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  googleMapRef: any
  apiCallIdQuoteMakePayment: string = ""
  apiCallIdGetProductServices: string = ""
  token: string = ""
  apiCallIdCreateQuote: string = ""
  apiCallIdGetQuoteDetails: string = ""
  getCustomerDetailsApiCallId: string = ""
  apiCallIdUpdateQuote: string = ""
  apiCallIdSoftDeleteQuote: string = ""
  apiCallIdDetailsGetRequest: string = ""
  getSiteDetailsApiCallId: string = ""
  apiCallIdGetJobProductDetails: string = ""
  currencyApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.googleMapRef = React.createRef();

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)

      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isQuoteTitleEdit: false,
      isQuoteErrorformData: {},
      quoteForm: {
        quoteId: "",
        quoteTitle: "",
        serviceDetails: "",
        quoteDate: new Date(),
        dueDate: new Date()
      },
      customerSelected: false,
      currency:'',
      selectedQuoteCustomer: {},
      cutstomerDailogOpen: false,
      defaultLocation: {
        lat: 25.276987,
        lng: 55.296249
      },
      selectLanguage:"en",
      getCustomerPlace: null,
      siteDetails: {},
      productServices: [{
        product_id: "",
        service_id: "",
        description: "",
        quantity: 0,
        unitPrice: 0,
        totalCost: 0,
        files: [],
        completed: false,

      }],
      selectCurrency:'',
      completed: false,
      files: [],
      progress: 0,
      isEditable: true,
      count: 0,
      extensionIcons: {
        jpg: extensionJpg,
        png: extensionPng,
        jpeg: extensionJpg,
        doc: extensionDoc,
        pdf: extensionPdf,
        xls: extensionXls,
        txt: extensionTxt,
        ppt: extensionPpt,
        docx: extensionDoc,
        xlsx: extensionXls,
        pptx: extensionPpt,
      },
      fileToaster: false,
      imgToasterText: "",
      showErrorMessage: false,
      errorMessage: "",
      productSearch: "",
      productData: [],
      collectedPaymentForm: {
        id: "",
        amount: '',
        payment_method: '',
        transaction_date: new Date(),
        details: '',
        pay_balance : false,
        collected: false
      },
      priceSectionData: {
        sub_total: "",
        total: "",
        tax_name: "",
        tax: "",
        discount: "",
        currency: "",
      },
      openNextModal: "",
      collectedPayment: true,
      nextModal: "",
      anchorEl: null,
      anchorElV1: null,
      internalNotes: "",
      internalAttachments: [],
      quoteDeletedailog: false,
      quoteSendEmailDailog: false,
      singleQuoteDetails: {},
      toaster: false,
      toasterText: "",
      createQuoteLoading: false,
      getQuoteLoading: true,
      updateQuoteLoading: false,
      softDeleteQuoteLoading: false,
      isEditQuote: true,
      jobConvertedFiles: [],
      jobEditedFiles: [],
      openPdfStatus: false,
      savingStatus: "",
      drawerStatus: true,
      requestDetails: {},
      selectCustomerButton: false,
      discountDialog: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount(): Promise<void> {
    localStorage.removeItem('convertType')
    window.scrollTo(0, 0);
    const selectedLanguage = await getStorageData("lang")||'en';
    this.setState({ selectLanguage: selectedLanguage })
    this.getCurrencySym()
    setTimeout(() => {
      this.setState({ getQuoteLoading: false });
    }, 1500)
    const authorize = localStorage.getItem(configJSON.storageToken) || ""
    this.token = authorize
    this.createJobproductAndServices()
    if(this.props.location?.state?.reDirectionFrom==='customer'){
      this.getQuoteCustomerDetails()
    }
    const quote_id = localStorage.getItem("quote_id");
    const redirectType = this.props.location?.state?.convertedType
    if (redirectType === "RequestTo") {
      this.getQuoteRequestDetails()
      this.jobproductDetails()
      localStorage.setItem("convertType", "RequestTo")
    }
    if (quote_id && redirectType === "RequestTo") {
      this.getQuoteDetails();
      this.getQuoteRequestDetails()
    } else {
      this.getQuoteDetails();
    }

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.apiCallIdGetProductServices) {
        this.getProductServicesApiResponce(responseJson)
      }
      if (apiRequestCallId === this.apiCallIdCreateQuote) {
        this.createQuoteApiResponce(responseJson)
      }
      if (apiRequestCallId === this.apiCallIdGetQuoteDetails) {
        this.getQuoteApiResponce(responseJson)
      }
      if (apiRequestCallId === this.getCustomerDetailsApiCallId) {
        this.getCustomerDetailsApiResponce(responseJson)
      }
      if (apiRequestCallId === this.apiCallIdUpdateQuote) {
        this.updateQuoteApiResponce(responseJson)
      }
      if (apiRequestCallId === this.apiCallIdSoftDeleteQuote) {
        this.softDeleteQuoteApiResponce(responseJson)
      }
      if(apiRequestCallId === this.currencyApiCallId){
        this.currencyApiResponse(responseJson)
      }
      this.QuoteApiCalls(apiRequestCallId, responseJson)
    }
    // Customizable Area End
  }


  // Customizable Area Start

  QuoteApiCalls = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.apiCallIdDetailsGetRequest) {
      this.requestDetailsGetApiResponce(responseJson)
    }
    if (apiRequestCallId === this.getSiteDetailsApiCallId) {
      this.siteInformationApiResponce(responseJson)
    }
    if (apiRequestCallId === this.apiCallIdGetJobProductDetails) {
      this.jobProductServicesApiResponce(responseJson)
    }
  }
  requestDetailsGetApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({ getQuoteLoading: false });
      this.setState({
        requestDetails: responseJson.data,
        selectCustomerButton: true
      }, () => {
        this.getQuoteCustomerDetails();
        this.getSelectedSiteDetails();

      })
    } else {
      this.setState({ getQuoteLoading: false });
      this.apiErrorResponce(responseJson)
    }

  }
  getProductServicesApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({ productData: responseJson })
    } else if (responseJson.errors) {
      this.apiErrorResponce(responseJson)
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }
  }

  createQuoteApiResponce = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({
        createQuoteLoading: false,
        toaster: true,
        toasterText: `${this.props.t('quote.toasterMsg.create')}`,
        isQuoteTitleEdit: false,
        discountDialog: false
      });
      localStorage.setItem("quote_id", responseJson.data?.id);
      this.props.openToastHandler(this.state.toasterText,'success')
      this.setState({ isEditQuote: false });
      this.getQuoteDetails();
    } else if (responseJson.errors) {
      this.setState({ createQuoteLoading: false });
      this.apiErrorResponce(responseJson)
    } else {
      //Check Error Response
      this.setState({ createQuoteLoading: false });
      this.parseApiErrorResponse(responseJson);
    }
  }
  getQuoteApiResponce = (responseJson: any) => {
    if (!responseJson.errors) {
      if (responseJson.message === "you are not authorized") {
        return this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
      }
      this.setState({ getQuoteLoading: false });
      const quoteFormEdit = {
        quoteId: responseJson.data?.attributes?.quote_id,
        quoteTitle: responseJson.data?.attributes?.quote_title,
        serviceDetails: responseJson.data?.attributes?.service_detail,
        quoteDate: new Date(responseJson.data?.attributes?.quote_date),
        dueDate: new Date(responseJson.data?.attributes?.due_date)
      }
      const priceSectionDataEdit = {
        currency: responseJson.data?.attributes?.currency,
        discount: responseJson.data?.attributes?.discount,
        sub_total: responseJson.data?.attributes?.sub_total,
        total: responseJson.data?.attributes?.total,
        tax_name: responseJson.data?.attributes?.tax_name,
        tax: responseJson.data?.attributes?.tax,
      }
      const collectedPaymentFormEdit = {
        id: responseJson.data?.attributes?.invoice_deposits[0]?.id,
        amount: responseJson.data?.attributes?.invoice_deposits[0]?.amount,
        details: responseJson.data?.attributes?.invoice_deposits[0]?.details,
        payment_method: responseJson.data?.attributes?.invoice_deposits[0]?.payment_method,
        transaction_date: new Date(responseJson.data?.attributes?.invoice_deposits[0]?.transaction_date),
        pay_balance: responseJson.data?.attributes?.invoice_deposits[0]?.pay_balance,
        collected: responseJson.data?.attributes?.invoice_deposits[0]?.collected,
      }
      const productServicesEdit = responseJson.data?.attributes?.products?.data?.map((item: any) => {
        return {
          product_id: item?.attributes?.product_id,
          service_id: item?.attributes?.service_id,
          description: item?.attributes?.description,
          quantity: item?.attributes?.quantity || 0,
          unitPrice: item?.attributes?.unit_price || 0,
          totalCost: item?.attributes?.total,
          product_item_id: item?.id,
          files: item?.attributes?.files || [],
          completed: true,
          progress: 100,
        }
      });
      this.setState({
        singleQuoteDetails: responseJson.data,
        customerSelected: true,
        isEditQuote: false,
        quoteForm: quoteFormEdit,
        priceSectionData: priceSectionDataEdit,
        collectedPaymentForm: collectedPaymentFormEdit,
        productServices: productServicesEdit,
        internalNotes: responseJson.data?.attributes?.internal_notes,
        internalAttachments: [],
        jobEditedFiles: responseJson.data?.attributes?.files || [],
        currency: responseJson.data?.attributes?.currency,
        selectCurrency: responseJson.data?.attributes?.discount_type,
      }, () => {
        this.handleManageMore();
        this.getQuoteCustomerDetails();
        this.getSelectedSiteDetails()
        this.getCustomerLocationData(this.state.siteDetails)

      });
    } else if (responseJson.errors) {
      this.setState({ getQuoteLoading: false });
      this.apiErrorResponce(responseJson)
    } else {
      //Check Error Response
      this.setState({ getQuoteLoading: false });
      this.parseApiErrorResponse(responseJson);
    }
    localStorage.removeItem('customerTabId')
  }
  getCustomerDetailsApiResponce = (responseJson: any) => {
    this.createJobproductAndServices()
    if (responseJson && !responseJson.errors) {
      this.setState({ getQuoteLoading: false });

      this.setState({
        selectedQuoteCustomer: responseJson.data
      })
      if(this.props.location?.state?.id){
        this.setState({
          selectCustomerButton: true
        })
      }
    } else if (responseJson.errors) {
      this.apiErrorResponce(responseJson)
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }
    this.setState({
      selectCustomerButton: true
    })
  }
  updateQuoteApiResponce = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({
        updateQuoteLoading: false,
        toaster: true,
        toasterText: `${this.props.t('quote.toasterMsg.updated')}`,
        isQuoteTitleEdit: false,
        discountDialog: false
      });
      this.props.openToastHandler(this.state.toasterText,'success')
      this.setState({ isEditQuote: false });
      this.getQuoteDetails();
    } else if (responseJson.errors) {
      this.setState({ updateQuoteLoading: false });
      this.apiErrorResponce(responseJson)
    } else {
      //Check Error Response
      this.setState({ updateQuoteLoading: false });
      this.parseApiErrorResponse(responseJson);
    }
  }
  softDeleteQuoteApiResponce = async (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({ softDeleteQuoteLoading: false });
      await setStorageData("archiveQuoteToaster", "archiveQuote");
      localStorage.removeItem("quote_id");
      this.props.history.push("/Quotes");
    } else if (responseJson.errors) {
      this.setState({ softDeleteQuoteLoading: false });
      this.apiErrorResponce(responseJson)
    } else {
      //Check Error Response
      this.setState({ softDeleteQuoteLoading: false });
      this.parseApiErrorResponse(responseJson);
    }
  }
  currencyApiResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({ currency:responseJson.currency_code })
    } else {
      if (responseJson&&responseJson.errors) {
        for (let key in responseJson.errors) { 
          this.setState({errorMessage:`${key} ${responseJson.errors[key][0]}`,showErrorMessage:true})
        } 
      }
    }
  }
  apiErrorResponce = (responseJson: any) => {
    const error = responseJson?.errors?.[0];
    if (error) {
      const { token } = error;
      if (token === "Token has Expired" || token === "Invalid token") {
        this.props.openToastHandler(token, "error");
        setTimeout(() => {
          localStorage.clear();
          this.props?.history?.push("/Login");
        }, 2000);
      }
    }
  }

  handleManageMore = () => {
    if (this.state.savingStatus === "saveMoreEmail") {
      this.handleCloseSaveMore()
      this.handleEmailQuoteOpen()
    } else if (this.state.savingStatus === "saveMorePdf") {
      this.handleCloseSaveMore()
      this.openpdf()
    } else if (this.state.savingStatus === "saveMoreJob") {
      this.handleCloseSaveMore()
      this.props.navigation.history.push("/Job/Create",{
        convertedType: "QuoteTo",
        quoteid: localStorage.getItem("quote_id")
      })
    }
  }
  handleEmailQuoteOpen = () => {
    this.setState({ quoteSendEmailDailog: true });
  }
  handleCloseQuoteEmailDailog = (status: boolean, messageStatus: string) => {
    this.setState({
      quoteSendEmailDailog: status
    }, () => {
      if (messageStatus === "success") {
        this.props.openToastHandler(`${this.props.t('invoice.emailSuccessMsg')}`, "success")
      }
    })
  }
  handleCloseToaster = () => {
    this.setState({ toaster: false });
  }

  editQuoteButton = async () => {
    const isAuthorized = await performInvoiceAuthorizationCheck("quote");
    this.handleEditQuoteButtonCallback(isAuthorized)
  }


  handleEditQuoteButtonCallback = (isAuthorized: any) => {
    if (!isAuthorized) {
      this.setState({ isEditQuote: true });
    } else {
      this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
    }
  }

  handleCancel = () => {
    this.props.history?.push("/Quotes");
  }
  handleCancelQuote = () => {
    this.setState({
      isEditQuote: false,
      isQuoteTitleEdit: false,
    });
  }
  handleQuoteTitle = () => {
    if (this.state.isEditQuote) {
      this.setState({ isQuoteTitleEdit: true });
    }
  }

  openpdf = () => {
    this.setState({
      openPdfStatus: true
    })
    this.handleCloseSaveMore()
  }
  closePdf = () => {
    this.setState({
      openPdfStatus: false
    })
  }

  // dailogs
  handleOpenCustomerDailog = async () => {
    if (this.state.isEditQuote) {
      const isAuthorized = await performInvoiceAuthorizationCheck("quote");
      this.handleOpenCustomerDailogCallback(isAuthorized)
    }
  }

  handleOpenCustomerDailogCallback = (isAuthorized: any) => {
    if (!isAuthorized) {
      this.setState({ cutstomerDailogOpen: true });
    } else {
      this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
    }
  }
  getCustomerLocationData = (customer: any) => {
    const selectedPlace = {
      lat: parseFloat(customer.latitude),
      lng: parseFloat(customer.longitude),
    };
    this.setState({ getCustomerPlace: selectedPlace });

    const map = this.googleMapRef.current;
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(selectedPlace);
      map.fitBounds(bounds, 100);
      map.setZoom(15);
    }
  }
  onMapLoad = (map: google.maps.Map) => {
    this.googleMapRef.current = map;
    const zoomInButton = document.createElement('button');
    zoomInButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 24 24" width="30">
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" fill="#1c18af"/>
        <path d="M12 10h-2v2H9v-2H7V9h2V7h1v2h2v1z" fill="#1c18af"/>
    </svg>`;
    zoomInButton.style.backgroundColor = 'white';
    zoomInButton.style.marginTop = '10px';
    zoomInButton.style.marginBottom = '10px';
    zoomInButton.style.border = 'none';
    zoomInButton.style.paddingLeft = '5px';
    zoomInButton.style.cursor = 'pointer';
    zoomInButton.style.padding = '6px 3px 0px 5px';
    zoomInButton.style.borderRadius = '4px';
    zoomInButton.title = 'Zoom In';
    zoomInButton.addEventListener('click', () => map?.setZoom((map?.getZoom() ?? 0) + 1));
    const zoomOutButton = document.createElement('button');
    zoomOutButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 24 24" width="30">
    <path d="M0 0h24v24H0V0z" fill="none"/>
    <path fill="#1c18af" d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zM7 9h5v1H7z"/>
    </svg>`;
    zoomOutButton.style.border = 'none';
    zoomOutButton.style.cursor = 'pointer';
    zoomOutButton.style.backgroundColor = 'white';
    zoomOutButton.style.marginBottom = '0px';
    zoomOutButton.style.padding = '6px 3px 0px 5px';
    zoomOutButton.style.borderRadius = '4px';
    zoomOutButton.style.paddingLeft = '5px';
    zoomOutButton.title = 'Zoom Out';
  
    zoomOutButton.addEventListener('click', () =>   map?.setZoom((map?.getZoom() ?? 0) - 1));
  
    const controlDiv = document.createElement('div');
    controlDiv.style.position = 'absolute';
    controlDiv.style.marginRight = '15px';
    controlDiv.style.display = 'flex';
    controlDiv.style.flexDirection = 'column';
    controlDiv.style.zIndex = '1';
    controlDiv.style.marginBottom = '10px';
    controlDiv.style.right = '10px';
    controlDiv.style.justifyContent = 'space-between'; 
    controlDiv.appendChild(zoomInButton);
    controlDiv.appendChild(zoomOutButton);
    const controlPosition = google.maps.ControlPosition.RIGHT_BOTTOM;
    map.controls[controlPosition].push(controlDiv);
  };
  getCustomerDailogStatus = (Status: boolean, customerSelected: boolean, siteDetails: any) => {
    this.setState({
      cutstomerDailogOpen: Status,
      customerSelected: customerSelected,
      siteDetails: siteDetails
    })
  }
  getCustomerDetails = (details: any) => {
    this.setState({
      selectedQuoteCustomer: details
    })
  }

  // ProductSections started
  addQuoteField = () => {
    this.setState({
      productServices: [...this.state.productServices, {
        product_id: "",
        service_id: "",
        description: "",
        unitPrice: '',
        quantity: '',
        totalCost: '',
        files: [],
        completed: false
      }]
    });
  }

  handleRemoveFile = (index: any, file: any) => {
    if (this.state.isEditable) {
      const values = [...this.state.productServices]
      const filesFiltered = Array.from(values[index]['files']).filter((f: any) => {
        return f.name !== file.name;
      });

      values[index]['files'] = filesFiltered;
      values[index]['progress'] = 100;
      values[index]['completed'] = true;

      if (values[index]['file_id'] && values[index]['file_id'].length > 0) {
        values[index]['file_id'] = [...values[index]['file_id'], file.id]
      } else {
        values[index]['file_id'] = [file.id]
      }
      this.setState({ productServices: values })
    }
  }

  removeField = (index: any) => {
    const values = [...this.state.productServices]
    values.splice(index, 1);
    this.setState({ productServices: values });
  };

  handleChangeInput = (index: any, event: any) => {
    const values = [...this.state.productServices]
    if (event?.target) {
      values[index][event.target.name] = event.target.value;
      this.setState({ productServices: values });
    } else {
      if (event.value.type === 'service') {
        values[index]['service_id'] = event.value.id;
        values[index]['product_id'] = null;
        values[index]['quantity'] = 1;
        values[index]['totalCost'] = event.value.total_price;
        values[index]['unitPrice'] = event.value.total_price;
      } else {
        values[index]['service_id'] = null;
        values[index]['product_id'] = event.value.id;
        values[index]['quantity'] = 1;
        values[index]['unitPrice'] = event.value.total_price;
      }
      values[index]['description'] = event.value.description;
      this.setState({ productServices: values });
    }
  }

  handleDownloadFile = async (file: any) => {
    try {
      const url = file.url ? file.url : URL.createObjectURL(file);

      const response = await fetch(url);
      const blob = await response.blob();

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = file.name

      downloadLink.click();

      URL.revokeObjectURL(downloadLink.href);
    } catch (error) {
      this.setState({
        showErrorMessage: true,
        errorMessage: "Error downloading the file, please try again.",
      });
    }
  };

  handleChangeProductFiles = (index: any, event: any) => {
    const values = [...this.state.productServices];
    const files: any = event.target?.files;
    if (event.target?.files?.length > 0 && [...values[index].files, ...event.target?.files].length > 4) {
      this.props.openToastHandler(`${this.props.t('invoice.maxFile')}`, "error")
    } else if (event.target?.files?.length > 0 && [...values[index].files, ...event.target?.files].length > 0 && [...values[index].files, ...event.target?.files].length < 5) {
      this.setState({
        showErrorMessage: false,
        errorMessage: "",
      });
      values[index][event.target?.name] = [...values[index].files, ...event.target?.files]
      this.setState({ files: [...this.state?.files, ...files], productServices: values, fileToaster: false, imgToasterText: "" }, () => {
        this.handleFileUpload(this.state?.files, index);
      });
    }
  };

  handleFileUpload = (files: any, index: any) => {
    if (files) {
      for (const file of files) {
        if (!this.state.extensionIcons[file.name.split(".")[1]]) {
          this.setState({ files: [] })
          break;
        }
        const reader: any = new FileReader();
        reader.onloadstart = this.readUploadOnLoadStart(index)
        reader.onprogress = (event: any) => this.readUploadOnProgress(event, index);
        reader.onload = this.readUploadOnLoad(index);

        reader.onerror = this.readUploadOnError(index);
        reader.readAsDataURL(file);
      }
    }
  }

  readUploadOnLoadStart = (index: any) => {
    const values = [...this.state.productServices]
    values[index]['progress'] = 0;
    this.setState({ productServices: values });
  }

  readUploadOnProgress = (event: any, index: any) => {
    if (event.lengthComputable) {
      const percentComplete = (event.loaded / event.total) * 100
      const values = [...this.state.productServices]
      values[index]['progress'] = percentComplete;
      this.setState({ productServices: values });
    }
  }

  readUploadOnLoadCallbackTimeout = (index: any) => {
    const values = [...this.state.productServices]
    values[index]['completed'] = true
    this.setState({ productServices: values, count: 0 });
  };

  readUploadOnLoadCallback: any = (index: any) => {
    setTimeout(this.readUploadOnLoadCallbackTimeout(index), 500);
  }

  readUploadOnLoad = (index: any) => {
    const values = [...this.state.productServices]
    values[index]['progress'] = 100
    setTimeout(() => {
      this.setState({ productServices: values })
      this.setState((prevState) => ({
        count: prevState.count + 1,
      }), () => this.readUploadOnLoadCallback(index));
    }, 500);
  };

  readUploadOnError = (index: any) => {
    this.setState({
      // toasterText1: "File could not be read!",
      fileToaster: true,
    });
  };
  handleProductDocument = (e: any) => {
    let reader = new FileReader();
    const files = e.target?.files[0];
    if (files) {
      reader.readAsDataURL(files);
    }
    this.setState({
      productServices: { ...this.state.productServices, [e.target?.name]: e.target?.files }
    });
  }
  // Price section
  handleChangePaymentMethodRef = (selected: any) => {
    this.setState({ collectedPaymentForm: { ...this.state.collectedPaymentForm, payment_method: selected.value } })
  }
  handleChangeCurrencyRef = (selected: any) => {
    this.setState({ priceSectionData: { ...this.state.priceSectionData, currency: selected.value } })
  }
  getSubTotal = (productServices: any) => {
    let total: any = 0;
    productServices?.forEach((item: any) => {
      if (item.quantity && item.unitPrice) {
        total += item.quantity * parseFloat(item.unitPrice);
      } else {
        total += parseFloat(item.totalCost);
      }
    });
    return total;
  };
  currencyDiscount = (discount: number,subTotal:number) =>{
    if(this.state.selectCurrency !== '%'){
      return subTotal - this.state.priceSectionData.discount
    }else{
      return discount
    }
  }
  subTotalAfterDiscount = (currencyDiscountAmount:number,subTotal:number) =>{
    if(this.state.selectCurrency !== '%'){
      return currencyDiscountAmount
    }else{
      return subTotal - currencyDiscountAmount
    }
  }
  calculateTotal = () => {
    const subTotal = this.getSubTotal(this.state.productServices);
    const discount = (subTotal * this.state.priceSectionData.discount) / 100;
    const currencyDiscountAmount = this.currencyDiscount(discount,subTotal)
    const subtotalAfterDiscount = this.subTotalAfterDiscount(currencyDiscountAmount,subTotal);
    const taxAmount = (subtotalAfterDiscount * this.state.priceSectionData.tax) / 100;
    const total = subtotalAfterDiscount + taxAmount;
    return total.toFixed(2);
  };

  handleCollectionPaymentClose = () => {
    this.setState({
      openNextModal: 'payment'
      , collectedPayment: false, nextModal: '', collectedPaymentForm: { amount: '', payment_method: '', transaction_date: '', details: '' }
    }, () => this.invoicePayment());
  }
  handleCollectionPaymentSave = () => {
    this.invoicePayment()
  }
  handlePaymentModalClose = () => {
    this.setState({
      collectedPayment: false,
      anchorEl: null,
      openNextModal: '',
      nextModal: '',
      collectedPaymentForm: {
        amount: '', payment_method: '', transaction_date: '', details: ''
      }
    })
  }
  handleTransactionDate = (date: Date) => {
    this.setState({ collectedPaymentForm: { ...this.state.collectedPaymentForm, transaction_date: date } })
  }
  invoicePayment = () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
      "token": token
    };
    let invoicePaymentBody = new FormData();
    invoicePaymentBody.append("data[invoice_deposit_attributes][amount]", this.state.collectedPaymentForm.amount);
    invoicePaymentBody.append("data[invoice_deposit_attributes][pay_balance]",this.state.collectedPaymentForm.pay_balance);
    invoicePaymentBody.append("data[invoice_deposit_attributes][payment_method]", this.state.collectedPaymentForm.payment_method);
    invoicePaymentBody.append("data[invoice_deposit_attributes][transaction_date]", this.state.collectedPaymentForm.transaction_date);
    invoicePaymentBody.append("data[invoice_deposit_attributes][collected]",this.state.collectedPaymentForm.collected);
    invoicePaymentBody.append("data[invoice_deposit_attributes][details]", this.state.collectedPaymentForm.details);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallIdQuoteMakePayment = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), ""
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), invoicePaymentBody
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `${configJSON.paymentApiMethodtype}?lang=${this.state.selectLanguage}`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handlePriceSection = (e: any) => {
    this.setState({ priceSectionData: { ...this.state.priceSectionData, [e.target?.name]: e.target?.value } });
  }

  selectTaxRate = (taxRate: TaxRateResponse) => {
    this.setState({
      priceSectionData:{
        ...this.state.priceSectionData,
        tax_name: taxRate.name,
        tax: taxRate.rate,
      }
    })
  }
  emptyPriceSectionData = (key: string) => {
    if (key === "discount") {
      this.setState({
        priceSectionData: {
          ...this.state.priceSectionData,
          discount: "",
        }
      })
    } else {
      this.setState({
        priceSectionData: {
          ...this.state.priceSectionData,
          tax_name: "",
          tax: "",
        }
      })
    }
    
  }


  handleCollectionPaymentModal = () => {
    this.setState({
      collectedPaymentForm: {
        amount: '',
        payment_method: '',
        transaction_date: '',
        details: '',
      }
    })

    this.setState({ isQuoteErrorformData: this.validateQuote(this.state.selectedQuoteCustomer, this.state.siteDetails) },
      () => {
        if (Object.keys(this.state.isQuoteErrorformData).length === 0) {
          this.createQuote();
        }
      }
    )

    this.setState({ nextModal: 'payment' });
  }
  handleChange = (e: any) => {
    this.setState({ collectedPaymentForm: { ...this.state.collectedPaymentForm, [e.target?.name]: e.target?.value } });
  }

  createJobproductAndServices = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCallIdGetProductServices = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.products_and_servicesEndPoint}?lang=${this.state.selectLanguage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  getCurrencySym = () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
      "token": token
    };
    const currencyApiCall = apiCall({
      header: header,
      httpBody: null,
      url: configJSON.getCurrency,
      httpMethod: configJSON.validationApiMethodType,
    });
    this.currencyApiCallId = currencyApiCall.messageId;
    runEngine.sendMessage(currencyApiCall.id, currencyApiCall);
  }
  getJobEditedFiles = (files: any) => {
    this.setState({
      jobEditedFiles: files
    })
  }
  getJobConvertedFiles = (files: any) => {
    this.setState({
      jobConvertedFiles: files
    })
  }
  updateInternalNotes = (internalNotes: string) => {
    this.setState({ internalNotes: internalNotes })
  }
  updateInternalAttachments = (attachments: any) => {
    this.setState({ internalAttachments: attachments })
  }
  handleOpenSaveMore = (event: any) => {
    this.setState({
      anchorElV1: event?.currentTarget
    }, this.handleOpenSaveMoreCallbackV1)
  }

  handleOpenSaveMoreCallbackV1 = async () => {
    const isAuthorized = await performInvoiceAuthorizationCheck("quote");
    this.handleOpenSaveMoreCallback(isAuthorized)
  }

  handleOpenSaveMoreCallback = (isAuthorized: any,) => {
    if (!isAuthorized) {
      this.setState({
        anchorEl: this.state.anchorElV1
      })
    } else {
      this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
    }
  }
  handleCloseSaveMore = () => {
    this.setState({
      anchorEl: null
    })
  };
  handleQuoteDeleteModal = async () => {
    const isAuthorized = await performInvoiceDeleteAuthorizationCheck("quote");
    this.handleQuoteDeleteModalCallback(isAuthorized)
  }
  handleQuoteDeleteModalCallback = (isAuthorized: any) => {
    if (!isAuthorized) {
      this.setState({
        quoteDeletedailog: true
      })
    } else {
      this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
    }
  }
  handleCloseQuoteDeleteDailog = () => {
    this.setState({ quoteDeletedailog: false })
  }
  handleQuoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ quoteForm: { ...this.state.quoteForm, [event?.target.name]: event?.target.value } },
      ()=>{
        this.setState({ isQuoteErrorformData: { ...this.state.isQuoteErrorformData, ...this.quoteTitleValidation() } })

      });
  }
  handleOnBlur = () => {
    this.setState({ isQuoteErrorformData: {...this.state.isQuoteErrorformData,...this.quoteTitleValidation()} })
  }
  productOnBlur = () => {
    this.setState({ isQuoteErrorformData: {...this.state.isQuoteErrorformData,...this.productServiceValidation()} })
  }
  handleCustomerValidation=()=>{
    this.setState({ isQuoteErrorformData: {...this.state.isQuoteErrorformData,...this.quoteCustomerValidation()} })
  }
  quoteCustomerValidation = () => {
    const errors = { customer: '', site: "" }
    this.validateCustomerSite(this.state.selectedQuoteCustomer, this.state.siteDetails, errors)
    this.clean(errors)
    return errors;
  }
  productServiceValidation = () => {
    const errors = { product_id: [], service_id: [] }
    this.validateProductsAndServices(this.state.productServices, errors);
    this.clean(errors)
    return errors;
}
  quoteTitleValidation = () => {
    const errors = { title: "" }
    this.validateQuoteForm(this.state.quoteForm.quoteTitle, errors, 'quoteTitle')
    this.clean(errors)
    return errors;
  }
  handleBack = () => {
    this.props.navigation.history.goBack();
  }
  handleSoftDeleteQuote = () => {
    this.softDeleteQuote();
    this.setState({ softDeleteQuoteLoading: true });
  }
  selectCurrency = (selected: { value: string; label: string}) => {
    const { priceSectionData } = this.state;
    this.setState({
      selectCurrency: selected.value,
      priceSectionData: {
        ...priceSectionData,
        discount: 0
      }
    });
  }
  // Api Calls

  createQuote = () => {
    this.setState({ createQuoteLoading: true });
    const header = {
      "token": this.token
    };

    let createQuoteBody = new FormData();
    createQuoteBody.append("data[quote_title]", this.state.quoteForm.quoteTitle);
    //@ts-ignore
    createQuoteBody.append("data[customer_id]", this.state.selectedQuoteCustomer.id);
    createQuoteBody.append("data[site_id]", this.state.siteDetails?.id || this.state?.requestDetails?.attributes?.site_id);
    createQuoteBody.append("data[quote_date]", this.state.quoteForm.quoteDate);
    createQuoteBody.append("data[due_date]", this.state.quoteForm.dueDate);
    createQuoteBody.append("data[service_detail]", this.state.quoteForm.serviceDetails);
    createQuoteBody.append("data[internal_notes]", this.state.internalNotes)
    for (let file of this.state.internalAttachments) {
      createQuoteBody.append("data[files][]", file);
    }
    this.productServicesFormData(createQuoteBody);
    createQuoteBody.append("data[sub_total]", this.getSubTotal(this.state.productServices));
    createQuoteBody.append("data[total]", this.calculateTotal());
    createQuoteBody.append("data[tax_name]", this.state.priceSectionData.tax_name);
    createQuoteBody.append("data[tax]", this.state.priceSectionData.tax);
    createQuoteBody.append("data[currency]", this.state.currency);
    createQuoteBody.append("data[discount]", this.state.priceSectionData.discount);
    createQuoteBody.append("data[discount_type]", this.state.selectCurrency);
    createQuoteBody.append("data[invoice_deposit_attributes][payment_method]", this.state.collectedPaymentForm.payment_method);
    createQuoteBody.append("data[invoice_deposit_attributes][amount]", this.state.collectedPaymentForm.amount);
    createQuoteBody.append("data[invoice_deposit_attributes][transaction_date]", this.state.collectedPaymentForm.transaction_date);
    createQuoteBody.append("data[invoice_deposit_attributes][details]", this.state.collectedPaymentForm.details);
    createQuoteBody.append("data[invoice_deposit_attributes][collected]",this.state.collectedPaymentForm.collected);
    createQuoteBody.append("data[invoice_deposit_attributes][pay_balance]", this.state.collectedPaymentForm.pay_balance);
    createQuoteBody.append("data[request_id]", this.state.requestDetails?.id||this.state.singleQuoteDetails?.attributes?.request_id||"");
    createQuoteBody.append("data[job_id]", this.props.navigation?.history?.location?.state?.jobId||"");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallIdCreateQuote = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.quoteEndPoint}?lang=${this.state.selectLanguage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), createQuoteBody
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getQuoteDetails = async () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const customerQuoteId = await getStorageData('customerTabId')
    const quote_id = localStorage.getItem("quote_id");
    if(quote_id||customerQuoteId){
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
   
    this.apiCallIdGetQuoteDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getQuoteEndPoint}/${quote_id||customerQuoteId}?lang=${this.state.selectLanguage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  getQuoteCustomerDetails = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCustomerDetailsApiCallId = requestMessage.messageId;
    if (this.props?.location?.state?.convertedType === "RequestTo") {
      if(this.state.requestDetails.attributes?.customer_id){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.customersEndPoint}/${this.state.requestDetails?.attributes?.customer_id}&lang=${this.state.selectLanguage}`
      )}
    } else {
      if(this.state.singleQuoteDetails.attributes?.customer_id){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.customersEndPoint}/${this.state.singleQuoteDetails?.attributes?.customer_id}&lang=${this.state.selectLanguage}`
      )}
    }
    if(this.props.location?.state?.reDirectionFrom==='customer'){
      if(this.props.location.state?.id){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.customersEndPoint}/${this.props.location?.state?.id}&lang=${this.state.selectLanguage}`
      )}
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateQuote = () => {
    this.setState({ updateQuoteLoading: true });
    const header = {
      "token": this.token
    };
    const quote_id = localStorage.getItem("quote_id");
    if(quote_id){
    let updateQuoteBody = new FormData();
    updateQuoteBody.append("data[quote_title]", this.state.quoteForm.quoteTitle);
    //@ts-ignore
    updateQuoteBody.append("data[customer_id]", this.state.selectedQuoteCustomer.id);
    updateQuoteBody.append("data[site_id]", this.state.siteDetails.id || this.state.singleQuoteDetails?.attributes?.site_id);
    updateQuoteBody.append("data[quote_date]", this.state.quoteForm.quoteDate);
    updateQuoteBody.append("data[due_date]", this.state.quoteForm.dueDate);
    updateQuoteBody.append("data[service_detail]", this.state.quoteForm.serviceDetails);
    updateQuoteBody.append("data[internal_notes]", this.state.internalNotes)
    for (let file of this.state.internalAttachments) {
      updateQuoteBody.append("data[files][]", file);
    }
    for (let file of this.state.jobConvertedFiles) {
      updateQuoteBody.append("data[file_id][]", file.id);
    }
    this.productServicesFormData(updateQuoteBody);
    updateQuoteBody.append("data[sub_total]", this.getSubTotal(this.state.productServices));
    updateQuoteBody.append("data[total]", this.calculateTotal());
    updateQuoteBody.append("data[tax_name]", this.state.priceSectionData.tax_name || "");
    updateQuoteBody.append("data[tax]", this.state.priceSectionData.tax || "");
    updateQuoteBody.append("data[currency]", this.state.currency || "");
    updateQuoteBody.append("data[discount]", this.state.priceSectionData.discount || "");
    updateQuoteBody.append("data[discount_type]", this.state.selectCurrency);
    updateQuoteBody.append("data[invoice_deposits_attributes][id]", this.state.collectedPaymentForm.id);
    updateQuoteBody.append("data[invoice_deposits_attributes][amount]", this.state.collectedPaymentForm.amount || "");
    updateQuoteBody.append("data[invoice_deposits_attributes][transaction_date]", this.state.collectedPaymentForm.transaction_date);
    updateQuoteBody.append("data[invoice_deposits_attributes][details]", this.state.collectedPaymentForm.details || "");
    updateQuoteBody.append("data[invoice_deposit_attributes][pay_balance]", this.state.collectedPaymentForm.pay_balance);
    updateQuoteBody.append("data[invoice_deposit_attributes][collected]",this.state.collectedPaymentForm.collected);
    if(this.state.collectedPaymentForm.payment_method){
      updateQuoteBody.append("data[invoice_deposits_attributes][payment_method]", this.state.collectedPaymentForm.payment_method);
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallIdUpdateQuote = requestMessage.messageId;
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateQuoteEndPoint}${quote_id}?lang=${this.state.selectLanguage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), updateQuoteBody
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  productServicesFormData = (updateQuoteBody: any) => {
    if (this.state.productServices && Array.isArray(this.state.productServices)) {
      for (let [i, product] of this.state.productServices.entries()) {
        if (product.product_id) {
          updateQuoteBody.append(`data[product_items_attributes][${[i]}][product_id]`, product.product_id);
        }
        if (product.service_id) {
          updateQuoteBody.append(`data[product_items_attributes][${[i]}][service_id]`, product.service_id);
        }
        updateQuoteBody.append(`data[product_items_attributes][${i}][quantity]`, product.quantity);
        updateQuoteBody.append(`data[product_items_attributes][${i}][unit_price]`, product.unitPrice);
        updateQuoteBody.append(`data[product_items_attributes][${i}][total]`, product.quantity * product.unitPrice || product.totalCost);
        updateQuoteBody.append(`data[product_items_attributes][${i}][description]`, product.description);
        this.productServicesFiles(updateQuoteBody, product, i);
      }
    }
  }

  productServicesFiles = (updateQuoteBody: any, product: any, i: any) => {
    if (product.file_id && product.file_id.length > 0) {
      updateQuoteBody.append(`data[product_items_attributes][${[i]}][file_id]`, product.file_id);
    }
    if (product.files) {
      for (let updatedFiles of product.files) {
        if (!updatedFiles.id) {
          updateQuoteBody.append(`data[product_items_attributes][${i}][files][]`, updatedFiles);
        }
      }
    }
    if (product.product_item_id) {
      updateQuoteBody.append(`data[product_items_attributes][${[i]}][product_item_id]`, product.product_item_id);
    }
  }

  softDeleteQuote = () => {
    const header = {
      "token": this.token
    };
    const quote_id = localStorage.getItem("quote_id");
  if(quote_id){
    let deleteQuoteBody = new FormData();
    deleteQuoteBody.append("data[status]", "cancelled");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallIdSoftDeleteQuote = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateQuoteEndPoint}${quote_id}?lang=${this.state.selectLanguage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), deleteQuoteBody
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  }

  validateQuote = (customer: any, site: any) => {
    let errors = { quantity: [], unitPrice: [], product_id: [] };
    this.validateQuoteForm(this.state.quoteForm.quoteTitle, errors, 'quoteTitle')
    this.validateProductsAndServices(this.state.productServices, errors);
    this.validateCustomerSite(customer, site, errors)
    this.clean(errors)
    return errors;
  }

  validateCustomerSite = (customer: any, site: any, errors: any) => {
    if (Object.keys(customer).length === 0 && Object.keys(site).length === 0) {
      errors.customer = `${this.props.t('errorMessage.customerId')}`

    }

  }
  validateProductsAndServices = (array: any, errors: any) => {
    array?.forEach((item: any, index: any) => {
      if ((item.product_id === null || item.product_id === '') && (item.service_id === null || item.service_id === '')) {
        errors.product_id[index] = `${this.props.t('errorMessage.productService')}`
      }
    });
  }

  validateQuoteForm = (title: any, errors: any, key: any) => {
    if (!title) {
      errors[key] = `${this.props.t('quote.errorMessage.title')}`
    } else if ((this.state.quoteForm.quoteTitle).length > 40) {
      errors[key] = `${this.props.t('quote.errorMessage.titleLength')}`
    }
  }

  saveQuoteButton = (status: string) => {
    this.setState({ savingStatus: status, isQuoteErrorformData: this.validateQuote(this.state.selectedQuoteCustomer, this.state.siteDetails) }, () => {
      if (Object.keys(this.state.isQuoteErrorformData).length === 0) {
        this.createQuote();
       
      }
    })
  }

  updateQuoteButton = () => {
    this.setState({ isQuoteErrorformData: this.validateQuote(this.state.selectedQuoteCustomer, this.state.siteDetails) }, () => {
      if (Object.keys(this.state.isQuoteErrorformData).length === 0) {
        this.updateQuote();
      }
    })
  }

  clean = (obj: any) => {
    for (let propName in obj) {
      if (obj[propName]?.length === 0) {
        delete obj[propName];
      }
    }
    return obj
  }

  clearCustomerSite = () => {
    this.setState({
      selectedQuoteCustomer: {},
      siteDetails: {},
      customerSelected: false,
      selectCustomerButton: false,
      getCustomerPlace: null
    },()=>{
      this.setState({isQuoteErrorformData:{...this.state.isQuoteErrorformData,...this.quoteCustomerValidation()}})
  })
  }
  handleQuoteDrawerClose = () => {
    this.setState({ drawerStatus: false })
  }
  handleQuoteDrawerOpen = () => {
    this.setState({ drawerStatus: true })
  }
  handlePayBalance = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ collectedPaymentForm: { ...this.state.collectedPaymentForm, pay_balance: event.target?.checked } });
  }
  handleCollected = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ collectedPaymentForm: { ...this.state.collectedPaymentForm, collected: event.target?.checked } });
  }
  getQuoteRequestDetails = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };
    if(this.props.location?.state?.requestId){
    this.setState({ getQuoteLoading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallIdDetailsGetRequest = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRequest}/${this.props.location?.state?.requestId}?lang=${this.state.selectLanguage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  convertQuoteToJob = () => {
    const convertedRedirection=this.props.navigation?.history?.location?.state?.convertedType
    if(convertedRedirection ==="RequestTo"){
      this.props.navigation.history.push("/Job/Create", {
        convertedType: "RequestTo",
        requestId:this.state.requestDetails?.id,
        quoteid: localStorage.getItem("quote_id")

      })
    }else{
      this.props.navigation.history.push("/Job/Create", {
        convertedType: "QuoteTo",
        quoteid: localStorage.getItem("quote_id")
      })
    }
   
  }

  getCustomerDailogStatus1 = (selected: boolean) => {
    this.setState({
      selectCustomerButton: selected,
    })
  }
  getSiteDetails = (details: any) => {
    this.setState({
      siteDetails: details
    })
  }
  siteInformationApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({
        siteDetails: responseJson.data
      }, () => {
        this.getCustomerLocationData(this.state.siteDetails)

      })
    }

  }
  getSelectedSiteDetails = () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    if(this.state.singleQuoteDetails.attributes?.site_id || this.state.requestDetails.attributes?.site_id){
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSiteDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.siteEndPoint}/${this.state.singleQuoteDetails?.attributes?.site_id || this.state.requestDetails?.attributes?.site_id}?lang=${this.state.selectLanguage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  getCustomerSelectedStatus = (customerSelected: boolean, customerDailog: boolean) => {
    this.setState({
      customerSelected: customerSelected,
      cutstomerDailogOpen: customerDailog,

    })
  }

  handleQuoteDateChange = (date: any) => {
    this.setState({ quoteForm: { ...this.state.quoteForm, quoteDate: date } });
  }

  handleDueDateChange = (date: any) => {
    this.setState({ quoteForm: { ...this.state.quoteForm, dueDate: date } });

  }

  quoteDiscountDailog = () => {
    if(this.state.isEditQuote){
      this.setState({
        discountDialog: !this.state.discountDialog
      })
    }
  }
  jobproductDetails = () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const jobproductId = this.props.navigation?.history?.location?.state?.jobId
    if(jobproductId){
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallIdGetJobProductDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.JobsEndPoint}/${jobproductId}?lang=${this.state.selectLanguage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  jobProductServicesApiResponce = (responceJson: any) => {
    if (responceJson && !responceJson.errors) {
      let jobProductsData = responceJson.data?.attributes?.products?.data.map((product: any, index: number) => {
        return {
          product_id: product.attributes.product_id,
          service_id: product?.attributes?.service_id,
          productName: product.attributes.product_name,
          description: product.attributes.description,
          quantity: product.attributes.quantity,
          unitPrice: product.attributes.unit_price,
          totalCost: product.attributes.total || "",
          files: product?.attributes?.files || [],
          product_item_id: product?.id,
          completed: true,
          pogress: 100
        }
      })

      this.setState({ productServices: jobProductsData || [] })
    }
  }

  // Customizable Area End
}
