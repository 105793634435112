import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  Container,
  Typography,
  InputBase,
  TextField,
  MenuItem,
  InputAdornment,
  TableContainer,
  TableCell,
  ClickAwayListener, Divider, Hidden,Checkbox
} from "@material-ui/core";
import {
  withStyles,createStyles,Theme,styled
} from "@material-ui/core/styles";
import {
  dropDownSelectIcon
} from "./assets";
import { CreateQuote } from "../../../components/src/CustomizableComponents.web";
import { backIconMobile } from "../../CustomisableUserProfiles/src/assets";
import { withSnackbar } from "../../../components/src/toastweb";
import withPermission from "../../../components/src/withPermission.web"
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { StyledRoot } from "../../dashboard/src/utility.web";
import { StyledCheckbox } from "../../Settings/src/CompanyInformation.web";
export const configJSON = require("./config");
import { goBack } from "../../../components/src/CommonFunction"
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import CustomDatePicker from "../../../components/src/CustomDatePicker";
// Customizable Area End

import RequestsController, { Props } from "./RequestController.web";
import { deleteIcon, searchIcon } from "../../dashboard/src/assets";
import moment from "moment";
import DeleteModel from "./DeleteModal.web";
import { CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
// Customizable Area End
// Customizable Area Start
export const StyledTextField = withStyles({
  root: {
    '&.language-ar': {
      "& .MuiInputAdornment-positionEnd": {
        marginLeft: "13px",
      },
      "& .MuiOutlinedInput-adornedEnd": {
        paddingRight: "0px",
      },
    },
    "& .MuiInputBase-root": {
      height: "56px",
      borderRadius: "10px",
      fontFamily: "Rubik",
      color: "#626262",
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "16px",
      cursor: "pointer",
      
    },
    "& .MuiOutlinedInput-input": {
      padding: "15px 14px",
      cursor: "pointer",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1c18af",
      borderWidth: "2px"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: CONSTANTS.color.darkBlue,
      borderWidth: "2px"
    },
    "& .MuiSvgIcon-root": {
      fontSize: "2.1rem",
      marginTop: "-5px",
    }
  }
})(TextField);

export const StyledGrid = withStyles({
  root: {
   
    "&:hover": {
      backgroundColor: `${CONSTANTS.color.blueViolet} !important`,
      "& .MuiTypography-root": {
        color: `${CONSTANTS.color.white} !important`,
      },

    },
    cursor: "pointer",
  }
})(Grid);

export const RequestStyledTableContainer = withStyles({
  root: {
    "& .MuiPaper-elevation1": {
      boxShadow: "none"
    }
  }
})(TableContainer);

export const StyleTableCell = withStyles({
  root: {
    "& .MuiTableCell-root": {
      padding: "0px"
    }
  }
})(TableCell);
interface RequestsStatusData{
  statusName:string
  id:number
  title:string
}
// Customizable Area End

export class Requests extends RequestsController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  statusModalRequest = (mockRequestsStatusData: { id: number; title: string; statusName: string; }[]) => {
    return (
      <ClickAwayListener onClickAway={this.handleStatusRequestModalClose}>
        <Typography>
          <Box style={webStyle.statusModalContainerRequest}>
            <Box style={webStyle.statusModalRequest}>
              {mockRequestsStatusData.map((item:RequestsStatusData) => (
                <Box key={item.id} display={"flex"} mb={1} gridGap={"0.5rem"}>
                  <Checkbox
                    data-test-id="handleSelectStatus"
                    checked={this.state.checkedItems.some((subItem) => item.statusName === subItem)}
                    onChange={this.handleSelectStatus.bind(this,item,mockRequestsStatusData)}
                    style={{ padding: 0, color: CONSTANTS.color.darkBlue }} />
                  <Typography style={webStyle.menuItemStyleRequest} variant="body1">
                    {item.title}
                  </Typography>
              </Box>
              ))}
            </Box>
          </Box>
        </Typography>
      </ClickAwayListener>
    );
  }

  requestToggleModal = () => {
    const { classes } = this.props;
    return (
      <ClickAwayListener onClickAway={this.handleToggleRequestModalClose}>
        <Typography>
          <Box style={webStyle.toggleRequestModalContainer} className={classes.toggleRequestModalContainer}>
            <Box style={webStyle.toggleModalStyleRequest}>
              {this.state.requestToggleData.map((item: any) => (
                <Box style={webStyle.toggleIconsContainerRequest} key={item.id}>
                  <StyledCheckbox
                    data-test-id="handleToggleColumnRequest"
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    checked={item.hidden}
                    onChange={() => this.handleToggleColumnRequest(item.id)}
                  />
                  <MenuItem
                    style={webStyle.menuItemStyleRequest}
                    value={item.title}
                    disableRipple
                  >
                    {item.title}
                  </MenuItem>
                </Box>
              ))}
            </Box>
          </Box>
        </Typography>
      </ClickAwayListener>
    );
  }

  requestViewData = ({ key, classes, item }:any) => {
    return <>
      {this.state.requestToggleData[0]?.hidden &&
        (<Grid item data-test-id="viewData"
          className={classes.tableCellLayout}
          style={{ width: `${(100 / this.state.requestToggleData.filter((item:any) => item.hidden).length)}%`, minWidth: "185px" }}
          onClick={() => this.handleViewRequestData(item)}
        >
          <Typography variant="body1" className={classes.textTableCell}>

          {item.attributes?.request_title}
          </Typography>
        </Grid>
        )}
      {this.state.requestToggleData[1]?.hidden &&
        (<Grid item data-test-id="viewData1"
          className={classes.tableCellLayout}
          style={{ width: `${(100 / this.state.requestToggleData.filter((item:any) => item.hidden).length)}%`, minWidth: "185px" }}
          onClick={() => this.handleViewRequestData(item)}
        >
          <Typography variant="body1" className={classes.textTableCell}>

          {item?.attributes.custmoer_name}
          </Typography>
        </Grid>
        )}
      {this.state.requestToggleData[2]?.hidden &&
        (<Grid item data-test-id="viewData2"
          className={classes.tableCellLayout}
          style={{ width: `${(100 / this.state.requestToggleData.filter((item:any) => item.hidden).length)}%`, minWidth: "185px" }}
          onClick={() => this.handleViewRequestData(item)}
        >
          <Typography variant="body1" className={classes.textTableCell}>
          {item?.attributes.source}
            </Typography>
        </Grid>
        )}
      {this.state.requestToggleData[3]?.hidden &&
        (<Grid item data-test-id="viewData3"
          className={classes.tableCellLayout}
          style={{ width: `${(100 / this.state.requestToggleData.filter((item:any) => item.hidden).length)}%`, minWidth: "185px" }}
          onClick={() => this.handleViewRequestData(item)}
        >
          <Typography variant="body1" className={classes.textTableCell}>

          {moment(item?.attributes.appointment_date).format("DD/MM/YYYY").toString()}
          </Typography>
        </Grid>
        )}
      {this.state.requestToggleData[4]?.hidden &&
        (<Grid item
          className={classes.tableCellLayout}
          style={{
            width: `${(100 / this.state.requestToggleData.filter((item:any) => item.hidden).length)}%`, minWidth: "185px"
          }}
          onClick={() => this.handleViewRequestData(item)}
        >
          <Typography variant="body1" className={classes.textTableCell}>

          {item?.attributes.appointment_time}
          </Typography>
        </Grid>
        )}
      {(key === "cancelled" && item.id === this.state.hoverRow) &&
        <Grid item
          style={{ zIndex: 1, position: 'absolute', width: '80px', height: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center' }}
          onClick={() => this.handleDeleteDailog(item.id)}
          data-test-id='handleDeleteDialog'
          className={classes.tableCellBodyDeleteIcon}
        >
          <img src={deleteIcon} style={webStyle.deleteIcon} />
        </Grid>
      }

    </>
  }

  requestSortRow = ({ classes,mockRequestsTableRow }:any) => {
    return <>
      {mockRequestsTableRow.map((item: any, index: any) => (
        <>
          {
            this.state.requestToggleData[index]?.hidden && (
              <Grid item
                className={classes.padding_16Head}
                key={item.id}
                onClick={() => this.handleSortRequest(item.key)}
                data-test-id="handleSort"
                style={this.state.sortColumn === item.key
                  ? { backgroundColor: "rgba(232,232,232,0.4)", width: `${(100 / this.state.requestToggleData.filter((item:any) => item.hidden).length)}%`, minWidth: "185px" }
                  : { width: `${(100 / this.state.requestToggleData.filter((item:any) => item.hidden).length)}%`, minWidth: "185px" }}
              >
                <span
                  style={webStyle.requestTableHeadSpan}
                >
                  <Typography variant="body1" align="left"  className={classes.textTableCell}>
                    {item.title}
                  </Typography>
                  <img
                    style={(this.state.sortColumn === item.key) && this.state.sortDirection === 'asc' ? { ...webStyle.dropDownSelectIconTable, transform: 'rotate(180deg)' } : webStyle.dropDownSelectIconTable}
                    src={dropDownSelectIcon}
                    className={classes.dropDownSelectIcon}
                    alt=""
                  />
                </span>
              </Grid>
            )
          }
        </>
      ))}
    </>
  }

  requestHeadings = (key: string, classes: ClassNameMap<string>, mockRequestsStatusData: {
    id: number; title: string // Customizable Area Start
    ; statusName: string;
  }[]) => {
    const { t } = this.props
    if (key === "converted_to_job") {
      return (
      <>
      <Grid container>
            <Typography variant="body2" className={classes.statusTextConverted}>{t('request.statusMenu.convertedToJob')}</Typography>
          </Grid>
          <Divider style={webStyle.dividerColorConverted} />
        </>
      )

    } else {
      return (
        <>
          <Grid container>
            <Typography variant="body2"
            
              className=
              {key === "pending" ? classes.statusTextPending : this.findClasses(key,classes) }>
              {mockRequestsStatusData?.find((item: any) => item.statusName === key)?.title}
            </Typography>
          </Grid>
          <Divider style={key === "pending" ? webStyle.dividerColorPending : this.findDividerColor(key)} />
        </>
      )
    }
  }
  findDividerColor = (key: string) => {
    return key === "quoted" ?
      webStyle.dividerColorQuoted : this.findDividerColorV1(key)
  }
  findDividerColorV1 = (key:string)=>{
    return  key === "overdue"
    ? webStyle.dividerColorOverdue : webStyle.dividerColorCancelled
  }
  findClasses = (key: string,classes:any) => {
    return key === "quoted" ?
      classes.statusTextQuoted : this.findClassesV1(key,classes)
  }

  findClassesV1 = (key:string,classes:any)=>{
    return key === "overdue"
    ? classes.statusTextOverdue : classes.statusTextCancelled
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes,t } = this.props;
    const mockRequestsStatusData = [
      { id: 1, title: `${t('request.statusMenu.all')}`, statusName: "all" },
      { id: 2, title: `${t('request.statusMenu.pending')}`, statusName: "pending" },
      { id: 3, title: `${t('request.statusMenu.quoted')}`, statusName: "quoted"},
      { id: 4, title: `${t('request.statusMenu.convertedToJob')}`, statusName: "converted_to_job"  },
      { id: 5, title: `${t('request.statusMenu.overdue')}`, statusName: "overdue" },
      { id: 6, title: `${t('request.statusMenu.cancelled')}`, statusName: "cancelled" },
    ]
    const mockRequestsTableRow = [
      { id: 1, key: "request_title", title: `${t('request.title')}` },
      { id: 2, key: "customer_name", title: `${t('invoice.menuLabel.cusName')}` },
      { id: 3, key: "source", title: `${t('request.source')}` },
      { id: 4, key: "appointment_date", title: `${t('request.subTitles.dateText')}` },
      { id: 4, key: "appointment_time", title: `${t('request.subTitles.timeText')}`},
    ]
    return (
      <> 
        <DashboardSideBar permissions={undefined} oldProps={undefined} {...this.props} navMenuItem={this.props.t('dashboardNavbar.requests')} active={3} active1={false} active2={false} />
        <StyledRoot languageSelected={this.state.selectedLanguage}>
          <Box
            sx={{
              marginTop: "9rem",
              paddingBottom: "5rem"
            }}
            className={classes.boxRequestContainer}
          >
            <Container maxWidth={false}>
              <Grid container spacing={1}>
                <Grid item md={5} xs={12}>
                  <Box style={webStyle.backIconContainer}> 
                    <Hidden smUp>
                      <img style={webStyle.backIconMobile} src={backIconMobile} onClick={()=>goBack(this.props.navigation)} alt="" />
                    </Hidden>
                  <Box style={webStyle.requestSearchContainer}>
                 
                    <Box>
                      <img 
                        style={
                          this.state.selectedLanguage === 'en' ? 
                          webStyle.requestSearchIcon : 
                          webStyle.requestSearchIconAr
                        } 
                        src={searchIcon} alt="" />
                    </Box>
                    <InputBase
                      data-test-id="searchRequest"
                      className={classes.requestInputBase}
                      placeholder={`${t('request.placeholder.searchRequest')}`}
                      inputProps={{ 'aria-label': 'search' }}
                      value={this.state.searchRequest}
                      onChange={this.handleChangeSearchRequest}
                    />
                  </Box>
                  </Box>
                </Grid>
                <Grid item md={2}  xs={3}>
                  <CustomDatePicker
                    type='request'
                    data-testid="customDatePicker"
                    selectedDate={this.state.selectedDate}
                    status={this.state.dateStatus}
                    dateModal={this.state.dateModal}
                    datePickerRef={this.datePickerRef}
                    languageSelected={this.state.selectedLanguage}
                    handleDateChange={this.handleDateChange}
                    handleSelectStatus={this.handleSelectDateStatus}
                    handleDateModalOpen={this.handleDateModalOpen}
                    handleStatusModalClose={this.handleStatusModalClose} 
                    />
                </Grid>
                <Grid item md={2} xs={4}>
                  <StyledTextField
                    fullWidth
                    defaultValue={`${t('invoice.placeholder.status')}`}
                    className={this.state.selectedLanguage === 'ar' ? 'language-ar' : ''}
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <img style={!this.state.statusModal ? webStyle.dropDownSelectIcon : { ...webStyle.dropDownSelectIcon, transform: "rotate(180deg)" }} src={dropDownSelectIcon} alt="" />
                        </InputAdornment>
                      ),
                    }}
                    onClick={this.handleStatusRequestModalOpen}
                  >
                  </StyledTextField>
                  {this.state.statusModal ? (
                    this.statusModalRequest(mockRequestsStatusData)
                  ) : null}
                </Grid>
                <Grid item md={3} xs={5}>
                  <StyledTextField
                    fullWidth
                    defaultValue={`${t('invoice.placeholder.toggleColumns')}`}
                    variant="outlined"
                    className={this.state.selectedLanguage === 'ar' ? 'language-ar' : ''}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                         <img style={!this.state.toggleModal ? webStyle.dropDownSelectIcon :
                            { ...webStyle.dropDownSelectIcon, transform: "rotate(180deg)" }}
                            src={dropDownSelectIcon} alt="" />
                        </InputAdornment>
                      ),
                    }}
                    onClick={this.handleToggleRequestModalOpen}
                  >
                  </StyledTextField>
                  {this.state.toggleModal ? (
                    this.requestToggleModal()
                  ) : null}
                </Grid>
              </Grid>
              <StyledBox container>
                <Grid item xs={12} style={{ overflow: 'scroll', minWidth: "fit-content" }}>
                  <Grid container
                    style={{
                      display: 'flex',
                      width: '100%',
                      flexWrap: 'nowrap',
                      gridAutoFlow: 'column',
                    }}
                  >
                    {this.requestSortRow({ classes,mockRequestsTableRow })}
                  </Grid>
                  <Divider className={classes.marginTopHead} />
                  {Object.keys(this.state.allRequestsList)?.map((key: string) => {
                    return (
                      <>
                        {this.requestHeadings(key,classes,mockRequestsStatusData)}

                        {this.state.allRequestsList[key]?.data?.length === 0 &&
                          <Grid container justifyContent="center" alignItems="center"
                            style={{ height: '150px' }}
                          >
                            <Grid item xs={12}>
                              <Typography variant="subtitle1" className={classes.noRequestText} align="center">{t('ProductService.noDataFound')}.</Typography>
                            </Grid>
                          </Grid>
                        }
                        {this.state.allRequestsList[key]?.data?.length !== 0 &&
                          <Grid container style={{
                            maxHeight: '300px', overflow: 'scroll', minWidth: "fit-content"
                          }}>
                            <Grid item xs={12}>
                              {this.state.allRequestsList[key]?.data?.map((item: any) => (
                                <>
                                  <StyledGrid container
                                    style={{
                                      display: 'flex',
                                      width: '100%',
                                      flexWrap: 'nowrap',
                                      gridAutoFlow: 'column',
                                      zIndex: 0,
                                      position: 'relative',
                                      justifyContent: 'end'
                                    }}
                                    data-test-id="moveHover"
                                    key={item.id}
                                    onMouseEnter={() => {
                                      this.setState({ hoverRow: item.id });
                                    }}
                                    onMouseLeave={() => this.setState({ hoverRow: -1 })}
                                  >
                                    {this.requestViewData({ key,item,classes })}
                                  </StyledGrid>
                                  <Divider />
                                </>
                              ))}
                            </Grid>
                          </Grid>
                        }
                      </>
                    )
                  })}
                </Grid>
              </StyledBox>

              <Box style={webStyle.buttonsContainer}>
                <CreateQuote
                  data-test-id="createrequest"
                  onClick={this.handleCreateRequest}
                >
                  <Typography variant="subtitle2"
                    className={classes.buttonRequestText}
                  >
                    {t('request.button.createRequest')}
                  </Typography>
                </CreateQuote>
              </Box>
            </Container>
          </Box>

          {this.state.hardDeleteStatus &&
            <DeleteModel
              deleteType={`${t('request.hardDelete')}`}
              subType={`${t('request.hardDeleteRequestDes')}`}
              open={this.state.hardDeleteStatus}
              onClose={this.handleDeleteCloseDailog}
              handleDelete={this.deleteRequest}
              t={this.props.t}
            />}
        </StyledRoot>
        <LoadingBackdrop
          loading={this.state.getRequestsLoading}
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledBox = styled(Grid)(() => ({
  marginTop: "2rem",
  maxWidth: "100%",
  backgroundColor: CONSTANTS.color.white,
  boxShadow: "0px 16px 17px rgba(28,24,175,0.07)",
  minHeight: "300px",
  overflow: 'scroll',
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: "#1c18af"
  }
}));
export const styles = (theme: Theme) =>
createStyles ({
  boxRequestContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 0.25rem 2rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "8rem !important",
    },
  },
  buttonRequestText: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: "center",
  },
  dropDownSelectIcon: {
    width: "13px",
    height: "9px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0.5rem !important",
      width: "11px !important",
      height: "7px !important",
    }
  },
  requestTableHeadText: {
    textAlign:'start',
  },
  statusTextPending: {
    color: "#6764d6",
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: 10,
    marginRight:16,
    textTransform: "capitalize",
    marginLeft: 10,
  },
  statusTextConverted: {
    color: "#008000",
    fontWeight: theme.typography.fontWeightMedium,
    marginRight:16,
    marginTop: 10,
    marginLeft: 10,
    textTransform: "capitalize"
  },
  statusTextQuoted: {
    color: "#fa6400",
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: 10,
    marginRight:16,
    marginLeft: 10,
    textTransform: "capitalize"
  },
  statusTextOverdue: {
    color:"#e02020",
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: 10,
    marginLeft: 10,
    marginRight:16,
    textTransform: "capitalize"
  },
  statusTextCancelled: {
    color: "#8C8C8C",
    marginRight:16,
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: 10,
    marginLeft: 10,
    textTransform: "capitalize"
  },

  tableCellBodyDeleteIcon: {
    background: "#eedee4"
  },
  padding_16Head: {
    padding: '16px'
  },
  marginTopHead: {
    marginTop: '0.5rem'
  },
  textTableCell: {
    color: CONSTANTS.color.grey,
    fontWeight: theme.typography.fontWeightRegular,
  },
  tableCellLayout: {
    padding: '28px 16px 28px 16px !important'
  },
  requestInputBase: {
    width: "100%",
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: "16px",
    fontSize: "1.1667rem",
    color: CONSTANTS.color.grey
  },
  noRequestText: {
    color: CONSTANTS.color.darkGrey,
    fontWeight: theme.typography.fontWeightMedium,
  },
  toggleRequestModalContainer: {
    [theme.breakpoints.down("xs")]: {
      right: "32px !important",
    }
  },
  buttonContainerCreateRequest: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: "216px",
    height: "67px",
    borderRadius: "10px",
    backgroundColor: "#1c18af",
    [theme.breakpoints.down("xs")]: {
      width:'100%',
      height:'48px'
    }
  },
});

const webStyle = {
  requestSearchIcon: {
    height: "20px",
    width: "20px",
    margin: "0 0.6rem 0 1.3rem",
  },
  requestSearchIconAr: {
    height: "20px",
    width: "20px",
    margin: "0px 1rem 0px 0.9rem",
    marginTop:"4px"
  },
  requestSearchContainer: {
    display: "flex",
    alignItems: "center",
    minHeight: "56px",
    width: "100%",
    borderRadius: "8px",
    border: "1.5px solid rgba(0,0,0,0.1)",
  },
  backIconMobile: {
    width: "12px",
    height: "24px",
  },
  backIconContainer: {
    display: "flex",
    alignItems: "center",
    gap: "1rem"
  },
  dropDownSelectIcon: {
    width: "13px",
    height: "9px",
    cursor: "pointer"
  },
  dropDownSelectIconTable: {
    width: "13px",
    height: "9px",
    cursor: "pointer",
    marginLeft: "2rem",
    transform: "rotate(0deg)"
  },
  statusModalContainerRequest: {
    position: "absolute" as const,
    zIndex: 9,
    width: "217px",
    height: "235px",
    borderRadius: "5px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
  },
  statusModalRequest: {
    padding: "1rem"
  },
  menuItemStyleRequest: {
    color: "#626262",
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 400,
  },
  applyRequestButtonContainer: {
    display: "flex",
    justifyContent: 'flex-end',
    marginTop: "1rem"
  },
  buttonContainerApplyRequest: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: "#1c18af",
    cursor: 'pointer',
    borderRadius: "3px",
    minHeight: "40px",
    maxWidth: "84px",
    width: "100%",
  },
  buttonTextApplyRequest: {
    color: '#ffffff',
    fontFamily: 'Rubik',
    fontSize: '18px',
    fontWeight: 400,
  },
  toggleRequestModalContainer: {
    position: "absolute" as const,
    padding: "1rem",
    zIndex: 9,
    width: "254px",
    minHeight: "194px",
    borderRadius: "5px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
  },
  toggleModalStyleRequest: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
  },
  toggleIconsContainerRequest: {
    display: "flex",
    alignItems: "center"
  },
  toggleIconsRequest: {
    width: "24px",
    height: "24px"
  },
  requestTableHeadSpan: {
    cursor: "pointer",
    borderRadius: "2px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonsContainer: {
    paddingTop: "3rem",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
 
  dividerColorPending: {
    marginTop: "8px",
    backgroundColor: "#6764d6"
  },
  dividerColorConverted: {
    marginTop: "8px",
    backgroundColor: "#008000"
  },
  dividerColorQuoted: {
    marginTop: "8px",
    backgroundColor: "#fa6400"
  },
  dividerColorOverdue: {
    marginTop: "8px",
    backgroundColor:"#e02020",
  },
  dividerColorCancelled: {
    marginTop: "8px",
    backgroundColor: "#8C8C8C"
  },
  deleteIcon: {
    width: "30px",
    height: "30px"
  },
}

const RequestRouter = withStyles(styles)(Requests);
const RequestSnackBar = withSnackbar(RequestRouter);
export default withTranslation()(withPermission(RequestSnackBar));
// Customizable Area End