import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    Container,
    Typography,
    InputBase,
    MenuItem,
    InputLabel,
    InputAdornment,
    ClickAwayListener, Divider, Hidden, Tooltip, Button
} from "@material-ui/core";
import {
    withStyles, styled
} from "@material-ui/core/styles";
import {
    dropDownSelectIcon,
    threeDotsIcon,
} from "../../RequestManagement/src/assets";
import CustomDatePicker from "../../../components/src/DatePicker";
import CustomDatePickers from "../../../components/src/CustomDatePicker";
import { withSnackbar } from "../../../components/src/toastweb";
import withPermission from "../../../components/src/withPermission.web"
import Popover from '@material-ui/core/Popover';
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { StyledRoot } from "../../dashboard/src/utility.web";
import { StyledCheckbox } from "../../Settings/src/CompanyInformation.web";
export const configJSON = require("./config");
import { withTranslation } from "react-i18next";
// Customizable Area End
import { deleteIcon, searchIcon } from "../../dashboard/src/assets";
import moment from "moment";
import JobsController, { Props, ToggleData } from "./JobsController.web";
import DeleteModel from "../../RequestManagement/src/DeleteModal.web";
import { CreateQuote } from "../../../components/src/CustomizableComponents.web";
import { styles } from "../../QuoteManagement/src/Quotes.web"
import { CONSTANTS } from "../../../components/src/theme";
import { backIconMobile } from "../../CustomisableUserProfiles/src/assets";
import { goBack } from "../../../components/src/CommonFunction";
import { StyledTextField } from "../../RequestManagement/src/Requests.web"
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
// Customizable Area Start
interface JobsStatusData {
    statusName: string
    id: number
    title: string
}
interface JobsTableRow {
    key: string
    id: number
    title: string
}
// Customizable Area End

export class Jobs extends JobsController {
    constructor(props: Props) {
        super(props);
    }

    // Customizable Area Start
    statusModalJob = () => {
        const { classes } = this.props
        return (
            <ClickAwayListener onClickAway={this.handleStatusJobModalClose}>
                <Typography>
                    <Box style={webStyle.toggleJobModalContainer}>
                        <Box style={webStyle.toggleModalStyleJob}>
                            {this.state.statusToggleData.map((item: ToggleData) => (
                                <Box style={webStyle.toggleIconsContainerJob} key={item.id}>
                                    <StyledCheckbox
                                        data-test-id="handleToggleColumnJob1"
                                        color="primary"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                        checked={item.hidden}
                                        onChange={() => this.handleJobSelectStatus(item)}
                                    />
                                    <MenuItem
                                        className={classes.tableText}
                                        value={item.title}
                                        disableRipple
                                    >
                                        {item.title}
                                    </MenuItem>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Typography>
            </ClickAwayListener>
        );
    }

    dateModal = (dateMenuItem: any) => {
        const { classes, t } = this.props
        const open = Boolean(this.state.anchorEl);
        const newId = open ? 'simple-popover' : undefined;

        const StyledPopover = styled(Popover)({
            '& .MuiPopover-paper': {
                maxWidth: '500px',
                padding: '8px',
                borderRadius: '8px',
                overflowY: 'hidden'
            }
        });

        return (
            <ClickAwayListener onClickAway={this.handleDateModalClose}>
                <Typography>
                    <Box style={webStyle.toggleJobModalContainer}>
                        <Box style={webStyle.toggleModalStyleJob}>
                            {dateMenuItem.map((item: ToggleData) => (
                                <Box style={webStyle.toggleIconsContainerJob} key={item.id}>
                                    <MenuItem
                                        className={classes.tableText}
                                        value={item.title}
                                        disableRipple
                                        onClick={this.handleDateModalClose}
                                    >
                                        {item.title}
                                    </MenuItem>
                                </Box>
                            ))}
                            <Box style={webStyle.toggleIconsContainerJob}>
                                <MenuItem
                                    className={classes.tableText}
                                    disableRipple
                                    onClick={this.handleSelectDateModalOpen}
                                >
                                    {t('dashboard.custom')}
                                </MenuItem>
                            </Box>
                            <StyledPopover
                                id={newId}
                                anchorEl={this.state.anchorEl}
                                open={open}
                                onClose={this.handleSelectDateModalClose}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'center',
                                    horizontal: 'left',
                                }}
                            >
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={12} sm={6} md={6} lg={6} >
                                        <Grid container direction="row" spacing={2}>
                                            <Grid item xs={12}>
                                                <InputLabel htmlFor="my-input" >{t('jobs.tableRow.startDate')}</InputLabel>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <CustomDatePicker
                                                    selectedDate={this.state.startDate}
                                                    dateChange={this.handleStartDate}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} >
                                        <Grid container direction="row" spacing={2}>
                                            <Grid item xs={12}>
                                                <InputLabel htmlFor="my-input" className={classes.inputLabel}>{t('jobs.label.endDate')}</InputLabel>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <CustomDatePicker
                                                    minDate={this.state.startDate}
                                                    selectedDate={this.state.endDate}
                                                    dateChange={this.handleEndDate}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </StyledPopover>
                        </Box>
                    </Box>
                </Typography>
            </ClickAwayListener>
        );
    }

    requestToggleModal = () => {
        const { classes }: any = this.props
        return (
            <ClickAwayListener onClickAway={this.handleToggleRequestModalClose}>
                <Typography>
                    <Box style={webStyle.toggleJobModalContainer}>
                        <Box style={webStyle.toggleModalStyleJob}>
                            {this.state.jobsToggleData.map((item: ToggleData) => (
                                <Box style={webStyle.toggleIconsContainerJob} key={item.id}>
                                    <StyledCheckbox
                                        data-test-id="handleToggleColumnJob"
                                        data-testid="handleToggleColumnJob"
                                        color="primary"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                        checked={item.hidden}
                                        onChange={() => this.handleToggleColumnRequest(item.id)}
                                    />
                                    <MenuItem
                                        className={classes.tableText}
                                        value={item.title}
                                        disableRipple
                                    >
                                        {item.title}
                                    </MenuItem>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Typography>
            </ClickAwayListener>
        );
    }
    renderHandleViewJob = (item: any) => this.handleViewJob(item.id);

    jobsViewData = ({ key, classes, item }: any) => {
        const open = Boolean(this.state.teamMemberModal);
        const newId = open ? 'simple-popover' : undefined;
        return <>
            {this.state.jobsToggleData[0]?.hidden &&
                (<Grid item
                    className={classes.jobCellLayout}
                    style={{ width: `${(100 / this.state.jobsToggleData.filter((item: any) => item.hidden).length)}%`, minWidth: "185px" }}
                    onClick={this.renderHandleViewJob.bind(this, item)}
                    data-test-id="viewData"
                >
                    <Typography variant="body1" className={classes.tableText} >

                        {item.attributes?.job_title}
                    </Typography>
                </Grid>
                )}
            {this.state.jobsToggleData[1]?.hidden &&
                (<Grid item
                    className={classes.jobCellLayout}
                    style={{ width: `${(100 / this.state.jobsToggleData.filter((item: any) => item.hidden).length)}%`, minWidth: "185px" }}
                    onClick={this.renderHandleViewJob.bind(this, item)}
                    data-test-id="viewData1"
                >
                    <Typography variant="body1" className={classes.tableText} >

                        {item.attributes?.custmoer_name}
                    </Typography>
                </Grid>
                )}
            {this.state.jobsToggleData[2]?.hidden &&
                (<Grid item
                    className={classes.jobCellLayout}
                    style={{
                        width: `${(100 / this.state.jobsToggleData.filter((item: ToggleData) => item.hidden).length)}%`, minWidth: "185px"
                    }}
                    onClick={this.renderHandleViewJob.bind(this, item)}
                >

                    <Typography variant="body1" className={classes.tableText} >
                    {this.convertToArabicTime(item.attributes?.start_time)} - {this.convertToArabicTime(item.attributes?.end_time)}
                    </Typography>

                </Grid>
                )}
            {this.state.jobsToggleData[3]?.hidden &&
                (<Grid item
                    className={classes.jobCellLayout}
                    style={{
                        width: `${(100 / this.state.jobsToggleData.filter((item: ToggleData) => item.hidden).length)}%`, minWidth: "185px"
                    }}
                    onClick={this.renderHandleViewJob.bind(this, item)}
                >
                    <Typography variant="body1" className={classes.tableText} >
                        {moment(item?.attributes.start_date).format("DD/MM/YYYY").toString()} - {moment(item?.attributes.end_date).format("DD/MM/YYYY").toString()}
                    </Typography>
                </Grid>
                )}
            {this.state.jobsToggleData[4]?.hidden &&
                (<Grid item
                    className={classes.jobCellLayout}
                    style={{ width: `${(100 / this.state.jobsToggleData.filter((item: any) => item.hidden).length)}%`, minWidth: "185px" }}
                    // onClick={this.renderHandleViewJob.bind(this, item)}
                    data-test-id="viewData3"
                >
                    {item.attributes.employees.length > 1 ?
                        <>
                            <Typography variant="body1" className={classes.tableText} style={{ cursor: 'pointer' }} onClick={this.handleTeamModalOpen}>
                                {item.attributes.employees[0]?.name}...
                            </Typography>
                            <Popover
                                id={newId}
                                anchorEl={this.state.teamMemberModal}
                                open={open}
                                onClose={this.handleTeamModalClose}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'center',
                                    horizontal: 'center',
                                }}
                            >
                                <List>
                                    {item.attributes.employees.map((item: any) => (
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar src={item.img} />
                                            </ListItemAvatar>
                                            <ListItemText primary={item.name} secondary={item.mail} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Popover>
                        </>
                        :
                        <Typography variant="body1" className={classes.tableText}>
                            {item.attributes.employees[0]?.name}
                        </Typography>}
                </Grid>
                )}
            {this.state.jobsToggleData[5]?.hidden &&
                (<Grid item
                    className={classes.jobCellLayout}
                    style={{
                        width: `${(100 / this.state.jobsToggleData.filter((item: ToggleData) => item.hidden).length)}%`, minWidth: "185px"
                    }}
                    onClick={this.renderHandleViewJob.bind(this, item)}
                    data-test-id="viewData4"
                >
                    <Typography variant="body1" className={classes.tableText} >
                        {(item.product?.attributes?.product_id && item.product?.attributes?.product_name) ?? '-'}
                    </Typography>
                </Grid>
                )}
            {this.state.jobsToggleData[6]?.hidden &&
                (<Grid item
                    className={classes.jobCellLayout}
                    style={{
                        width: `${(100 / this.state.jobsToggleData.filter((item: ToggleData) => item.hidden).length)}%`, minWidth: "185px"
                    }}
                    onClick={this.renderHandleViewJob.bind(this, item)}
                    data-test-id="viewData5"
                >
                    <Typography variant="body1" className={classes.tableText} >
                        {(item.product?.attributes?.service_id && item.product?.attributes?.service_name) ?? '-'}

                    </Typography>
                </Grid>
                )}
            {this.state.jobsToggleData[7]&&this.state.jobsToggleData[7].hidden &&
                (<Grid item
                    className={classes.jobCellLayout}
                    style={{
                        width: `${(100 / this.state.jobsToggleData.filter((item: ToggleData) => item.hidden).length)}%`, 
                        minWidth: "185px"
                    }}
                    onClick={this.renderHandleViewJob.bind(this, item)}
                    data-test-id="viewData6"
                >
                    <Typography variant="body1" 
                        className={classes.tableText}
                    >
                        {item?.attributes?.total}
                    </Typography>
                </Grid>
            )}    
            {(key === "cancelled" && item.id === this.state.hoverJobRow) &&
                <Grid item
                    style={{ zIndex: 1, position: 'absolute', width: '80px', height: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center' }}
                    onClick={() => this.handleDeleteDailog(item.id)}
                    className={classes.tableCellBodyDeleteIcon}
                >
                    <img src={deleteIcon} style={webStyle.cancelledIcon} />
                </Grid>
            }

        </>
    }

    jobsTableRow = (mockJobsTableRow: JobsTableRow[]) => {
        const { classes } = this.props;
        return <>
            {mockJobsTableRow.map((item: any, index: any) => (
                <>
                    {
                        this.state.jobsToggleData[index]?.hidden && (
                            <Grid item
                                className={classes.headLayout}
                                key={item.id}
                                onClick={() => this.handleSortJob(item.key)}
                                data-test-id="handleSort"
                                style={this.state.sortColumn === item.key
                                    ? { backgroundColor: "rgba(232,232,232,0.4)", width: `${(100 / this.state.jobsToggleData.filter((item: any) => item.hidden).length)}%`, minWidth: "185px" }
                                    : { width: `${(100 / this.state.jobsToggleData.filter((item: ToggleData) => item.hidden).length)}%`, minWidth: "185px" }}
                            >
                                <span
                                    style={webStyle.jobTableHeadSpan}
                                >
                                    <Typography variant="body1" align="left" data-testid="job_title"
                                        className={classes.tableText}
                                        style={{ whiteSpace: 'nowrap' }}
                                    >
                                        {item.title}
                                    </Typography>
                                    <img
                                        style={(this.state.sortColumn === item.key) && this.state.sortDirection === 'asc' ? { ...webStyle.dropDownSelectIconTable, transform: 'rotate(180deg)' } : webStyle.dropDownSelectIconTable}
                                        src={dropDownSelectIcon}
                                        className={classes.dropDownSelectIcon}
                                        alt=""
                                    />
                                </span>
                            </Grid>
                        )
                    }
                </>
            ))}
        </>
    }
    jobHeadings = (keyValue: string, classes: ClassNameMap<string>, jobStatusData: JobsStatusData[]) => {
        const {textColor, dividerColor} = this.getJobHeadingStyle(keyValue)

        return (
            <>
                <Grid container>
                    <Typography variant="body2" className={keyValue === "upcoming" ?
                        classes.statusKeysTextPending : this.findClassesJobs(keyValue, classes)} style={{color: textColor}}>
                        {keyValue === 'in_progress' && `${this.props.t('jobs.statusMenu.inProgress')}` || keyValue === 'late' && `${this.props.t('jobs.statusMenu.overdue')}` || keyValue === "to_be_invoiced" && `${this.props.t('jobs.statusMenu.invoiced')}` ||
                            jobStatusData?.find((item: any) => item.statusName === keyValue)?.title
                        }
                    </Typography>
                </Grid>
                <Divider style={keyValue === "upcoming" ? {...webStyle.dividerColorPending, backgroundColor: dividerColor } :
                    {...this.findDividerColorJobs(keyValue), backgroundColor: dividerColor }} />
            </>
        )


    }

    findClassesJobs = (key: string, classes: any) => {
        return key === "in_progress" ?
            classes.statusKeysText : this.findJobsClasses(key, classes)
    }

    findJobsClasses = (key: string, classes: any) => {
        return key === "completed"
            ? classes.statusKeysTextQuoted : this.findJobsClassesv1(key, classes)

    }
    findJobsClassesv1 = (key: string, classes: any) => {
        return key === "to_be_invoiced" ? classes.statusKeysTextConverted : this.findJobsClassesv2(key, classes)
    }

    findJobsClassesv2 = (key: string, classes: any) => {
        return key === "late" ? classes.statusKeysTextOverdue : classes.statusKeysTextCancelled
    }

    findDividerColorJobs = (key: string) => {
        return key === "in_progress" ?
            webStyle.dividerLine : this.findDividerColorJobsV1(key)
    }
    findDividerColorJobsV1 = (key: string) => {
        return key === "completed"
            ? webStyle.dividerColorQuoted : this.findDividerColorJobsV2(key)
    }

    findDividerColorJobsV2 = (key: string) => {
        return key === "to_be_invoiced"
            ? webStyle.dividerColorConverted : this.findDividerColorJobsV3(key)
    }

    findDividerColorJobsV3 = (key: string) => {
        return key === "late"
            ? webStyle.dividerColorOverdue : webStyle.dividerColorCancelled
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, t } = this.props;
        const jobStatusData = [
            { id: 1, title: `${t('request.statusMenu.all')}`, statusName: "all" },
            { id: 2, title: `${t('jobs.statusMenu.upcoming')}`, statusName: "upcoming" },
            { id: 3, title: `${t('jobs.statusMenu.inProgress')}`, statusName: "in_progress" },
            { id: 4, title: `${t('jobs.statusMenu.invoiced')}`, statusName: "invoiced" },
            { id: 5, title: `${t('jobs.statusMenu.completed')}`, statusName: "completed" },
            { id: 6, title: `${t('jobs.statusMenu.late')}`, statusName: "late" },
            { id: 7, title: `${t('request.statusMenu.cancelled')}`, statusName: "cancelled" },
        ]
        const mockJobsTableRow = [
            { id: 1, key: "job_title", title: `${t('jobs.tableRow.title')}` },
            { id: 2, key: "customer_name", title: `${t('invoice.menuLabel.cusName')}` },
            { id: 3, key: "start/end_date", title: `${t('jobs.tableRow.startEndDate')}` },
            { id: 4, key: "start/end_time", title: `${t('jobs.tableRow.startEndTime')}` },
            { id: 5, key: "team_members", title: `${t('Calendar.label.teamMembers')}` },
            { id: 6, key: "product_name", title: `${t('invoice.menuLabel.productName')}` },
            { id: 7, key: "service_name", title: `${t('invoice.menuLabel.serviceName')}` },
            { id: 8, key: "total_price", title: `${t('invoice.menuLabel.totalPrice')}` },
        ]
        const dateMenuItem = [
            { id: 1, title: `${t('dashboard.today')}` },
            { id: 2, title: `${t('dashboard.thisWeek')}` },
            { id: 3, title: `${t('dashboard.thisMonth')}` }
        ]
        return (
            <>
                <DashboardSideBar permissions={undefined} oldProps={undefined} {...this.props} navMenuItem={this.props.t('dashboardNavbar.jobs')} active={5} active1={false} active2={false} />
                <StyledRoot languageSelected={this.state.selectedLanguage}>
                    <Box
                        sx={{
                            marginTop: "9rem",
                            paddingBottom: "5rem"
                        }}
                        className={classes.boxJobContainer}
                    >
                        <Container maxWidth={false}>
                            <Grid container spacing={2}>
                                <Grid item md={5} xs={12}>
                                    <Box style={webStyle.backIconContainer}>
                                        <Hidden smUp>
                                            <img src={backIconMobile}
                                                width={12} height={24}
                                                data-test-id="jobs_back_icon"
                                                onClick={() => goBack(this.props.navigation)}
                                                alt="" />
                                        </Hidden>
                                        <Box style={webStyle.jobSearchContainer}>
                                            <Box>
                                                <img style={webStyle.jobSearchIcon} src={searchIcon} alt="" />
                                            </Box>

                                            <InputBase
                                                data-test-id="searchJob"
                                                style={webStyle.jobInputBase}
                                                placeholder={`${t('jobs.placeholder.searchJob')}`}
                                                inputProps={{ 'aria-label': 'search' }}
                                                value={this.state.searchJobs}
                                                onChange={this.handleChangeSearchJobs}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item md={2}  xs={3}>
                                    <CustomDatePickers
                                        type='Job'
                                        selectedDate={this.state.selectedDate}
                                        dateModal={this.state.dateModal}
                                        status={this.state.dateStatus}
                                        languageSelected={this.state.selectedLanguage}
                                        datePickerRef={this.datePickerRef}
                                        handleSelectStatus={this.handleSelectDateStatus}
                                        handleDateChange={this.handleDateChange}
                                        handleStatusModalClose={this.handleStatusModalClose}
                                        handleDateModalOpen={this.handleDateModalOpen}
                                    />
                                </Grid>
                                <Grid item md={2} xs={4}>
                                    <StyledTextField
                                        data-test-id="dateModalOpen"
                                        fullWidth
                                        className={this.state.selectedLanguage === 'ar' ? 'language-ar' : ''}
                                        defaultValue={`${t('invoice.placeholder.status')}`}
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <img style={!this.state.statusModal ? webStyle.dropDownSelectIcon : { ...webStyle.dropDownSelectIcon, transform: "rotate(180deg)" }} src={dropDownSelectIcon} alt="" />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onClick={this.handleStatusJobModalOpen}
                                    >
                                    </StyledTextField>
                                    {this.state.statusModal ? (
                                        this.statusModalJob()
                                    ) : null}
                                </Grid>
                                <Grid item md={3} xs={5}>
                                    <StyledTextField
                                        fullWidth
                                        defaultValue={`${t('invoice.placeholder.toggleColumns')}`}
                                        variant="outlined"
                                        className={this.state.selectedLanguage === 'ar' ? 'language-ar' : ''}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <img style={!this.state.toggleModal ? webStyle.dropDownSelectIcon : { ...webStyle.dropDownSelectIcon, transform: "rotate(180deg)" }} src={dropDownSelectIcon} alt="" />
                                                </InputAdornment>
                                            ),
                                        }}
                                        data-test-id="toggleOpne"
                                        onClick={this.handleToggleJobModalOpen}
                                    >
                                    </StyledTextField>
                                    {this.state.toggleModal ? (
                                        this.requestToggleModal()
                                    ) : null}
                                </Grid>
                            </Grid>

                            <StyledBox container>
                                <Grid item xs={12} style={{ overflow: 'scroll', minWidth: "fit-content" }}>
                                    <Grid container
                                        style={{
                                            display: 'flex',
                                            width: '100%',
                                            flexWrap: 'nowrap',
                                            gridAutoFlow: 'column',
                                        }}
                                    >
                                        {this.jobsTableRow(mockJobsTableRow)}
                                    </Grid>
                                    <Divider className={classes.marginTop_1} />
                                    {Object.keys(this.state.allJobs)?.map((key: string) => {
                                        return (
                                            <>
                                                {this.jobHeadings(key, classes, jobStatusData)}

                                                {this.state.allJobs[key]?.data?.length === 0 &&
                                                    <Grid container justifyContent="center" alignItems="center"
                                                        style={{ height: '150px' }}
                                                    >
                                                        < Grid item xs={12}>
                                                            <Typography variant="subtitle1" className={classes.emptyData} align="center">
                                                                {t('ProductService.noDataFound')}.
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                }
                                                {this.state.allJobs[key]?.data?.length !== 0 &&
                                                    <Grid container style={{
                                                        maxHeight: '300px', overflow: 'scroll', minWidth: "fit-content"
                                                    }}>
                                                        <Grid item xs={12}>
                                                            {this.state.allJobs[key]?.data?.map((item: any) => (
                                                                <>
                                                                    <StyledGrided container
                                                                        style={{
                                                                            display: 'flex',
                                                                            width: '100%',
                                                                            flexWrap: 'nowrap',
                                                                            gridAutoFlow: 'column',
                                                                            zIndex: 0,
                                                                            position: 'relative',
                                                                            justifyContent: 'end'
                                                                        }}
                                                                        data-test-id="moveHover"
                                                                        key={item.id}
                                                                        onMouseEnter={() => {
                                                                            this.setState({ hoverJobRow: item.id });
                                                                        }}
                                                                        onMouseLeave={() => this.setState({ hoverJobRow: -1 })}
                                                                    >
                                                                        {this.jobsViewData({ key, item, classes })}
                                                                    </StyledGrided>
                                                                    <Divider />
                                                                </>
                                                            ))}
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </>
                                        )
                                    })}
                                </Grid>
                            </StyledBox>
                            <Grid item xs={12}>
                                <Grid container direction="row" justifyContent="flex-end">
                                    <CreateQuote
                                        data-test-id="create_job"
                                        data-testid="create_job"
                                        onClick={this.handleCreateJob}
                                        className={classes.createQuoteBtn}
                                    >
                                        <Typography variant="subtitle2" className={classes.buttonTextCreateQuote}>{t('jobs.button.createJob')}</Typography>
                                    </CreateQuote>

                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </StyledRoot>

                {this.state.hardDeleteStatus &&

                    <DeleteModel
                        deleteType={t('jobs.hardDeleteJob')}
                        subType={t('jobs.hardDeleteJobDes')}
                        open={this.state.hardDeleteStatus}
                        onClose={this.handleDeleteCloseDailog}
                        handleDelete={this.deleteJob}
                        t={t}
                    />}
                <LoadingBackdrop
                    loading={this.state.getJobsLoading}
                />
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    jobSearchIcon: {
        height: "20px",
        width: "20px",
        margin: "0 0.6rem 0 1.3rem",
        marginTop:"4px"
    },
    backIconContainer: {
        display: "flex",
        alignItems: "center",
        gap: "1rem"
    },
    jobSearchContainer: {
        display: "flex",
        alignItems: "center",
        minHeight: "56px",
        width: "100%",
        borderRadius: "8px",
        border: "1.5px solid rgba(0,0,0,0.1)",
    },
    dropDownSelectIcon: {
        width: "13px",
        height: "9px",
        cursor: "pointer"
    },
    jobInputBase: {
        width: "100%",
        fontSize: "18px",
        fontWeight: CONSTANTS.fontWeight.fontWeight400,
        color: CONSTANTS.color.grey,
        lineHeight: "16px",
    },
    dropDownSelectIconTable: {
        width: "13px",
        height: "9px",
        cursor: "pointer",
        marginLeft: "2rem",
        transform: "rotate(0deg)"
    },
    statusModalContainerJob: {
        position: "absolute" as const,
        zIndex: 9,
        width: "182px",
        minHeight: "182px",
        borderRadius: "5px",
        backgroundColor: CONSTANTS.color.white,
        boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    },
    statusModalJob: {
        padding: "1.5rem 0 0 0"
    },
    toggleJobModalContainer: {
        position: "absolute" as const,
        padding: "1rem",
        zIndex: 9,
        width: "254px",
        minHeight: "194px",
        borderRadius: "5px",
        backgroundColor: CONSTANTS.color.white,
        boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    },
    toggleModalStyleJob: {
        display: "flex",
        flexDirection: "column" as const,
        justifyContent: "center",
    },
    toggleIconsContainerJob: {
        display: "flex",
        alignItems: "center"
    },
    toggleIconsJob: {
        width: "24px",
        height: "24px"
    },
    containeJob: {
        marginTop: "2rem",
        maxWidth: "100%",
        backgroundColor: CONSTANTS.color.white,
        boxShadow: "0px 16px 17px rgba(28,24,175,0.07)",
        minHeight: "697px"
    },
    jobTableHeadSpan: {
        cursor: "pointer",
        borderRadius: "2px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    cancelledIcon: {
        width: "30px",
        height: "30px"
    },
    invoiceContainer: {
        marginTop: "2rem",
        maxWidth: "100%",
        backgroundColor: CONSTANTS.color.white,
        boxShadow: "0px 16px 17px rgba(28,24,175,0.07)",
        minHeight: "300px",
        overflow: 'scroll',
    },
    dividerLineCancelled: {
        marginTop: "8px",
        backgroundColor: "#8C8C8C"
    },
    dividerLine: {
        marginTop: "8px",
        backgroundColor: CONSTANTS.color.darkBlue
    },
    dividerColorPending: {
        marginTop: "8px",
        backgroundColor: "#6764d6"
    },
    dividerColorConverted: {
        marginTop: "8px",
        backgroundColor: "#74ab39"
    },
    dividerColorQuoted: {
        marginTop: "8px",
        backgroundColor: "#fa6400"
    },
    dividerColorOverdue: {
        marginTop: "8px",
        backgroundColor: "#a41c1c",
    },
    dividerColorCancelled: {
        marginTop: "8px",
        backgroundColor: "#8C8C8C"
    },

}
const StyledGrided = withStyles({
    root: {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: `${CONSTANTS.color.blueViolet} !important`,
        "& .MuiTypography-root": {
          color: `${CONSTANTS.color.white} !important`,
        },
  
      },
    }
  })(Grid);
const StyledBox = styled(Grid)(() => ({
    marginTop: "2rem",
    maxWidth: "100%",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 16px 17px rgba(28,24,175,0.07)",
    minHeight: "300px",
    overflow: 'scroll',
    '&::-webkit-scrollbar-thumb': {
        backgroundColor:"#1c18af"
    }
  }));
const RequestRouter = withStyles(styles)(Jobs);
const RequestSnackBar = withSnackbar(RequestRouter);
export default withTranslation()(withPermission(RequestSnackBar));
// Customizable Area End