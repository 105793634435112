import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  Container,
  Typography,
  InputBase,
  MenuItem,
  InputAdornment,
  ClickAwayListener,
  Divider, 
  Hidden,
  Checkbox,
  Popover 
} from "@material-ui/core";
import {
  withStyles, Theme, createStyles,styled
} from "@material-ui/core/styles";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { StyledRoot } from "../../dashboard/src/utility.web";
import {
  dropDownSelectIcon
} from "../../RequestManagement/src/assets";
import { backIconMobile } from '../../CustomisableUserProfiles/src/assets';
import { withRouter } from "react-router-dom";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import CustomDatePicker from "../../../components/src/CustomDatePicker";
import { withSnackbar } from "../../../components/src/toastweb";
// Customizable Area End
import { StyledCheckbox } from "../../Settings/src/CompanyInformation.web";
import { deleteIcon, searchIcon } from "../../CustomisableUserProfiles/src/assets";
import DeleteModel from "../../RequestManagement/src/DeleteModal.web";
import QuotesController, { Props, ToggleDetails ,ProductItem} from "./QuotesController.web";
import moment from "moment"; 
import { CONSTANTS} from "../../../components/src/theme";
import { CreateQuote } from "../../../components/src/CustomizableComponents.web";
import { withTranslation } from "react-i18next";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { StyledTextFieldRequest } from "../../InvoiceBilling/src/Invoices.web"
interface ToggleData{
  id:number,
  title:string,
  hidden:boolean
}
// Customizable Area Start
// Customizable Area End

export class Quotes extends QuotesController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  statusModalRequests = (quoteStatusData: { id: number; title: string; statusName: string; }[]) => {
    const { classes } = this.props
    return (
      <ClickAwayListener onClickAway={this.handleStatusRequestModalClose}>
        <Typography component={'div'}>
          <Box style={webStyle.statusModalContainerRequest}>
            <Box style={webStyle.statusModalRequest}>
              {quoteStatusData.map((item) => (

                <Box key={item.id} display={"flex"} mb={1} gridGap={"0.5rem"}>
                  <Checkbox
                    data-tes-id="handleSelectStatus"
                    checked={this.state.checkedItems.some((subItem) => item.statusName === subItem)}
                    onChange={this.handleSelectStatus.bind(this,item,quoteStatusData)}
                    style={{ padding: 0, color: "#1c18af" }} />
                  <Typography className={classes.menuItemStyle} variant="body1">
                    {item.title}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Typography>
      </ClickAwayListener>
    );
  }

  modalToggleQuotes = () => {
    const { classes } = this.props

    return (
      <ClickAwayListener onClickAway={this.handleToggleRequestModalClose}>
        <Typography>
          <Box style={webStyle.toggleRequestModalContainer} className={classes.toggleQuoteModalContainer}>
            <Box style={webStyle.toggleModalRequestStyle}>
              {this.state.quoteToggleData.map((item: ToggleDetails) => (
                <Box style={webStyle.requestToggleIconsContainer} key={item.id}>
                  <StyledCheckbox
                    data-test-id="handleToggleColumnQuote"
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    checked={item.hidden}
                    onChange={() => this.handleToggleColumnRequest(item.id)}
                  />
                  <MenuItem
                    className={classes.toggleText}
                    value={item.title}
                    disableRipple
                  >
                    {item.title}
                  </MenuItem>
                </Box>
              ))}
            </Box>
          </Box>
        </Typography>
      </ClickAwayListener>
    );
  }
  quoteViewData = ({ key, classes, item }: any) => {
    return <>

      {this.state.quoteToggleData[1]?.hidden &&
        (<Grid item data-test-id="handleViewQuote"
          className={classes.jobCellLayout}
          style={{ width: `${(100 / this.state.quoteToggleData.filter((item:ToggleData) => item.hidden).length)}%`, minWidth: "220px" }}
          onClick={() => this.handleViewQuoteData(item)}
        >
          <Typography variant="body1" className={classes.tableText} >
            {item.attributes?.custmer_name}</Typography>
        </Grid>
        )}
      {this.state.quoteToggleData[2]?.hidden &&
        (<Grid item data-test-id="handleViewQuote"
          className={classes.jobCellLayout}
          style={{ width: `${(100 / this.state.quoteToggleData.filter((item:ToggleData) => item.hidden).length)}%`, minWidth: "220px" }}
          onClick={() => this.handleViewQuoteData(item)}
        >
          <Typography variant="body1" className={classes.tableText} >
            {moment(item?.attributes.due_date).format("DD/MM/YYYY").toString() ?? "-"}</Typography>
        </Grid>
        )}
      {this.state.quoteToggleData[3]?.hidden &&
        (<Grid item data-test-id="handleViewQuote"
          className={classes.jobCellLayout}
          style={{ width: `${(100 / this.state.quoteToggleData.filter((item:ToggleData) => item.hidden).length)}%`, minWidth: "220px" }}
        onClick={() => this.handleViewQuoteData(item)}
        >
         
        <Typography
          data-test-id="showProducts"
          onClick={this.handleTooltipOpen.bind(this, item.attributes.products.data)}
          onMouseEnter={this.handleTooltipOpen.bind(this, item.attributes.products.data)} variant="body1" className={classes.tableText} >
          {this.filterItems(item.attributes.products.data,"product_id")}
        </Typography>
      
        </Grid>
        )}
      {this.state.quoteToggleData[4]?.hidden &&
        (<Grid item data-test-id="handleViewQuote"
          className={classes.jobCellLayout}
          style={{ width: `${(100 / this.state.quoteToggleData.filter((item:ToggleData) => item.hidden).length)}%`, minWidth: "220px" }}
        onClick={() => this.handleViewQuoteData(item)}
      >
        <Typography
          data-test-id="showServices"
          onClick={this.handleTooltipOpenService.bind(this, item.attributes.products.data)}
          onMouseEnter={this.handleTooltipOpenService.bind(this, item.attributes.products.data)} variant="body1" className={classes.tableText} >
          {this.filterItems(item.attributes.products.data, "service_id")}</Typography>

      </Grid>
        )}
         {this.state.quoteToggleData[5]?.hidden &&
        (<Grid item data-test-id="handleViewQuote"
          className={classes.jobCellLayout}
          style={{ width: `${(100 / this.state.quoteToggleData.filter((item:ToggleData) => item.hidden).length)}%`, minWidth: "220px" }}
          onClick={() => this.handleViewQuoteData(item)}
        >
          <Typography variant="body1" className={classes.tableText} >{moment(item?.attributes.quote_date).format("DD/MM/YYYY").toString() ?? "-"}</Typography>
        </Grid>
        )}
      {this.state.quoteToggleData[6]?.hidden &&
        (<Grid item data-test-id="handleViewQuote4"
          className={classes.jobCellLayout}
          style={{ width: `${(100 / this.state.quoteToggleData.filter((item:ToggleData) => item.hidden).length)}%`, minWidth: "220px" }}
          onClick={() => this.handleViewQuoteData(item)}
        >
          <Typography variant="body1" className={classes.tableText} >{item?.attributes.total ?? '-'}</Typography>
        </Grid>
        )}
      {(key === "cancelled" && item.id === this.state.hoverRow) &&
        <Grid item
          data-test-id="openpopup"
          style={{ zIndex: 1, position: 'absolute', width: '80px', height: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center' }}
          onClick={() => this.handleDeleteDailog(item?.id)}
          className={classes.tableCellBodyDeleteIcon}
        >
          <img src={deleteIcon} style={webStyle.deleteIcon} />
        </Grid>
      }

    </>
  }

  invoiceTableRow = (classes: ClassNameMap<string>, quoteTableRow:{ id: number; title: string; key: string; }[] ) => {
    return <>
      {quoteTableRow.map((item: any, index: any) => (
        <>
          {
            this.state.quoteToggleData[index]?.hidden && (
              <Grid item
                className={classes.headLayout}
                key={item.id}
                onClick={() => this.handleSortRequest(item.key)}
                data-test-id="handleSort"
                style={this.state.sortColumn === item.key ? 
                  { 
                    backgroundColor: "rgba(232,232,232,0.4)", 
                    width: `${(100 / this.state.quoteToggleData.filter((item:ToggleData) => item.hidden).length)}%`, 
                    minWidth: "220px" 
                  }
                  : { 
                    width: `${(100 / this.state.quoteToggleData.filter((item:ToggleData) => item.hidden).length)}%`, 
                    minWidth: "220px" 
                  }
                }
              >
                <span
                  style={webStyle.tableHeadSpan}
                >
                  <Typography variant="body1" align="left"
                    className={classes.tableText}
                  >
                    {item.title}
                  </Typography>
                  <img
                    style={(this.state.sortColumn === item.key) && this.state.sortDirection === 'asc' ? { ...webStyle.dropDownSelectIconTable, transform: 'rotate(180deg)' } : webStyle.dropDownSelectIconTable}
                    src={dropDownSelectIcon}
                    className={classes.dropDownSelectIcon}
                    alt=""
                  />
                </span>
              </Grid>
            )
          }
        </>
      ))}
    </>
  }
  requestQuoteHeadings = (key: string, classes: any,quoteStatusData:{ id: number; title: string; statusName: string; }[]) => {
    if (key === "pending") {
      return (
        <>
          <Grid container>
            <Typography variant="body2" className={classes.statusKeysTextPending}>
              {this.props.t('quote.pending')}</Typography>
          </Grid>
          <Divider style={webStyle.dividerLineColorPending} />
        </>
      )

    } else {
      return (
        <>
          <Grid container>
            <Typography variant="body2"

              className=
              {key === "converted" ? classes.statusKeysTextConverted : this.findClassesQuotes(key, classes)}>
              {quoteStatusData?.find((item) => item.statusName === key)?.title}
            </Typography>
          </Grid>
          <Divider style={key === "converted" ? webStyle.dividerLineColorConverted : this.findDividerColorQuotes(key)} />
        </>
      )
    }
  }
  findDividerColorQuotes = (key: string) => {
    return key === "sent" ?
      webStyle.dividerLineColorQuoted : this.findQuotesColor(key)
  }
  findQuotesColor = (key: string) => {
    return key === "overdue"
      ? webStyle.dividerLineColorOverdue : this.findQuotesColorv1(key)
  }
  findQuotesColorv1 = (key: string) => {
    return key === "cancelled" ? webStyle.dividerLineColorCancelled : webStyle.dividerColor
  }
  findClassesQuotes = (key: string, classes: any) => {
    return key === "sent" ?
      classes.statusKeysTextQuoted : this.findQuotesClasses(key, classes)
  }

  findQuotesClasses = (key: string, classes: any) => {
    return key === "overdue"
      ? classes.statusKeysTextOverdue : this.findQuotesClassesv1(key, classes)

  }
  findQuotesClassesv1 = (key: string, classes: any) => {
    return key === "cancelled" ? classes.statusKeysTextCancelled : classes.statusKeysText
  }
  renderProducts = (item: ProductItem) => {
    return (
      <Box display={"flex"} >
        <Typography style={{ textTransform: "capitalize",color:"#666666" }} variant="body2">
          {`${item.attributes.product_name}`}
        </Typography>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes,t } = this.props;
    const quoteTableRow = [
      { id: 1, key: "quote_title", title: `${t('quote.title')}`},
      { id: 2, key: "customer_name", title: `${t('invoice.menuLabel.cusName')}`},
      { id: 3, key: "due_date", title: `${t('quote.expirationDate')}`},
      { id: 4, key: "product_name", title: `${t('quote.productName')}`},
      { id: 5, key: "service_name", title: `${t('quote.serviceName')}`},
      { id: 6, key: "quote_date", title: `${t('quote.quoteDate')}`},
      { id: 7, key: "total_quote_amount", title: `${t('quote.amount')}`}
    ]
    const quoteStatusData = [
      { id: 1, title: `${t('request.statusMenu.all')}`, statusName: "all" },
      { id: 2, title: `${t('quote.pending')}`, statusName: "pending" },
      { id: 3, title:`${t('quote.sent')}`, statusName: "sent" },
      { id: 4, title: `${t('quote.converted')}`, statusName: "converted" },
      { id: 5, title: `${t('quote.overdue')}`, statusName: "overdue" },
      { id: 6, title: `${t('request.statusMenu.cancelled')}`, statusName: "cancelled" },
    ]
    return (
      <>
       
        <Popover
          id={this.state.anchorId}
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
         
          onClose={this.handleTooltipClose}

          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <>
          
            <Box className={classes.productPopover} p={1} >
            <Typography variant="body1" className={classes.popoverHeading}>{this.state.popoverHeading}</Typography>
              {this.state.productsData.map(this.renderProducts)}

            </Box>
           

          </>

        </Popover>
        <DashboardSideBar permissions={undefined} oldProps={undefined} {...this.props} navMenuItem={this.props.t('dashboardNavbar.quotes')} active={4} active1={false} active2={false} />
        <StyledRoot languageSelected={this.state.selectLanguage}>
          <Box
            sx={{
              marginTop: "9rem",
              paddingBottom: "5rem"
            }}
            className={classes.boxRequestContainer}
          >
            <Container maxWidth={false}>
              <Grid container spacing={1} className={classes.mt_1}>
                <Grid item md={5} xs={12}>
                  <Box style={webStyle.backIconContainer}>
                    <Hidden smUp>
                      <img style={webStyle.backIcon} src={backIconMobile} onClick={this.handleBack} alt="" />
                    </Hidden>
                    <Box style={webStyle.searchContainer}>
                      <Box>
                        <img style={webStyle.searchIconRequest} src={searchIcon} alt="" />
                      </Box>
                    <InputBase
                      data-test-id="searchQuote"
                      placeholder={`${t('quote.placeHolder')}`}
                      style={webStyle.searchInput}
                      className={classes.searchInput}
                      inputProps={{ 'aria-label': 'search' }}
                      value={this.state.searchQuote}
                      onChange={this.handleChangeSearchRequest}
                    />
                  </Box>
                  </Box>
                </Grid>
                <Grid item md={2}  xs={3}>
                  <CustomDatePicker
                    type='Quote'
                    selectedDate={this.state.selectedDate}
                    status={this.state.dateStatus}
                    dateModal={this.state.dateModal}
                    datePickerRef={this.datePickerRef}
                    languageSelected={this.state.selectLanguage}
                    handleDateChange={this.handleDateChange}
                    handleSelectStatus={this.handleSelectDateStatus}
                    handleDateModalOpen={this.handleDateModalOpen}
                    handleStatusModalClose={this.handleStatusModalClose}
                  />
                </Grid>
                <Grid item md={2} xs={4}>
                  <StyledTextFieldRequest
                    data-test-id="statsuQuoteOpen"
                    fullWidth
                    defaultValue={`${t('invoice.placeholder.status')}`}
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <img style={!this.state.statusModal ? webStyle.dropDownSelectIcon : { ...webStyle.dropDownSelectIcon, transform: "rotate(180deg)" }} src={dropDownSelectIcon} alt="" />
                        </InputAdornment>
                      ),
                    }}
                    onClick={this.handleStatusRequestModalOpen}
                  >
                  </StyledTextFieldRequest>
                  {this.state.statusModal ? (
                    this.statusModalRequests(quoteStatusData)
                  ) : null}
                </Grid>
                <Grid item md={3} xs={5}>
                  <StyledTextFieldRequest
                    data-test-id='toggleQuoteOpen'
                    fullWidth
                    defaultValue={`${t('invoice.placeholder.toggleColumns')}`}
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <img style={!this.state.toggleModal ? webStyle.dropDownSelectIcon : { ...webStyle.dropDownSelectIcon, transform: "rotate(180deg)" }} src={dropDownSelectIcon} alt="" />
                        </InputAdornment>
                      ),
                    }}
                    onClick={this.handleToggleRequestModalOpen}
                  >
                  </StyledTextFieldRequest>
                  {this.state.toggleModal ? (
                    this.modalToggleQuotes()
                  ) : null}
                </Grid>
              </Grid>
              <StyledBox container>
                <Grid item xs={12} style={{ overflow: 'scroll', minWidth: "fit-content" }}>
                  <Grid container
                    style={{
                      display: 'flex',
                      width: '100%',
                      flexWrap: 'nowrap',
                      gridAutoFlow: 'column',
          
                    }}
                  >
                    {this.invoiceTableRow(classes,quoteTableRow)}
                  </Grid>
                  <Divider className={classes.headLayoutMargin} />
                  {Object.keys(this.state.allQuotesList)?.map((key: string) => {
                    return (
                      <>
                        {this.requestQuoteHeadings(key, classes,quoteStatusData)}

                        {this.state.allQuotesList[key]?.data?.length === 0 &&
                          <Grid container justifyContent="center" alignItems="center"
                            style={{ height: '150px' }}
                          >
                            <Grid item xs={12}>
                              <Typography variant="subtitle1" className={classes.emptyData} align="center">
                               {t('ProductService.noDataFound')}.
                              </Typography>
                            </Grid>
                          </Grid>
                        }
                        {this.state.allQuotesList[key]?.data?.length !== 0 &&
                          <Grid container style={{
                            maxHeight: '300px', overflow: 'scroll', minWidth: "fit-content"
                          }}>
                            <Grid item xs={12}>
                              {this.state.allQuotesList[key]?.data?.map((item: any) => (
                                <>
                                  <StyledGrid container
                                    style={{
                                      display: 'flex',
                                      width: '100%',
                                      flexWrap: 'nowrap',
                                      gridAutoFlow: 'column',
                                      zIndex: 0,
                                      position: 'relative',
                                      justifyContent: 'end',
                                      cursor:"pointer"
                                    }}
                                    data-test-id="deleteiconMouseHover"

                                    key={item.id}
                                    onMouseEnter={() => {
                                      this.setState({ hoverRow: item.id });
                                    }}
                                    onMouseLeave={() => this.setState({ hoverRow: -1 })}
                                  >
                                    {this.state.quoteToggleData[0]?.hidden &&
                                      (<Grid item data-test-id="handleViewQuote"
                                        className={classes.jobCellLayout}
                                        style={{ width: `${(100 / this.state.quoteToggleData.filter((item: ToggleData) => item.hidden).length)}%`, minWidth: "220px" }}
                                        onClick={() => this.handleViewQuoteData(item)}
                                      >
                                        <Typography variant="body1" className={classes.tableText}>{item.attributes?.quote_title}</Typography>
                                      </Grid>
                                      )}
                                    {this.quoteViewData({ key, classes, item })}
                                  </StyledGrid>
                                  <Divider />
                                </>
                              ))}
                            </Grid>
                          </Grid>
                        }
                      </>
                    )
                  })}
                </Grid>
              </StyledBox>
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="flex-end">
                  <CreateQuote
                    data-test-id="createQuote"
                    onClick={this.handleCreateQuote}
                    className={classes.createQuoteBtn}
                  >
                   <Typography variant="subtitle2" className={classes.buttonTextCreateQuote}>{t('quote.button.createQuote')}</Typography>
                  </CreateQuote>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </StyledRoot>
        {this.state.hardDeleteStatus &&
          <DeleteModel
            deleteType={`${t('quote.hardDeleteQuote')}`}
            subType={`${t('quote.hardDeleteQuoteDes')}`}
            open={this.state.hardDeleteStatus}
            onClose={this.handleDeleteCloseDailog}
            handleDelete={this.deleteQuote}
            t={t}
          />}
        <LoadingBackdrop
          loading={this.state.getQuotesLoading}
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) =>
  createStyles({
    productPopover:{
      backgroundColor: 'white',
      color: theme.palette.common.black,
      fontSize: 15,
      width:"191px",
      borderRadius:"5px",
      padding:"0.5rem 0.5rem 0 0.5rem",
      height:"200px",
      overflowX:"scroll",
      boxShadow:"0px 0px 20px rgba(0,0,0,0.1)"
    },
    popoverHeading:{
      color:"#999999"
    },
    boxRequestContainer: {
      [theme.breakpoints.down("md")]: {
        padding: "0 0.8rem 2rem !important",
      }
    },
    dropDownSelectIcon: {
      [theme.breakpoints.down("sm")]: {
        width: "11px !important",
        height: "7px !important",
      }
    },
    toggleQuoteModalContainer: {
      [theme.breakpoints.down("xs")]: {
        right: "30px !important",
      },
    },
    searchInput: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "18px !important"
      }
    },
    mt_1:{
      [theme.breakpoints.down("xs")]: {
          marginTop:'-1.5rem'
      },
   },
    tooltipBtn: {
      color: "white",
      textTransform: "capitalize"
    },
    tableCellBodyDeleteIcon: {
      background: "#eedee4"
    },
    textStart: {
      textAlignLast: "start",
    },
    headLayout: {
      padding: '16px'
    },
    headLayoutMargin: {
      marginTop: '0.5rem'
    },
    tableText: {
      color: CONSTANTS.color.grey,
      fontWeight: theme.typography.fontWeightRegular,
    },
    jobCellLayout: {
      padding: '28px 16px 28px 20px !important'
    },
    emptyData: {
      color: CONSTANTS.color.darkGrey,
      fontWeight: theme.typography.fontWeightMedium,
    },
    statusKeysText: {
      color: CONSTANTS.color.darkBlue,
      marginRight:16,
      fontWeight: theme.typography.fontWeightMedium,
      marginTop: 10,
      marginLeft: 10,
      textTransform: "capitalize"
    },
    statusKeysTextPending: {
      color: "#6764d6",
      marginRight:16,
      fontWeight: theme.typography.fontWeightMedium,
      marginTop: 10,
      marginLeft: 10,
      textTransform: "capitalize"
    },
    statusKeysTextConverted: {
      color: "#008000",
      fontWeight: theme.typography.fontWeightMedium,
      marginTop: 10,
      marginRight:16,
      marginLeft: 10,
      textTransform: "capitalize"
    },
    statusKeysTextQuoted: {
      marginRight:16,
      color: "#008000",
      fontWeight: theme.typography.fontWeightMedium,
      marginTop: 10,
      marginLeft: 10,
      textTransform: "capitalize"
    },
    statusKeysTextOverdue: {
      color: "#e02020",
      fontWeight: theme.typography.fontWeightMedium,
      marginTop: 10,
      marginLeft: 10,
      textTransform: "capitalize",
      marginRight:16,
    },
    statusKeysTextCancelled: {
      color: "#8C8C8C",
      marginRight:16,
      fontWeight: theme.typography.fontWeightMedium,
      marginTop: 10,
      marginLeft: 10,
      textTransform: "capitalize"
    },
    dividerColor: {
      marginTop: "8px",
      backgroundColor: CONSTANTS.color.darkBlue
    },
    createQuoteBtn: {
      marginTop: "3rem"
    },
    menuItemStyle: {
      color: CONSTANTS.color.grey,
      fontWeight: theme.typography.fontWeightRegular,
    
    },
    toggleText:{
      color: CONSTANTS.color.grey,
      fontWeight: theme.typography.fontWeightRegular,
      whiteSpace:'initial'
  },
    buttonTextCreateQuote: {
      color: CONSTANTS.color.white,
      fontWeight: CONSTANTS.fontWeight.fontWeight400,
      textAlign: "center" as const,
    },

  });
const StyledBox = styled(Grid)(() => ({
  marginTop: "2rem",
  maxWidth: "100%",
  backgroundColor: CONSTANTS.color.white,
  boxShadow: "0px 16px 17px rgba(28,24,175,0.07)",
  minHeight: "300px",
  overflow: 'scroll',
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: "#1c18af"
  }
}));
const StyledGrid = withStyles({
  root: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: `${CONSTANTS.color.blueViolet} !important`,
      "& .MuiTypography-root": {
        color: `${CONSTANTS.color.white} !important`,
      },

    },
  }
})(Grid);
const webStyle = {
  searchIconRequest: {
    height: "20px",
    width: "20px",
    margin: "0 0.6rem 0 1.3rem",
  },
  backIcon: {
    width: "12px",
    height: "24px",
  },
  backIconContainer: {
    display: "flex",
    alignItems: "center",
    gap: "1rem"
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    minHeight: "56px",
    width: "100%",
    borderRadius: "8px",
    border: "1.5px solid rgba(0,0,0,0.1)",
  },
  searchInput: {
    width: "100%",
    fontSize: "1.1667rem",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "16px",
    color: CONSTANTS.color.grey
  },
  dropDownSelectIcon: {
    width: "13px",
    height: "9px",
    cursor: "pointer"
  },
  dropDownSelectIconTable: {
    width: "13px",
    height: "9px",
    cursor: "pointer",
   
    transform: "rotate(0deg)"
  },
  statusModalContainerRequest: {
    position: "absolute" as const,
    zIndex: 9,
    width: "217px",
    height: "235px",
    borderRadius: "5px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
  },
  statusModalRequest: {
    padding: "1rem"
  },
  toggleRequestModalContainer: {
    position: "absolute" as const,
    padding: "1rem",
    zIndex: 9,
    width: "269px",
    minHeight: "194px",
    borderRadius: "5px",
    backgroundColor:CONSTANTS.color.white,
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
  },
  toggleModalRequestStyle: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
  },
  requestToggleIconsContainer: {
    display: "flex",
    alignItems: "center"
  },
  toggleIconsRequest: {
    width: "24px",
    height: "24px"
  },
  tableHeadSpan: {
    cursor: "pointer",
    borderRadius: "2px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width:"100%"
  },
  deleteIcon: {
    width: "30px",
    height: "30px"
  },
  dividerLineColorPending: {
    marginTop: "8px",
    backgroundColor: "#6764d6"
  },
  dividerLineColorConverted: {
    marginTop: "8px",
    backgroundColor: "#008000"
  },
  dividerLineColorQuoted: {
    marginTop: "8px",
    backgroundColor: "#008000"
  },
  dividerLineColorOverdue: {
    marginTop: "8px",
    backgroundColor: "#e02020"
  },
  dividerLineColorCancelled: {
    marginTop: "8px",
    backgroundColor: "#8C8C8C"
  },
  dividerColor: {
    marginTop: "8px",
    backgroundColor: CONSTANTS.color.darkBlue
  },


}

const QuotesRouter: any = withStyles(styles)(Quotes);
const QuotesComponent = withRouter(QuotesRouter);
export default withTranslation()(withSnackbar(QuotesComponent));
// Customizable Area End