import React from "react";

import {
  Container,
  Box,
  Typography,
  InputAdornment,
  // Customizable Area Start
  TextField,
  Checkbox,
  Table,
  TableHead,
  Button,
  TableRow,
  TableCell,
  TableContainer,
  ClickAwayListener,
  Hidden
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  dropDownSelectIcon,
  noTasksImage,
  todoIcon,
  userIcon,
  calendarIcon,
  urgencyIcon,
  deleteTaskIcon,
  dialogBoxIcon,
  backIconMobile
} from "./assets";
import {
  Theme,
  withStyles
} from "@material-ui/core/styles";
import "../../../blocks/Settings/src/CustomScrollbar.css";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { StyledRoot } from "../../dashboard/src/utility.web";
import { withSnackbar } from "../../../components/src/toastweb";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../blocks/dashboard/src/ReactDatePicker.css";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { CONSTANTS } from "../../../components/src/theme";
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withTranslation } from "react-i18next";
import moment from "moment";
import { ar } from "date-fns/locale";

export const StyledTableHead = withStyles({
  root: {
    "& .MuiTableCell-root": {
      width: "100% !important",
      maxWidth: "36% !important",
      padding: "unset !important",
      whiteSpace: "normal !important",
    }
  }
})(TableHead);

export const StyledTableRow = withStyles({
  root: {
    cursor: "pointer",
    "& .MuiTableCell-root": {
      height: "107px !important",
    }
  }
})(TableRow);

export const StyledTableRowHover = withStyles({
  root: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: CONSTANTS.color.darkBlue,
      "& .MuiTypography-root": {
        color: `${CONSTANTS.color.white} !important`,
      },
      "& .MuiInputBase-root .MuiInputBase-input": {
        color: `${CONSTANTS.color.white} !important`,
      },
      "& .MuiCheckbox-root": {
        "& .MuiSvgIcon-root": {
          fill: CONSTANTS.color.white,
        },
      },
      "& .urgencyTitle": {
        color: `${CONSTANTS.color.lightBrown} !important`,
      },
      "& .urgencyTexts": {
        color: `${CONSTANTS.color.lightBlack} !important`,
      },
      "& .taskHeading": {
        color: `${CONSTANTS.color.midGrey} !important`,
      },
    },
    "& .MuiTableCell-root": {
      height: "107px !important",
    }
  }
})(TableRow);

export const StyledCheckbox = withStyles({
  root: {
    height: "24px",
    width: "24px",
    color: "rgba(0,0,0,0.1)",
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: `${CONSTANTS.color.darkBlue} !important`
    },
    "& .MuiSvgIcon-root": {
      fontSize: "2rem"
    }
  }
})(Checkbox);

const StyledTextField = withStyles((theme) => ({
  root: {
    width: "100%",
    cursor: "pointer",
    "& .MuiInputBase-root": {
      borderRadius: "10px",
      fontSize: "14px",
      fontWeight: CONSTANTS.fontWeight.fontWeight700,
      [theme.breakpoints.down("xs")]: {
        fontSize: "15px",
      }
    },
    "& .MuiOutlinedInput-input":{
      color: CONSTANTS.color.midGrey,
      cursor: "pointer",
    },
    "& .MuiFormHelperText-root": {
      color: CONSTANTS.color.red,
      textAlign: "start" as const,
      margin: 0,
      fontSize: "0.875rem"
    }
  }
}))(TextField);

export const getDropdownSelectIconStyle = (condition: boolean, baseStyle: BaseStyle) => ({
  ...baseStyle,
  cursor: "pointer",
  transform: condition ? "rotate(-90deg)" : baseStyle.transform
});




export const mockTableHead = [
  { id: 1, title: "Task Name (To do)" },
  { id: 2, title: "Assignee" },
  { id: 3, title: "Due date" },
  { id: 4, title: "Urgency" },
  { id: 5, title: "Action" },
]

const urgencyStyle = (urgency: string) => {
  const baseStyle: BaseStyle = { ...webStyle.taskHeadingStart };
  if (urgency === "High" || urgency === "عالي") {
    baseStyle.color = CONSTANTS.color.deepRed;
  } else if (urgency === "Medium" || urgency === "واسطة") {
    baseStyle.color = CONSTANTS.color.richBrown;
  } else if (urgency === "Low" || urgency === "قليل") {
    baseStyle.color = CONSTANTS.color.limeGreen;
  }

  return baseStyle;
}

const urgencyStyleCompleted = (urgency: string) => {
  if(urgency === "High" || urgency === "عالي") {
    return {...webStyle.taskHeadingStart, color: CONSTANTS.color.deepRed};
  } else if (urgency === "Medium" || urgency === "واسطة") {
    return {...webStyle.taskHeadingStart, color: CONSTANTS.color.richBrown};
  } else if (urgency === "Low" || urgency === "قليل") {
    return {...webStyle.taskHeadingStart, color: CONSTANTS.color.limeGreen};
  }
}

const todoTextfieldStyle = (item: UpcomingTasks, editSelectedId: number, todoEditError: string) => {
  return item.id === editSelectedId && todoEditError ? webStyle.verticalAlign : {}
}

const getTableCellStyle = (itemId: number, languageSelected: string) => {
  let style: any = { ...webStyle.tableHeadText };

  if (itemId === 1) {
    if (languageSelected === "ar") {
      style = { ...style, ...webStyle.paddingRightLeft };
    } else {
      style = { ...style, ...webStyle.paddingLeftStyle };
    }
  }

  if (itemId === 5) {
    style = { ...style, textAlign: "center" };
  }

  return style;
};

const convertedAr = (languageSelected: string, convertedDate: string, originalDate: Date) => {
  const convertedDateAr = new Date(originalDate).toLocaleDateString('ar', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit'
  }).replace(/\d/g, digit => '٠١٢٣٤٥٦٧٨٩'[parseInt(digit)]);
  return languageSelected === "ar" ? convertedDateAr : convertedDate
}

const convertDateAr = (dateString: any, languageSelected: string) => {
  const originalDate = dateString.slice(0, 6) + dateString.slice(8);
  const convertedDateAr = moment(originalDate, "DD/MM/YY").locale('ar').format('YY/MM/DD');
  return languageSelected === "ar" ? convertedDateAr : originalDate
}

interface UrgencyDataTypes {
  id: number;
  title: string;
}

interface EmployeeDetails {
  id: string;
  icon: string;
  name: string;
  email: string;
}

interface UpcomingTasks {
  id: string | number;
  todo: string;
  assignedTo: {
    id: number;
    icon: string;
    name: string;
    email: string;
  }[]
  dueDate: string;
  urgency: string;
}

interface AssignedTo {
  id: number;
  icon: string;
  name: string;
  email: string;
}

interface BaseStyle {
  width?: string;
  height?: string;
  transform?: string;
  color?: string;
}
// Customizable Area End

import TaskAllocatorController, {
  Props,
  configJSON,
} from "./TaskAllocatorController";

export class TaskAllocator extends TaskAllocatorController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  showCompletedArrow = (languageSelected: string, showCompletedTasks: boolean) => {
    return languageSelected === "ar" && { transform: showCompletedTasks ? "rotate(0deg)" : "rotate(90deg)" }
  }
  showUpcomingArrow = (languageSelected: string, showUpcomingTasks: boolean) => {
    return languageSelected === "ar" && { transform: showUpcomingTasks ? "rotate(0deg)" : "rotate(90deg)" }
  }

  createATask = (languageSelected: string) => {
    const { t,classes } = this.props;
    return (
      <Box style={webStyle.backIconContainer}>
        <Hidden smUp>
          <img
            data-test-id="handleGoBack"
            style={
              languageSelected === "ar" ?
              {...webStyle.backIconMobile, ...webStyle.transformStyle} :
              webStyle.backIconMobile
            }
            src={backIconMobile}
            onClick={this.handleGoBack}
            alt=""
          />
        </Hidden>
        <Button
          data-test-id="handleCreateATask"
          className={classes.buttonContainer}
          onClick={this.handleCreateATask}
        >
          <Typography style={webStyle.buttonText}>
            {t('taskAllocator.createATask')}
          </Typography>
        </Button>
      </Box>
    );
  }
  
  renderCreateTaskRow = (classes: ClassNameMap) => {
    const {
      createTask,
      todoError
    } = this.state;
    return (
      <>
        {createTask && (
          <Table style={{minWidth: 850}}>
            <StyledTableHead>
              <StyledTableRow style={webStyle.positionRelative}>
                <TableCell style={todoError ? webStyle.verticalAlign : {}}>
                  {this.addTodoTableCell(classes)}
                </TableCell>
                <TableCell>
                  {this.addAssigneeTableCell(classes)}
                </TableCell>
                <TableCell>
                  {this.addDatePickerTableCell(classes)}
                </TableCell>
                <TableCell>
                  {this.addUrgencyTableCell(classes)}
                </TableCell>
                <TableCell>
                </TableCell>
              </StyledTableRow>
            </StyledTableHead>
          </Table>
        )}
      </>
    );
  }

  addTodoTableCell = (classes: ClassNameMap) => {
    const {
      showAddTodo,
      todo,
      todoError,
      languageSelected
    } = this.state;
    const { t } = this.props;
    return (
      <Box data-test-id="handleClickAddTodo" onClick={this.handleClickAddTodo}>
        {!showAddTodo ? (
          <Box
            style={
              languageSelected === "ar" ?
              {...webStyle.addTaskContainer, ...webStyle.paddingRightStyle} :
              {...webStyle.addTaskContainer, ...webStyle.paddingLeftStyle}
            }
            className={languageSelected === "ar" ? classes.addTaskContainerAr : classes.addTaskContainer}
          >
            <img style={webStyle.todoIcon} src={todoIcon} alt="" />
            <Typography
              style={webStyle.taskHeading}
              className={classes.fontSizeMobile}
            >
              {t("taskAllocator.todo")}
            </Typography>
          </Box>
        ) : (
          <>
            <Box
              style={{...webStyle.addTaskContainer, ...webStyle.paddingRightTodo}}
              className={languageSelected === "ar" ? classes.addTaskContainerAr : classes.addTaskContainer}
            >
              <StyledTextField
                data-test-id="handleChangeTodo"
                variant="outlined"
                autoFocus
                value={todo}
                onChange={this.handleChangeTodo}
                error={Boolean(todoError)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img style={webStyle.todoIcon} src={todoIcon} alt="" />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            {todoError && (
              <Typography
                variant="body2"
                style={
                  languageSelected === "ar" ?
                  {...webStyle.errorMessage, ...webStyle.paddingRightStyle} :
                  {...webStyle.errorMessage, ...webStyle.paddingLeftStyle}
                }
                className={languageSelected === "ar" ? classes.addTaskContainerAr : classes.addTaskContainer}
              >
                {todoError}
              </Typography>
            )}
          </>
        )}
      </Box>
    );
  }

  addAssigneeTableCell = (classes: ClassNameMap) => {
    const {
      showAssignee,
      showAssigneeModal,
      selectedEmployeeId,
      selectedEmployeeName,
      languageSelected
    } = this.state;
    const { t } = this.props;
    return (
      <Box data-test-id="handleClickAddAssignee" onClick={this.handleClickAddAssignee}>
        {!showAssignee ? (
          <Box style={{...webStyle.addTaskContainer, ...webStyle.paddingLeft}}>
            <img style={webStyle.userIcon} src={userIcon} alt="" />
            <Typography style={webStyle.taskHeading} className={classes.fontSizeMobile}>
              {t('taskAllocator.addAssignee')}
            </Typography>
          </Box>
        ) : (
          <Box
            style={
              languageSelected === "ar" ?
              {...webStyle.addTaskContainer, ...webStyle.paddingLeftStyle} :
              {...webStyle.addTaskContainer, ...webStyle.paddingRightStyle, ...webStyle.paddingLeft}
            }
          >
            <StyledTextField
              data-test-id="handleChangeEmployeeName"
              variant="outlined"
              autoFocus
              value={selectedEmployeeName}
              onChange={this.handleChangeEmployeeName}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img style={webStyle.userIcon} src={userIcon} alt="" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        )}
        {this.renderEmployeeDetails(showAssigneeModal, selectedEmployeeId as number, classes, this.handleClickAssigneeOutside, this.handleSelectEmployee)}
      </Box>
    );
  }

  renderEmployeeDetails = (assigneeModal: boolean, employeeId: number, classes: ClassNameMap, handleFunction: () => void, selectEmployeeFunction: Function) => {
    const {
      employeeDetails,
      languageSelected
    } = this.state;
    return (
      <>
        {assigneeModal && (
          <ClickAwayListener data-test-id="handleClickAssigneeOutside" onClickAway={handleFunction}>
            <Box
              style={{...webStyle.urgencyContainer, ...webStyle.assigneeStyle}}
              className={classes.assigneeStyle}
            >
              <img
                style={
                  languageSelected === "ar" ?
                  {...webStyle.dialogBoxIcon, ...webStyle.rightStyle} :
                  {...webStyle.dialogBoxIcon, left: "8%"}}
                  src={dialogBoxIcon}
                  alt=""
                
                />
              <Box style={webStyle.employeeDetailsBox}>
                {employeeDetails.map((item: EmployeeDetails, index: number) => (
                  <Box key={item.id}>
                    <Box
                      data-test-id="handleSelectEmployee"
                      style={item.id === employeeId?.toString() ? {...webStyle.employeeDetailsContainer, ...webStyle.backgroundColorStyle} : webStyle.employeeDetailsContainer}
                      onClick={() => selectEmployeeFunction(item)}
                    >
                      <img style={webStyle.employeeIcon} src={item.icon} alt="" />
                      <Box style={webStyle.assigneeDetails}>
                        <Typography className={`${classes.fontSizeMobile} taskHeading`} style={webStyle.taskHeading}>
                          {item.name}
                        </Typography>
                        <Typography className={`${classes.fontSizeMobile} taskHeading`} style={webStyle.taskHeading}>
                          {item.email}
                        </Typography>
                      </Box>
                    </Box>
                    {index !== employeeDetails.length - 1 && <span style={webStyle.hr}></span>}
                  </Box>
                ))}
              </Box>
            </Box>
          </ClickAwayListener>
        )}
      </>
    );
  }

  addDatePickerTableCell = (classes: ClassNameMap) => {
    const {
      showDueDate,
      dueDate,
      showDatePicker,
      languageSelected
    } = this.state;
    const { t } = this.props;
    const originalDate = new Date(dueDate);
    const convertedDate = `${(originalDate.getDate()).toString().padStart(2, '0')}/${(originalDate.getMonth() + 1).toString().padStart(2, '0')}/${originalDate.getFullYear().toString().slice(-2)}`;
    
    return (
      <Box data-test-id="handleClickDueDate" onClick={this.handleClickDueDate}>
        {!showDueDate ? (
          <Box style={{...webStyle.addTaskContainer, ...webStyle.paddingLeft}}>
            <img style={webStyle.userIcon} src={calendarIcon} alt="" />
            <Typography style={webStyle.taskHeading} className={classes.fontSizeMobile}>
              {t('taskAllocator.addDate')}
            </Typography>
          </Box>
        ) : (
          <Box
            style={languageSelected === "ar" ? webStyle.addTaskContainer : {...webStyle.addTaskContainer, ...webStyle.paddingRightStyle, ...webStyle.paddingLeft}}
            className={classes.paddingRightMobile}
          >
            <Box style={webStyle.innerContainerStyle}>
              <img style={webStyle.userIcon} src={calendarIcon} alt="" />
              <Typography style={webStyle.taskHeading} className={classes.fontSizeMobile}>
                {
                  !dueDate ?
                  `${t('taskAllocator.addDate')}` :
                  convertedAr(languageSelected, convertedDate, originalDate)
                }
              </Typography>
            </Box>
          </Box>
        )}
        {showDatePicker && (
          <div style={webStyle.datePickerContainer}>
            <DatePicker
              data-test-id="datePicker"
              selected={dueDate}
              onChange={this.handleSelectDate}
              inline
              ref={this.datePickerRef}
              onClickOutside={this.handleHideCalendar}
              locale={this.state.languageSelected}
              renderCustomHeader={({
                date,
                decreaseMonth,
                increaseMonth,
              }) => (
                <div
                  style={webStyle.customHeader}
                >
                  <span style={webStyle.arrowIcon} onClick={decreaseMonth}>{"<"}</span>
                  <span style={webStyle.textFormate}>{moment(date).format("MMMM YYYY")}</span>
                  <span style={webStyle.arrowIcon} onClick={increaseMonth}>{">"}</span>
                </div>
              )}
              renderDayContents={(_, date) => {
                return <span>{moment(date).format("DD")}</span>;
              }}
            />
          </div>
        )}
      </Box>
    );
  }

  addUrgencyTableCell = (classes: ClassNameMap) => {
    const {
      showUrgency,
      showUrgencyModal,
      urgency,
      urgencyData,
      languageSelected
    } = this.state;
    const { t } = this.props;
    return (
      <Box data-test-id="handleClickAddUrgency" onClick={this.handleClickAddUrgency}>
        {!showUrgency ? (
          <Box
            style={{...webStyle.addTaskContainer, ...webStyle.paddingLeft}}
          >
            <img style={webStyle.userIcon} src={urgencyIcon} alt="" />
            <Typography style={webStyle.taskHeading} className={classes.fontSizeMobile}>
              {t('taskAllocator.addUrgency')}
            </Typography>
          </Box>
        ) : (
          <Box
            style={{...webStyle.addTaskContainer, ...webStyle.paddingLeft}}
          >
            <Box style={{...webStyle.innerContainerStyle, gap: "1px", padding: "3px"}} className={classes.addUrgencyMobile}>
              <img style={webStyle.userIcon} src={urgencyIcon} alt="" />
              <Typography style={webStyle.taskHeading} className={classes.fontSizeMobile}>
                {!urgency ? `${t('taskAllocator.addUrgency')}` : urgency}
              </Typography>
            </Box>
          </Box>
        )}
        {showUrgencyModal && (
          <ClickAwayListener data-test-id="handleClickUrgencyOutside" onClickAway={this.handleClickUrgencyOutside}>
            <Box
              style={
                languageSelected === "ar" ?
                {...webStyle.urgencyContainer, ...webStyle.transformModal, ...webStyle.translateStyle} :
                {...webStyle.urgencyContainer, ...webStyle.transformModal}
              }
              className={languageSelected === "ar" ? classes.transformModalAr : classes.transformModal}
            >
              <img
                style={
                  languageSelected === "ar" ?
                  {...webStyle.dialogBoxIcon, ...webStyle.positionRightStyle} :
                  webStyle.dialogBoxIcon
                }
                src={dialogBoxIcon}
                alt=""
              />
              <Typography
                style={webStyle.urgencyTitle}
                variant="subtitle2"
                className={classes.fontWeightMobile}
              >
                <img style={webStyle.userIcon} src={urgencyIcon} alt="" /> {t('taskAllocator.urgency')}
              </Typography>
              {urgencyData.map((item: UrgencyDataTypes) => (
                <Box
                  data-test-id="handleSelectUrgency"
                  key={item.id}
                  style={item.title === urgency ? {...webStyle.urgencyTextsContainer, ...webStyle.backgroundColorStyle} : webStyle.urgencyTextsContainer}
                  onClick={() => this.handleSelectUrgency(item.title)}
                >
                  <Typography style={webStyle.urgencyTexts} className={classes.fontSizeMobile}>
                    {item.title}
                  </Typography>
                </Box>
              ))}
            </Box>
          </ClickAwayListener>
        )}
      </Box>
    );
  }

  renderNoTask = (classes: ClassNameMap) => {
    const { t } = this.props;
    return (
      <>
        {this.state.upcomingTasks.length === 0 && (
          <Box style={webStyle.noTaskContainer}>
            <Typography style={webStyle.taskListEmptyText} className={classes.taskListEmptyText} variant="subtitle2">
              {t('taskAllocator.taskListEmpty')}
            </Typography>
            <Typography style={webStyle.noTaskAvailableText} className={classes.noTaskAvailableText} variant="body2" color="textPrimary">
              {t('taskAllocator.taskListEmptyDesc')}
            </Typography>
            <img style={webStyle.noTasksImage} className={classes.noTasksImage} src={noTasksImage} alt="" />
          </Box>
        )}
      </>
    );
  }

  createATaskRow = (classes: ClassNameMap) => {
    return (
      <>
        {this.state.createTask && (
          <Box style={webStyle.tableRowMobile} className={classes.tableRowMobile}>
            <Box className={`${classes.todoAssigneeStyle} ${classes.tableRowTodoAssigneeAdd}`}>
              <Box style={webStyle.marginLeftStyle}>{this.addTodoTableCell(classes)}</Box>
              <Box>{this.addAssigneeTableCell(classes)}</Box>
            </Box>
            <Box className={`${classes.todoAssigneeStyle} ${classes.dueDateRowAdd}`}>
              <Box>{this.addDatePickerTableCell(classes)}</Box>
              <Box>{this.addUrgencyTableCell(classes)}</Box>
              <Box style={webStyle.visibilityStyle}></Box>
            </Box>
          </Box>
        )}
      </>
    );
  }

  renderCreateATaskRow = (classes: ClassNameMap) => {
    return (
      <>
        {this.state.upcomingTasks.length > 0 && 
          <Box style={!this.state.createTask ? {...webStyle.tableBoxContainer, display: "none"} : webStyle.tableBoxContainer}>
            {this.createATaskRow(classes)}
          </Box>
        }
      </>
    );
  }

  renderTodoTableCell = (item: UpcomingTasks, classes: ClassNameMap) => {
    const {
      selectedId,
      editTodo,
      editSelectedId,
      todoEditError,
      languageSelected
    } = this.state;
    return (
      <Box
        style={
          languageSelected === "ar" ?
          {...webStyle.addTaskContainer, ...webStyle.paddingRightLeft} :
          webStyle.addTaskContainer
        }
        className={languageSelected === "ar" ? classes.addTaskContainerAr : classes.addTaskContainer}
      >
        <StyledCheckbox
          data-test-id="handleCheckbox"
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
          checked={item.id === selectedId}
          onChange={(e) => this.handleCheckbox(e, item.id as number)}
        />
        {item.id !== editSelectedId ? (
          <Typography
            data-test-id="handleShowEditTodo"
            style={
              languageSelected === "ar" ?
              {...webStyle.taskHeading, ...webStyle.paddingTextAlign } :
              {...webStyle.taskHeading, ...webStyle.paddingLeftStyle, textAlign: "start"}
            }
            className={classes.fontSizeMobile}
            onClick={() => this.handleShowEditTodo(item.id as number)}
          >
            {item.todo}
          </Typography>
        ) : (
          <ClickAwayListener data-test-id="handleClickTodoOutside" onClickAway={() => this.handleClickTodoOutside(item.id as number)}>
            <Box style={{...webStyle.addTaskContainer, ...webStyle.addTaskPadding}}>
              <StyledTextField
                data-test-id="handleChangeEditTodo"
                variant="outlined"
                autoFocus
                name="editTodo"
                value={editTodo}
                onChange={this.handleChangeEditTodo}
                error={Boolean(todoEditError)}
                helperText={todoEditError}
              />
            </Box>
          </ClickAwayListener>
        )}
      </Box>
    );
  }

  renderAssigneeTableCell = (item: UpcomingTasks, classes: ClassNameMap) => {
    const {
      showAssigneeModalEdit,
      editSelectedIdEmployee,
      selectedEmployeeNameEdit,
      editSelectedIdEmployeeId
    } = this.state;
    return (
      <Box data-test-id="handleShowEditAssignee" onClick={() => this.handleShowEditAssignee(item.id as number)}>
        {item.id !== editSelectedIdEmployee ? (
          this.renderAssigneeDetails(item, classes)
        ) : (
          <>
            <Box style={{...webStyle.addTaskContainer, ...webStyle.paddingRightStyle}}>
              <StyledTextField
                data-test-id="handleChangeEmployeeNameEdit"
                variant="outlined"
                autoFocus
                value={selectedEmployeeNameEdit}
                onChange={this.handleChangeEmployeeNameEdit}
              />
            </Box>
            {this.renderEmployeeDetails(showAssigneeModalEdit, editSelectedIdEmployeeId as number, classes, this.handleClickOutsideEmployeeEdit, this.handleSelectEmployeeEdit)}
          </>
        )}
      </Box>
    );
  }

  renderDueDateTableCell = (item: UpcomingTasks, classes: ClassNameMap) => {
    const {
      editDueDate,
      editSelectedDueDateId,
      showDatePickerEdit,
      languageSelected
    } = this.state;
    const modifiedDate = item?.dueDate?.slice(0, 6) + item?.dueDate?.slice(8);

    const originalDate = new Date(editDueDate);
    const convertedDate = `${(originalDate?.getDate()).toString().padStart(2, '0')}/${(originalDate?.getMonth() + 1).toString().padStart(2, '0')}/${originalDate?.getFullYear().toString().slice(-2)}`;
      
    const convertedmodifiedDateAr = new Date(modifiedDate).toLocaleDateString('ar', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit'
    }).replace(/\d/g, digit => '٠١٢٣٤٥٦٧٨٩'[parseInt(digit)]);

    let arabicDate: string | undefined;
    if (typeof modifiedDate === 'string') {
      const [yearStr, monthStr, dayStr] = modifiedDate.split('/');
      const year: number = parseInt(yearStr, 10);
      const month: number = parseInt(monthStr, 10);
      const day: number = parseInt(dayStr, 10);
      const date = new Date(2000 + year, month - 1, day);

      const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${(date.getFullYear() % 100).toString().padStart(2, '0')}`;

      arabicDate = formattedDate.replace(/\d/g, digit => '٠١٢٣٤٥٦٧٨٩'[parseInt(digit)]);
    }
    
    return (
      <>
        {item.id !== editSelectedDueDateId ? (
          <Typography
            data-test-id="handleShowEditDueDate"
            style={webStyle.taskHeadingStart}
            className={classes.fontSizeMobile}
            onClick={() => this.handleShowEditDueDate(item.id as number)}
          >
            {languageSelected === "ar" ? (arabicDate as string) : modifiedDate}
          </Typography>
        ) : (
          <>
            <Box
              style={
                languageSelected === "ar" ?
                webStyle.paddingLeftStyle :
                webStyle.paddingRightStyle
              }
            >
              <Box style={webStyle.innerContainerStyle}>
                <Typography style={webStyle.taskHeading} className={classes.fontSizeMobile}>
                  {
                    !editDueDate ?
                    convertedmodifiedDateAr :
                    convertedAr(languageSelected, convertedDate, originalDate)
                  }
                </Typography>
              </Box>
            </Box>
            {showDatePickerEdit && (
              <div style={webStyle.datePickerContainer}>
                <DatePicker
                  data-test-id="handleSelectDateEdit"
                  selected={editDueDate}
                  onChange={(date) => this.handleSelectDateEdit(date as Date, item.id as number)}
                  inline
                  ref={this.editDatePickerRef}
                  onClickOutside={this.handleHideCalendarEdit}
                  locale={this.state.languageSelected}
                  renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                    <div style={webStyle.customHeader}>
                      <span style={webStyle.arrowIcon} onClick={decreaseMonth}>{"<"}</span>
                      <span style={webStyle.textFormate}>{moment(date).format("MMMM YYYY")}</span>
                      <span style={webStyle.arrowIcon} onClick={increaseMonth}>{">"}</span>
                    </div>
                  )}
                  renderDayContents={(_, date) => {
                    return <span>{moment(date).format("DD")}</span>
                  }}
                />
              </div>
            )}
          </>
        )}
      </>
    );
  }

  renderUrgencyTableCell = (item: UpcomingTasks, classes: ClassNameMap) => {
    const {
      editSelectedUrgency,
      editUrgency,
      showEditUrgency,
      urgencyData,
      languageSelected
    } = this.state;
    const { t } = this.props;
    return (
      <Box style={webStyle.renderUrgencyTable}>
        {item.id !== editSelectedUrgency ? (
          <Typography
            data-test-id="handleShowEditUrgency"
            style={urgencyStyle(item.urgency)}
            className={classes.fontSizeMobile}
            component={"div"}
            onClick={() => this.handleShowEditUrgency(item.id as number)}
          >
            {item.urgency}
          </Typography>
        ) : (
          <Box style={webStyle.urgencyBoxStyle}>
            <Box style={languageSelected === "ar" ? webStyle.paddingRight : webStyle.paddingRightStyle}>
              <Box style={webStyle.innerContainerStyle}>
                <Typography style={webStyle.taskHeading} className={classes.fontSizeMobile}>
                  {!editUrgency ? item.urgency : editUrgency}
                </Typography>
              </Box>
            </Box>
            {showEditUrgency && (
              <ClickAwayListener onClickAway={this.handleClickEditUrgencyOutside}>
                <Box
                  style={
                    languageSelected === "ar" ?
                    {...webStyle.urgencyContainer, ...webStyle.transformModal, ...webStyle.translateStyle} :
                    {...webStyle.urgencyContainer, ...webStyle.transformModal}
                  }
                  className={languageSelected === "ar" ? classes.transformModalAr : classes.transformModal}
                >
                  <img
                    style={
                    languageSelected === "ar" ?
                    {...webStyle.dialogBoxIcon, ...webStyle.positionRightStyle} :
                    webStyle.dialogBoxIcon}
                    src={dialogBoxIcon}
                    alt=""
                  />
                  <Typography
                    className={`${classes.fontWeightMobile} urgencyTitle`}
                    style={webStyle.urgencyTitle}
                    variant="subtitle2"
                  >
                    <img style={webStyle.userIcon} src={urgencyIcon} alt="" /> {t('taskAllocator.urgency')}
                  </Typography>
                  {urgencyData.map((data: UrgencyDataTypes) => (
                    <Box
                      data-test-id="handleSelectEditUrgency"
                      key={data.id}
                      style={data.title === editUrgency ? {...webStyle.urgencyTextsContainer, ...webStyle.backgroundColorStyle} : webStyle.urgencyTextsContainer}
                      onClick={() => this.handleSelectEditUrgency(data, item.id as number)}
                    >
                      <Typography
                        className={`urgencyTexts ${classes.fontSizeMobile}`}
                        style={webStyle.urgencyTexts}
                      >
                        {data.title}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </ClickAwayListener>
            )}
          </Box>
        )}
      </Box>
    );
  }

  renderActionsTableCell = (item: UpcomingTasks, classes: ClassNameMap) => {
    return (
      <Box style={webStyle.deleteContainer}>
        <Box
          data-test-id="handleDeleteUpcomingTask"
          style={webStyle.deleteBoxContainer}
          onClick={() => this.handleDeleteUpcomingTask(item.id as number)}
        >
          <img
            style={webStyle.deleteTaskIcon}
            className={classes.deleteTaskIcon}
            src={deleteTaskIcon}
            alt=""
          />
        </Box>
      </Box>
    )
  }

  renderCompletedCheckbox = (item: UpcomingTasks, classes: ClassNameMap) => {
    return (
      <Box style={this.state.languageSelected === "en" ? { ...webStyle.addTaskContainer, ...webStyle.paddingLeftStyle } :
        { ...webStyle.addTaskContainer, ...webStyle.paddingRightLeft }
      }>
        <StyledCheckbox
          data-test-id="handleCheckboxCompleted"
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
          checked={item.id !== this.state.selectedId}
          onChange={(e) => this.handleCheckboxCompleted(e, item.id as number)}
        />
        <Typography style={this.state.languageSelected === "en" ? { ...webStyle.taskHeading, ...webStyle.paddingLeftStyle } :
          { ...webStyle.taskHeading, ...webStyle.paddingRightLeft }
        } className={classes.fontSizeMobile}>
          {item.todo}
        </Typography>
      </Box>
    );
  }

  renderAssigneeDetails = (item: UpcomingTasks, classes: ClassNameMap) => {
    return (
      <>
        {item.assignedTo && item.assignedTo.map((i: AssignedTo) => (
          <Box
            style={{...webStyle.addTaskContainer, ...webStyle.paddingLeft, ...webStyle.gapStyle}}
            key={i.id}
          >
            <img style={webStyle.employeeIcon} src={i.icon} alt="" />
            <Box style={webStyle.assigneeDetails}>
              <Typography style={webStyle.taskHeading} className={classes.fontSizeMobile}>
                {i.name}
              </Typography>
              <Typography style={{...webStyle.taskHeading, ...webStyle.wordBreakStyle}} className={classes.fontSizeMobile}>
                {i.email}
              </Typography>
            </Box>
          </Box>
        ))}
      </>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      showUpcomingTasks,
      upcomingTasks,
      createTask,
      editSelectedId,
      getAllTasksLoading,
      addTaskLoading,
      deleteTaskLoading,
      editTaskLoading,
      getEmployeesListLoading,
      completedTaskLoading,
      todoEditError,
      completedToUpcomingLoading,
      languageSelected
    } = this.state;
    const { t } = this.props;
    const tableHeadMock = [
      {id: 1, title: t('taskAllocator.taskName')},
      {id: 2, title: t('taskAllocator.assignee')},
      {id: 3, title: t('taskAllocator.dueDate')},
      {id: 4, title: t('taskAllocator.urgency')},
      {id: 5, title: t('taskAllocator.action')}
    ]

    if (this.state.languageSelected === 'ar') {
      registerLocale("ar", ar);
      moment.locale('ar');
    } else {
      moment.locale('en');
    }
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <DashboardSideBar oldProps={undefined} permissions={undefined} {...this.props} navMenuItem={t('dashboardNavbar.task')} active={2} active1={false} active2={false} />
        <div className={"custom-scrollbar"}>
          <StyledRoot languageSelected={this.state.languageSelected}>
            <Box sx={webStyle.container} className={classes.boxContainer}>
              <Container maxWidth={false}>
                {this.createATask(languageSelected)}

                {/* upcoming tasks */}

                <Box style={webStyle.upcomingTaskStyle}>
                  <span style={webStyle.headerContainer}>
                    <img
                      data-test-id="handleViewAllUpcomingTasks"
                      style={{
                        ...getDropdownSelectIconStyle(!showUpcomingTasks, webStyle.dropDownSelectIcon),
                        ...(this.showUpcomingArrow(languageSelected, showUpcomingTasks))
                    }}
                      src={dropDownSelectIcon}
                      alt=""
                      onClick={this.handleViewAllUpcomingTasks}
                    />
                    <Typography style={webStyle.headerText} className={classes.headerText} onClick={this.handleViewAllUpcomingTasks} component={"span"} variant="subtitle1">
                      {t('taskAllocator.upcomingTask')}
                    </Typography>
                  </span>
                </Box>

                {showUpcomingTasks ? (
                  <>
                    <Box style={webStyle.tableBoxContainer} className={upcomingTasks.length !== 0 ? `${classes.tableBoxContainer} ${classes.hideTable}` : `${classes.tableBoxContainer}`}>
                      <Hidden smDown>
                        <TableContainer style={webStyle.tableContainerStyle}>
                          <Table style={{minWidth:850}}>
                            <StyledTableHead>
                              <TableRow>
                                {tableHeadMock.map((item) => (
                                  <TableCell key={item.id}>
                                    <Typography
                                      style={getTableCellStyle(item.id, this.state.languageSelected)}
                                    >
                                      {item.title}
                                    </Typography>
                                  </TableCell>
                                ))}
                              </TableRow>
                            </StyledTableHead>
                          </Table>
                          {this.renderCreateTaskRow(classes)}
                          {(createTask || upcomingTasks.length > 0) && (
                            <Table style={{minWidth: 850}}>
                              <StyledTableHead>
                                {upcomingTasks.map((item: UpcomingTasks) => (
                                  <StyledTableRowHover
                                    style={webStyle.positionRelative}
                                    key={item.id}
                                  >
                                    <TableCell style={todoTextfieldStyle(item, editSelectedId as number, todoEditError)}>
                                      {this.renderTodoTableCell(item, classes)}
                                    </TableCell>
                                    <TableCell>
                                      {this.renderAssigneeTableCell(item, classes)}
                                    </TableCell>
                                    <TableCell>
                                      {this.renderDueDateTableCell(item, classes)}
                                    </TableCell>
                                    <TableCell>
                                      {this.renderUrgencyTableCell(item, classes)}
                                    </TableCell>
                                    <TableCell>
                                      {this.renderActionsTableCell(item, classes)}
                                    </TableCell>
                                  </StyledTableRowHover>
                                ))}
                              </StyledTableHead>
                            </Table>
                          )}
                        </TableContainer>
                      </Hidden>
                      <Hidden mdUp>
                        {upcomingTasks.length === 0 &&
                          <Box style={webStyle.tableRowMobile} className={classes.tableRowMobile}>
                            <Box style={webStyle.todoAssigneeStyle} className={`${classes.todoAssigneeStyle} ${classes.tableRowTodoAssignee}`}>
                              <Typography style={webStyle.tableHeadText}>
                                {t('taskAllocator.taskName')}
                              </Typography>
                              <Typography style={webStyle.tableHeadText}>
                                {t('taskAllocator.assignee')}
                              </Typography>
                            </Box>
                            <Box style={webStyle.todoAssigneeStyle} className={`${classes.todoAssigneeStyle} ${classes.dueDateRow}`}>
                              <Typography style={webStyle.tableHeadText}>
                                {t('taskAllocator.dueDate')}
                              </Typography>
                              <Typography style={webStyle.tableHeadText}>
                                {t('taskAllocator.urgency')}
                              </Typography>
                              <Typography style={webStyle.tableHeadText}>
                                {t('taskAllocator.action')}
                              </Typography>
                            </Box>
                          </Box>
                        }
                        {upcomingTasks.length === 0 && this.createATaskRow(classes)}
                      </Hidden>
                      {this.renderNoTask(classes)}
                    </Box>
                    <Hidden mdUp>
                      {this.renderCreateATaskRow(classes)}
                    </Hidden>
                    <Hidden mdUp>
                      {(createTask || upcomingTasks.length > 0) && (
                        upcomingTasks.map((item: UpcomingTasks) => {
                          return (
                            <Box style={{...webStyle.tableBoxContainer, ...webStyle.marginTopStyle}}>
                              <Box style={webStyle.tableRowMobile} className={classes.tableRowMobile}>
                                <Box className={`${classes.todoAssigneeStyle} ${classes.tableRowTodoAssigneeAdd}`}>
                                  <Box style={webStyle.marginLeftStyle}>
                                    <Typography style={{...webStyle.tableHeadText, ...webStyle.paddingStyleTodo}} gutterBottom>
                                      {t('taskAllocator.taskName')}
                                    </Typography>
                                    {this.renderTodoTableCell(item, classes)}
                                  </Box>
                                  <Box>
                                    <Typography style={{...webStyle.tableHeadText, ...webStyle.paddingStyle}} gutterBottom>
                                      {t('taskAllocator.assignee')}
                                    </Typography>
                                    {this.renderAssigneeTableCell(item, classes)}
                                  </Box>
                                </Box>
                                <Box className={`${classes.todoAssigneeStyle} ${classes.dueDateRowAdd}`}>
                                  <Box>
                                    <Typography style={{...webStyle.tableHeadText, ...webStyle.paddingStyle}} gutterBottom>
                                      {t('taskAllocator.dueDate')}
                                    </Typography>
                                    {this.renderDueDateTableCell(item, classes)}
                                  </Box>
                                  <Box>
                                    <Typography style={{...webStyle.tableHeadText, ...webStyle.paddingStyle}} gutterBottom>
                                      {t('taskAllocator.urgency')}
                                    </Typography>
                                    {this.renderUrgencyTableCell(item, classes)}
                                  </Box>
                                  <Box>
                                    <Typography style={{...webStyle.tableHeadText, ...webStyle.paddingStyle}} gutterBottom>
                                      {t('taskAllocator.action')}
                                    </Typography>
                                    {this.renderActionsTableCell(item, classes)}
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          );
                        })
                      )}
                    </Hidden>
                  </>
                ) : (
                  null
                )}

                {/* completed tasks */}

                {this.state.completedTasks?.length > 0 && (
                  <>
                    <Box style={webStyle.containerStyle}>
                      <span style={webStyle.headerContainer}>
                        <img
                          data-test-id="handleViewAllCompletedTasks"
                          style={{
                            ...getDropdownSelectIconStyle(!this.state.showCompletedTasks, webStyle.dropDownSelectIcon),
                            ...this.showCompletedArrow(languageSelected, this.state.showCompletedTasks)
                          }}
                          src={dropDownSelectIcon}
                          alt=""
                          onClick={this.handleViewAllCompletedTasks}
                        />
                        <Typography style={webStyle.headerText} className={classes.headerText} onClick={this.handleViewAllCompletedTasks} component={"span"} variant="subtitle1">
                         {t("taskAllocator.completedTasks")}
                        </Typography>
                      </span>
                    </Box>

                    <Hidden smDown>
                      {this.state.showCompletedTasks ? (
                        <Box style={webStyle.tableBoxContainer}>
                          <Table style={{minWidth: 850}}>
                            <StyledTableHead>
                              <TableRow>
                                {tableHeadMock.map((item) => (
                                  <TableCell key={item.id}>
                                    <Typography
                                      style={getTableCellStyle(item.id, this.state.languageSelected)}
                                    >
                                      {item.title}
                                    </Typography>
                                  </TableCell>
                                ))}
                              </TableRow>
                            </StyledTableHead>
                          </Table>
                          <Table style={{minWidth: 850}}>
                            <StyledTableHead>
                              {this.state.completedTasks.map((item: UpcomingTasks) => (
                                <StyledTableRowHover
                                  data-test-id="handleSelectCompleted"
                                  key={item.id}
                                >
                                  <TableCell>
                                    {this.renderCompletedCheckbox(item, classes)}
                                  </TableCell>
                                  <TableCell>
                                    {this.renderAssigneeDetails(item, classes)}
                                  </TableCell>
                                  <TableCell>
                                    <Typography style={webStyle.taskHeadingStart}>
                                  {convertDateAr(item.dueDate,languageSelected)}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Box style={webStyle.tableRowDeleteContainer}>
                                      <Typography style={urgencyStyleCompleted(item.urgency)}>
                                        {item.urgency}
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    {this.renderActionsTableCell(item, classes)}
                                  </TableCell>
                                </StyledTableRowHover>
                              ))}
                            </StyledTableHead>
                          </Table>
                        </Box>
                      ) : (
                        null
                      )}
                    </Hidden>

                    <Hidden mdUp>
                      {this.state.showCompletedTasks && this.state.completedTasks.map((item: UpcomingTasks) => {
                        return (
                          <Box style={{...webStyle.tableBoxContainer, ...webStyle.marginTopStyle}}>
                            <Box style={webStyle.tableRowMobile} className={classes.tableRowMobile}>
                              <Box className={`${classes.todoAssigneeStyle} ${classes.tableRowTodoAssigneeAdd}`}>
                                <Box style={webStyle.marginLeftStyle}>
                                  <Typography style={{...webStyle.tableHeadText, ...webStyle.paddingStyleTodo}} gutterBottom>
                                    {t('taskAllocator.taskName')}
                                  </Typography>
                                  {this.renderCompletedCheckbox(item, classes)}
                                </Box>
                                <Box>
                                  <Typography style={{...webStyle.tableHeadText, ...webStyle.paddingStyle}} gutterBottom>
                                  {t('taskAllocator.assignee')}
                                  </Typography>
                                  {this.renderAssigneeDetails(item, classes)}
                                </Box>
                              </Box>
                              <Box className={`${classes.todoAssigneeStyle} ${classes.dueDateRowAdd}`}>
                                <Box>
                                  <Typography style={{...webStyle.tableHeadText, ...webStyle.paddingStyle}} gutterBottom>
                                  {t('taskAllocator.dueDate')}
                                  </Typography>
                                  <Typography style={webStyle.taskHeadingStart} className={classes.fontSizeMobile}>
                                  {convertDateAr(item.dueDate,languageSelected)}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography style={{...webStyle.tableHeadText, ...webStyle.paddingStyle}} gutterBottom>
                                  {t('taskAllocator.urgency')}
                                  </Typography>
                                  <Typography style={urgencyStyleCompleted(item.urgency)} className={classes.fontSizeMobile}>
                                    {item.urgency}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography style={{...webStyle.tableHeadText, ...webStyle.paddingStyle}} gutterBottom>
                                  {t('taskAllocator.action')}
                                  </Typography>
                                  {this.renderActionsTableCell(item, classes)}
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                    </Hidden>
                  </>
                )}
              </Container>
            </Box>
          </StyledRoot>
        </div>
        <LoadingBackdrop
          loading={
            getAllTasksLoading ||
            addTaskLoading ||
            deleteTaskLoading ||
            editTaskLoading ||
            getEmployeesListLoading ||
            completedTaskLoading ||
            completedToUpcomingLoading
          }
        />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  boxContainer: {
    [theme.breakpoints.down("md")]: {
      padding: "0rem !important"
    }
  },
  tableBoxContainer: {
    [theme.breakpoints.down("md")]: {
      boxShadow: "none !important"
    }
  },
  hideTable: {
    [theme.breakpoints.down("sm")]: {
      display: "none !important"
    }
  },
  headerText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px !important"
    }
  },
  taskListEmptyText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px !important"
    }
  },
  noTaskAvailabletext: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "255px !important"
    }
  },
  noTasksImage: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "267px !important",
      minHeight: "197px !important"
    }
  },
  todoAssigneeStyle: {
    [theme.breakpoints.down(860)]: {
      gap: "5rem !important"
    }
  },
  tableRowTodoAssigneeAdd: {
    display: 'flex',
    justifyContent: "space-between",
    gap: "9rem !important",
    [theme.breakpoints.down(860)]: {
      gap: "8rem !important"
    },
    [theme.breakpoints.down(828)]: {
      gap: "7rem !important"
    },
    [theme.breakpoints.down(960)]: {
      flexDirection: "column !important",
      gap: "2rem !important"
    },
  },
  dueDateRowAdd: {
    display: 'flex',
    justifyContent: "space-between",
    alignItems: "center",
    gap: "4.5rem !important",
    [theme.breakpoints.down(860)]: {
      gap: "4rem !important"
    },
    [theme.breakpoints.down(828)]: {
      gap: "3rem !important"
    },
    [theme.breakpoints.down("xs")]: {
      gap: "0rem !important"
    },
  },
  dueDateRow: {
    [theme.breakpoints.down("xs")]: {
      gap: "2rem !important"
    }
  },
  tableRowMobile: {
    position: "relative" as const,
    [theme.breakpoints.down(960)]: {
      flexDirection: "column !important"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0rem 1rem 2rem !important"
    },
  },
  tableRowTodoAssignee: {
    [theme.breakpoints.down(960)]: {
      gap: "2rem !important",
      flexDirection: "column !important"
    }
  },
  addUrgencyMobile: {
    [theme.breakpoints.down("xs")]: {
      gap: "5px !important",
      padding: "0 0.5rem !important"
    }
  },
  paddingRightMobile: {
    [theme.breakpoints.down("xs")]: {
      paddingRight: "0 !important"
    }
  },
  transformModal: {
    [theme.breakpoints.down("xs")]: {
      transform: "translate(-45%, 5%) !important"
    }
  },
  transformModalAr: {
      transform: "translate(0%, 5%) !important"
  },
  deleteTaskIcon: {
    [theme.breakpoints.down("xs")]: {
      width: "24px !important",
      height: "24px !important"
    }
  },
  fontSizeMobile: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px !important",
    }
  },
  assigneeStyle: {
    [theme.breakpoints.down("xs")]: {
      transform: "translate(-4%, 10%) !important",
    }
  },
  addTaskContainerAr: {
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0rem !important",
    }
  },
  addTaskContainer: {
    [theme.breakpoints.down("sm")]: {
      paddingRight: "1rem !important",
    }
  },
  fontWeightMobile: {
    display: 'flex',
    alignItems: 'center',
    gap: "5px",
    [theme.breakpoints.down("xs")]: {
      fontWeight: `${CONSTANTS.fontWeight.fontWeight600} !important`,
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    maxWidth: "150px",
    width: "100%",
    minHeight: "45px",
    borderRadius: "10px",
    backgroundColor: CONSTANTS.color.darkBlue,
    textTransform: "none" as const,
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      color: theme.palette.common.white,
      cursor: "pointer",
    },
  },
});

const webStyle = {
  container: {
    marginTop: "7rem",
    paddingBottom: "20rem",
  },
  backIconContainer: {
    display: "flex",
    alignItems: "center",
    gap: "1rem"
  },
  backIconMobile: {
    width: "12px",
    height: "24px"
  },
  transformStyle: {
    transform: "rotate(180deg)"
  },
  buttonText: {
    color: CONSTANTS.color.white,
    fontSize: "14px",
    fontWeight: CONSTANTS.fontWeight.fontWeight700,
    textAlign: "center" as const
  },
  upcomingTaskStyle: {
    paddingTop: "1.5rem"
  },
  headerContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "1rem",
  },
  headerText: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    cursor: "pointer",
  },
  dropDownSelectIcon: {
    width: "16px",
    height: "10px",
  },
  tableBoxContainer: {
    padding: "2rem 0 0 0rem",
    maxWidth: "100%",
    borderRadius: "24px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 6px 17px rgba(28,24,175,0.07)",
  },
  marginTopStyle: {
    marginTop: "1rem"
  },
  tableContainerStyle: {
    overflowX: "visible" as const
  },
  tableHeadText: {
    color: CONSTANTS.color.lightGrey,
    fontSize: "14px",
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "18px",
    textAlign: "start" as const,
  },
  paddingStyleTodo: {
    padding: "0 0 5px 1rem"
  },
  paddingStyle: {
    padding: "0 0 5px 0rem"
  },
  tableRowMobile: {
    display: "flex",
    justifyContent: "space-between",
    padding: "1rem 2rem 2rem",
    gap: "2rem"
  },
  marginLeftStyle: {
    marginLeft: "-1rem"
  },
  visibilityStyle: {
    visibility: "hidden" as const
  },
  todoAssigneeStyle: {
    display: 'flex',
    justifyContent: "space-between",
    gap: "6rem",
  },
  noTaskContainer: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
    minHeight: "500px",
  },
  taskListEmptyText: {
    color: CONSTANTS.color.darkBlue,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  noTaskAvailableText: {
    width: "100%",
    maxWidth: "341px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const
  },
  noTasksImage: {
    width: "100%",
    maxWidth: "354.83px",
    minHeight: "261.87px"
  },
  addTaskOuterContainer: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "flex-start",
  },
  positionRelative: {
    position: "relative" as const
  },
  verticalAlign: {
    verticalAlign: "bottom"
  },
  addTaskContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    paddingLeft: "1rem"
  },
  gapStyle: {
    gap: "1rem"
  },
  addTaskPadding: {
    paddingRight: "1rem",
    paddingLeft: "1rem",
    width: "100%"
  },
  urgencyBoxStyle: {
    width: "100%"
  },
  paddingRightTodo: {
    paddingRight: "1rem",
    paddingLeft: "1rem",
  },
  paddingRightStyle: {
    paddingRight: "1rem"
  },
  paddingRight: {
    paddingRight: 0
  },
  paddingLeft: {
    paddingLeft: "0"
  },
  renderUrgencyTable: {
    display: "flex",
    alignItems: "center",
    gap: "1rem"
  },
  paddingLeftStyle: {
    paddingLeft: "1rem"
  },
  paddingRightLeft: {
    paddingRight: "1rem",
    paddingLeft: 0
  },
  innerContainerStyle: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    height: "56px",
    borderRadius: "10px",
    border: "2px solid rgb(221 219 219)",
    padding: "0.5rem",
    width: "100%",
  },
  employeeDetailsContainer: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    padding: "1rem"
  },
  backgroundColorStyle: {
    backgroundColor: CONSTANTS.color.lightTrasparentBlue
  },
  todoIcon:  {
    width: "20px",
    height: "19px",
    cursor: "pointer"
  },
  userIcon:  {
    width: "24px",
    height: "24px",
    cursor: "pointer"
  },
  employeeIcon:  {
    width: "34px",
    height: "34px",
    borderRadius: "50%"
  },
  assigneeDetails: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "flex-start"
  },
  taskHeading: {
    color: CONSTANTS.color.midGrey,
    fontSize: "14px",
    fontWeight: CONSTANTS.fontWeight.fontWeight700,
    lineHeight: "24px",
    cursor: "pointer"
  },
  paddingTextAlign: {
    textAlign: "start" as const,
    paddingRight: "1rem"
  },
  wordBreakStyle: {
    wordBreak: "break-all" as const,
    textAlign: "start" as const
  },
  taskHeadingStart: {
    color: CONSTANTS.color.midGrey,
    textAlign: "start" as const,
    fontSize: "14px",
    fontWeight: CONSTANTS.fontWeight.fontWeight700,
    lineHeight: "24px",
  },
  tableRowDeleteContainer: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem"
  },
  deleteBoxContainer: {
    cursor: "pointer",
  },
  deleteContainer: {
    display: "flex",
    justifyContent: "center",
    width: '100%',
    gap: "10px"
  },
  deleteTaskIcon: {
    width: "40px",
    height: "40px",
  },
  datePickerContainer: {
    position: "absolute" as const,
    transform: "translate(2px, 12px)",
    zIndex: 1
  },
  dialogBoxIcon: { 
    height: "8px",
    width: "16px",
    position: "absolute" as const,
    left: "80%",
    top: "-1%",
    transform: "translate(-50%, -50%)",
  },
  rightStyle: {
    right: "8%"
  },
  positionRightStyle: {
    right: "10%",
    left: 0
  },
  employeeDetailsBox: {
    maxHeight: "164px",
    overflow: "auto"
  },
  urgencyContainer: {
    padding: "0.5rem 0",
    position: "absolute" as const,
    maxWidth: "230px",
    width: "100%",
    minHeight: "217px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    borderRadius: "5px",
    transform: "translate(-40%, 7%)",
    zIndex: 1,
  },
  transformModal: {
    transform: "translate(-55%, 5%)"
  },
  translateStyle: {
    transform: "translate(50%, 5%)"
  },
  assigneeStyle: {
    padding: "0px",
    maxWidth: "354px",
    minHeight: "160px",
    transform: "translate(0%, 10%)"
  },
  urgencyTitle: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "start" as const,
    padding: "0.5rem 1.5rem"
  },
  urgencyTextsContainer: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "flex-start",
    padding: "1.5rem"
  },
  urgencyTexts: {
    color: CONSTANTS.color.lightBlack,
    fontSize: "14px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  hr: {
    display: "flex",
    height: "1px",
    border: `1px solid ${CONSTANTS.color.paleGrey}`,
  },
  errorMessage: {
    margin: "5px",
    color: CONSTANTS.color.red,
    textAlign: "start" as const,
    paddingLeft: "1rem"
  },
  containerStyle: {
    paddingTop: "2.5rem"
  },
  customHeader: {
    margin: "0px 12px",
    display: "flex",
    justifyContent: "space-between",
  },
  textFormate: {
    fontSize: "16px",
    fontWeight: CONSTANTS.fontWeight.fontWeight600,
  },
  arrowIcon: {
    fontSize: '25px',
    marginTop: "-3px",
    color: "#ccc",
    fontWeight: CONSTANTS.fontWeight.fontWeight600,
    cursor: "pointer"
  },
}

const taskAllocatorStyles = withStyles(styles)(TaskAllocator);
const taskAllocatorSnackbar = withSnackbar(taskAllocatorStyles);
export default withTranslation()(taskAllocatorSnackbar);
// Customizable Area End
