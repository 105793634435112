import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  InputBase,
} from "@material-ui/core";
import {
  styled,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { countryCodes } from "../../dashboard/src/utility.web";
import Autocomplete from '@material-ui/lab/Autocomplete';
export const configJSON = require("./config");
import { CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
import { StyledMenuItem } from "../../Settings/src/CompanyInformation.web";
interface Currency {
  "id": number,
  "country": string,
  "timezone": string,
  "created_at": string,
  "updated_at": string,
  "active": boolean,
  "currency": string|undefined
}
// Customizable Area End

import CreateSupplierController, {
  Props,
} from "./CreateSupplierController.web";

// Customizable Area Start
export const StyleGrid = styled(Grid)(({ theme }: any) => ({
  [theme.breakpoints.down('sm')]: {
    '& > .MuiGrid-item': {
      padding: "0.5rem 0px 1rem 0.5rem",
    },
  },
}));

export const StyledGrid = styled(Grid)(({ theme }: any) => ({
  [theme.breakpoints.down('sm')]: {
    '& > .MuiGrid-item': {
      padding: "8px",
    },
  },
}));

export const StyledGridItem = styled(Grid)(({ theme }: any) => ({
  [theme.breakpoints.down('sm')]: {
    paddingTop: "4rem !important",
  },
}));

export const StyledGridItem2 = styled(Grid)(({ theme }: any) => ({
  [theme.breakpoints.down('sm')]: {
    paddingTop: "1.4rem !important",
  },
}));

export const StyledTextFields = withStyles((theme: Theme) => ({
  root: {
    "& .MuiInputBase-root": {
      borderRadius: "10px",
      color: CONSTANTS.color.lightBrown,
      [theme.breakpoints.down('xs')]: {
        fontSize: "18px !important"
      }
    },
  }
}))(TextField);


export const StyledBox = styled(Box)(({ theme }: any) => ({
  [theme.breakpoints.down("sm")]: {
    padding: "2.5rem 3rem 0rem 3rem !important",
  },
  [theme.breakpoints.down("xs")]: {
    padding: "2rem !important",
  },
}));

interface TextfieldProps {
  languageSelected: string;
}

export const StyledTextFieldsAutoComplete = withStyles((theme: Theme) => ({
  root: {
    "& .MuiInputBase-root": {
      display: "flex",
      justifyContent: (props: TextfieldProps) => props.languageSelected === "ar" ? "center" : "flex-start",
      height: "55px",
      fontSize: "1.1667rem",
      borderRadius: (props: TextfieldProps) => props.languageSelected !== "ar" ? "7px 0 0 7px" : "0 7px 7px 0",
      color: CONSTANTS.color.lightBrown,
      [theme.breakpoints.down("xs")]: {
        fontSize: "18px !important"
      },
    },
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
      minWidth: "90px",
      padding: "8.5px 16px !important",
    },
    "& .MuiOutlinedInput-input":{
      cursor: "pointer",
      padding:"15px 12px",
    },
    "& .MuiSvgIcon-root":{
      fontSize: "2.1rem",
      marginTop: "-5px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
      borderColor: CONSTANTS.color.thickBlue,
      borderWidth: "2px"
    },
    "& .MuiAutocomplete-clearIndicatorDirty": {
      display: "none"
    },
  }
}))(TextField);
// Customizable Area End

export class CreateSupplier extends CreateSupplierController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const getConditionalStyle = (isError: any) => {
      return isError ? webStyle.errorStyle : {};
    };
    const {
      supplierName,
      contactName,
      supplierOf,
      mobile,
      email,
      address,
      city,
      state,
      companyInformation,
      zip,
      currency
    } = this.state.formData;
    const { t } = this.props;
    const renderErrorMessage = (errorMessage: string) => {
      return (
        <Typography style={webStyle.errorMessage} variant="body2">
          {errorMessage}
        </Typography>
      );
    };

    const { classes }: any = this.props;
    const { languageSelected } = this.state;
    return (
      <>
        <Box className={classes?.suppliersDataContainer}>
          <Typography variant="subtitle1" className={classes?.headerText}>
            {t('suppliers.personalInformation')}
          </Typography>
          <StyledBox className={languageSelected !=="ar" ? `${classes?.formData}` : `${classes?.formDataAr}`}>
            <Grid item lg={9} md={12} xs={12}>
              <StyledGrid container spacing={10} className={classes?.styledGridContainer}>
                <Grid item lg={6} md={6} xs={12}>
                  <Typography
                    className={classes?.contactForm}
                    variant="body1"
                    gutterBottom
                    color="textPrimary"
                  >
                    {t('suppliers.supplierName')}<span style={webStyle.redColor}>{configJSON.startSymbol}</span>
                  </Typography>
                  <StyledTextFields
                    data-test-id="formData"
                    style={getConditionalStyle(this.state.isErrorFormData.supplierName)}
                    fullWidth
                    variant="outlined"
                    value={supplierName}
                    name="supplierName"
                    onChange={this.handleChangeFormData}
                    error={Boolean(this.state.isErrorFormData.supplierName)}
                  />
                  {renderErrorMessage(this.state.isErrorFormData.supplierName)}
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <Typography
                    className={classes?.contactForm}
                    variant="body1"
                    gutterBottom
                    color="textPrimary"
                  >
                    {t('suppliers.contactName')}
                  </Typography>
                  <StyledTextFields
                    data-test-id="formData"
                    style={getConditionalStyle(this.state.isErrorFormData.contactName)}
                    fullWidth
                    variant="outlined"
                    name="contactName"
                    value={contactName}
                    onChange={this.handleChangeFormData}
                    error={Boolean(this.state.isErrorFormData.contactName)}
                  />
                  {renderErrorMessage(this.state.isErrorFormData.contactName)}
                </Grid>
              </StyledGrid>
            </Grid>
            
            <Grid
              item
              lg={9}
              md={12}
              xs={12}
            >
              <StyledGrid
                container
                spacing={10}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <Typography
                    variant="body1"
                    className={classes?.contactForm}
                    color="textPrimary"
                    gutterBottom
                  >{t('suppliers.supplierOf')}</Typography>
                  <StyledTextFields
                    data-test-id="formData"
                    style={getConditionalStyle(this.state.isErrorFormData.supplierOf)}
                    fullWidth
                    variant="outlined"
                    value={supplierOf}
                    name="supplierOf"
                    onChange={this.handleChangeFormData}
                    error={Boolean(this.state.isErrorFormData.supplierOf)}
                  />
                  {renderErrorMessage(this.state.isErrorFormData.supplierOf)}
                </Grid>
              </StyledGrid>
            </Grid>
          </StyledBox>
          <Grid item lg={9} md={12} xs={12}><div className={classes?.hr}></div></Grid>
          <Typography className={classes?.headerText} variant="subtitle1">{t('suppliers.contactInformation')}</Typography>
          <StyledBox className={languageSelected !=="ar" ? `${classes?.formData}` : `${classes?.formDataAr}`}>
            <Grid item lg={9} md={12} xs={12}>
              <StyledGrid container spacing={10}>
                <Grid item lg={6} md={6} xs={12}>
                  <Typography
                    className={classes?.contactForm}
                    color="textPrimary"
                    gutterBottom
                    variant="body1"
                  >
                    {t('suppliers.mobileNumber')}
                  </Typography>
                  <Box style={webStyle.phoneContainer}>
                    <Autocomplete
                      id="country-select-demo"
                      style={webStyle.autocompleteStyle}
                      classes={{option: classes?.customOption}}
                      options={countryCodes}
                      value={this.state.countryCode}
                      onChange={this.handleChangeCountryCode}
                      autoHighlight
                      renderInput={(params: any) => (
                        <StyledTextFieldsAutoComplete
                          {...params}
                          fullWidth
                          variant="outlined"
                          languageSelected={this.state.languageSelected}
                        >
                        </StyledTextFieldsAutoComplete>
                      )}
                    />
                    <InputBase
                      data-test-id="formData"
                      style={getConditionalStyle(this.state.isErrorFormData.mobile)}
                      className={
                        languageSelected === "ar" ?
                        `${classes?.inputBase} ${classes?.inputBaseAr}` :
                        classes?.inputBase
                      }
                      name="mobile"
                      value={mobile}
                      onChange={this.handleChangeFormData}
                      error={Boolean(this.state.isErrorFormData.mobile)}
                    />
                  </Box>
                  {renderErrorMessage(this.state.isErrorFormData.mobile)}
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <Typography
                    className={classes?.contactForm}
                    variant="body1"
                    gutterBottom
                    color="textPrimary"
                  >
                    {t('suppliers.emailAddress')}
                  </Typography>
                  <StyledTextFields
                    data-test-id="formData"
                    style={getConditionalStyle(this.state.isErrorFormData.email)}
                    fullWidth
                    variant="outlined"
                    name="email"
                    value={email}
                    onChange={this.handleChangeFormData}
                    error={Boolean(this.state.isErrorFormData.email)}
                  />
                  {renderErrorMessage(this.state.isErrorFormData.email)}
                </Grid>
              </StyledGrid>
            </Grid>
          </StyledBox>
          <Grid item lg={9} md={12} xs={12}><div className={classes?.hr}></div></Grid>
          <Typography className={classes?.headerText} variant="subtitle1">{t('suppliers.companyInformation')}</Typography>
          <StyledBox className={languageSelected !=="ar" ? `${classes?.formData}` : `${classes?.formDataAr}`}>
            <Grid item lg={9} md={12} xs={12}>
            <StyledGrid container spacing={10}>
              <Grid item xs={12}>
          <Typography
            className={classes?.contactForm}
            color="textPrimary"
            gutterBottom
            variant="body1"
            dangerouslySetInnerHTML={{ __html: t('suppliers.supplierof') }}
          >     
          </Typography>
          <StyledTextFields
            data-test-id="formData"
            style={getConditionalStyle(this.state.isErrorFormData.address)}
            variant="outlined"
            fullWidth
            name="companyInformation"
            value={companyInformation}
            onChange={this.handleChangeFormData}
            error={Boolean(this.state.isErrorFormData.address)}
          />
          </Grid>
            </StyledGrid>
            
          </Grid>
          </StyledBox>

          <Grid item lg={9} md={12} xs={12}><div className={classes?.hr}></div></Grid>
          <Typography className={classes?.headerText} variant="subtitle1">{t('suppliers.address')}</Typography>
          <StyledBox style={languageSelected !== "ar" ? webStyle.addressContainer : webStyle.addressContainerAr}>
            <Grid item lg={9} md={12} xs={12}>
              <StyledGrid container spacing={10}>
                <Grid item lg={12} md={12} xs={12}>
                  <Typography
                    className={classes?.contactForm}
                    variant="body1"
                    gutterBottom
                    color="textPrimary"
                  >{t('suppliers.address')}</Typography>
                  <StyledTextFields
                    data-test-id="formData"
                    style={getConditionalStyle(this.state.isErrorFormData.address)}
                    variant="outlined"
                    fullWidth
                    name="address"
                    value={address}
                    onChange={this.handleChangeFormData}
                    error={Boolean(this.state.isErrorFormData.address)}
                  />
                  {renderErrorMessage(this.state.isErrorFormData.address)}
                </Grid>
              </StyledGrid>
            </Grid>

            <Grid item lg={9} md={12} xs={12}>
              <StyledGrid container spacing={10}>
                <StyledGridItem item lg={6} md={6} xs={12}>
                  <Typography
                    variant="body1"
                    className={classes?.contactForm}
                    gutterBottom
                    color="textPrimary"
                  >{t('suppliers.city')}</Typography>
                  <StyledTextFields
                    data-test-id="formData"
                    style={getConditionalStyle(this.state.isErrorFormData.city)}
                    variant="outlined"
                    fullWidth
                    name="city"
                    value={city}
                    onChange={this.handleChangeFormData}
                    error={Boolean(this.state.isErrorFormData.city)}
                  />
                  {renderErrorMessage(this.state.isErrorFormData.city)}
                </StyledGridItem>
                <StyledGridItem2 item lg={6} md={6} xs={12}>
                  <Typography
                    variant="body1"
                    className={classes?.contactForm}
                    color="textPrimary"
                    gutterBottom
                  >
                    {t('suppliers.state')}
                  </Typography>
                  <StyledTextFields
                    data-test-id="formData"
                    style={getConditionalStyle(this.state.isErrorFormData.state)}
                    fullWidth
                    variant="outlined"
                    name="state"
                    value={state}
                    onChange={this.handleChangeFormData}
                    error={Boolean(this.state.isErrorFormData.state)}
                  />
                  {renderErrorMessage(this.state.isErrorFormData.state)}
                </StyledGridItem2>
              </StyledGrid>
            </Grid>
            <Grid item lg={9} md={12} xs={12}>
              <StyledGrid container spacing={10}>
                <StyledGridItem item lg={6} md={6} xs={12}>
                  <Typography
                    className={classes?.contactForm}
                    variant="body1"
                    gutterBottom
                    color="textPrimary"
                  >{t('suppliers.zipCode')}</Typography>
                  <StyledTextFields
                    data-test-id="formData"
                    style={getConditionalStyle(this.state.isErrorFormData.zip)}
                    variant="outlined"
                    fullWidth
                    name="zip"
                    value={zip}
                    onChange={this.handleChangeFormData}
                    error={Boolean(this.state.isErrorFormData.zip)}
                  />
                  {renderErrorMessage(this.state.isErrorFormData.zip)}
                </StyledGridItem>
                <StyledGridItem2 item lg={6} md={6} xs={12}>
                  <Typography
                    variant="body1"
                    className={classes?.contactForm}
                    gutterBottom
                    color="textPrimary"
                  >{t('suppliers.currency')}</Typography>
                   <StyledTextFields
                      inputProps={{ 'data-testid': 'handleChangeCurrency' }}
                      select
                      fullWidth
                      variant="outlined"
                      name="currency"
                      value={this.state.getCurrency}
                      onChange={this.handleChangeCurrency}
                    >
                      <StyledMenuItem value="Select Currency">{t('settings.selectCurrency')}</StyledMenuItem>
                        <StyledMenuItem value={this.state.getCurrency}>
                          {this.state.getCurrency}
                        </StyledMenuItem>
                        {this.state.getCurrencyData &&
                      Array.from(
                        new Set(this.state.getCurrencyData.map((item: Currency) => item.currency))
                      ).map((currency) => (
                        <StyledMenuItem key={currency} value={currency}>
                          {currency}
                        </StyledMenuItem>
                      ))}
                    </StyledTextFields>
                  {renderErrorMessage(this.state.isErrorFormData.currency)}
                </StyledGridItem2>
              </StyledGrid>
            </Grid>
          </StyledBox>
        </Box>
        
        <Box style={webStyle.buttonsContainer} className={classes?.buttonsContainer}>
          <Box className={classes?.buttonsInnerContainer}>
            <Button
              className={`${classes?.buttonMobile} ${classes.btnBoxCancel} ${classes?.buttonStyleCommon}`}
              onClick={this.handleCancelButton}
            >
              <Typography
                style={webStyle.btnCancelText}
                className={classes?.buttonTextMobile}
                variant="subtitle2"
              >
                {t('suppliers.cancel')}
              </Typography>
            </Button>
          </Box>
          
          <Button
            className={`${classes?.buttonMobile} ${classes.buttonSaveSuppliers} ${classes?.buttonStyleCommon}`}
            onClick={this.handleSaveButton}
          >
            <Typography
              style={webStyle.btnSaveText}
              className={classes?.buttonTextMobile}
              variant="subtitle2"
            >
              {t('suppliers.save')}
            </Typography>
          </Button>
        </Box>
        <LoadingBackdrop
          loading={this.state.createSuppliersLoading}
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  
});

const webStyle = {
  addressContainer: {
    padding: "1.5rem 1rem 5rem 3rem"
  },
  addressContainerAr: {
    padding: "1.5rem 3rem 5rem 1rem"
  },
  buttonsContainer: {
    padding: "3rem 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  btnCancelText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textAlign: "center" as const,
  },
  btnSaveText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
  },
  errorMessage: {
    marginTop: "5px",
    color: CONSTANTS.color.red,
  },
  phoneContainer: {
    width: "100%",
    borderRadius: "8px",
    border: `1px solid ${CONSTANTS.color.paleCement}`,
    display: "flex",
    minHeight: "56px"
  },
  autocompleteStyle: {
    width: "34%"
  },
  countryCodeContainer: {
    display: "flex",
    borderRadius: "10px 0 0 10px",
    maxWidth: "88px",
    width: "100%",
  },
  redColor: {
    color: CONSTANTS.color.red,
  },
  errorStyle: {
    backgroundColor: CONSTANTS.color.lightRed,
    borderRadius: "10px"
  }
}

const createSupplierStyles: any = withStyles(styles)(CreateSupplier);
export default withTranslation()(withRouter(createSupplierStyles));
// Customizable Area End