//@ts-nocheck
import React from "react";
import {
    // Customizable Area Start
    Grid, Box, Container,Divider, Button, Menu, MenuItem,FormHelperText, ListItemIcon, TextField, IconButton, Typography , Hidden
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
// Customizable Area End
import InvoiceController, { Props } from "./InvoiceController.web";
// Customizable Area Start
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { withSnackbar } from "../../../components/src/toastweb";
import { CONSTANTS } from '../../../components/src/theme' 
import moment from "moment";
import DeleteModel from "./DeleteModal.web"; 
import { GoogleMap, Marker } from "@react-google-maps/api"; 
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { withStyles ,Theme} from "@material-ui/core/styles";
import CreationProcess from "../../RequestManagement/src/CreationProcess.web";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { StyledRoot } from "../../dashboard/src/utility.web";
import InternalNotes from "./InternalNotes.web";
import CustomerModal from "../../RequestManagement/src/CustomerModal.web";
import CustomerDetails from "../../RequestManagement/src/CustomerDetails.web";
import SiteModalWeb from "../../RequestManagement/src/SiteModal.web";
import { collectionPayment, mail, pdf,badDebt } from "../../InvoiceBilling/src/assets"
import ClearIcon from '@material-ui/icons/Clear'; 
import withCommonStyles from "../../../components/src/withCommonStyles.web";
import { withRouter } from "react-router"
import CollectedPaymentModal from "./CollectedPaymentModal.web";
import PriceSectionWeb from "../../QuoteManagement/src/PriceSection.web";
import ProductServices from "../../QuoteManagement/src/ProductService.web";
import EmailModal from "./EmailModal.web";
import InvoicePdf from "../../saveaspdf/src/InvoicePdf.web";
import { SelectCustomerButton } from "../../../components/src/CustomizableComponents.web";
import CustomDatePicker from "../../../components/src/DatePicker";
import JobReference from "../../../components/src/JobReference.web";
export const configJSON = require("./config");
import {backIconMobile} from "../../CustomisableUserProfiles/src/assets"
import { StyledTextFieldQuote } from "../../RequestManagement/src/Utility.web"
import { dropDownArrow } from "../../ProjectReporting/src/assets";
import { t } from "i18next";
import { withTranslation } from "react-i18next";
const selectedLanguage =  localStorage.getItem("lang");
function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const StyledMenu = withStyles({
    paper: {
        borderRadius: "5px",
        background: CONSTANTS.color.white,
        boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
        marginLeft: selectedLanguage === 'en' ? "-15px" : "",
        marginTop: "-129px",
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));
 const StyledTextFieldRequest = withStyles((theme:Theme)=>({
    root: {
      "& .MuiInputBase-root": {
        borderRadius: "8px",
        fontFamily: "Rubik",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(0, 0, 0, 0.23)",
        borderWidth: "2px"
      },
      "& .MuiOutlinedInput-root:hover": {
        borderColor: "rgba(0, 0, 0, 0.23)",
  
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "white"
      },
      "& .MuiSvgIcon-root": {
        fontSize: "25px"
      },
      [theme.breakpoints.down("xl")]: {
        width: "100% !important"
      }
    }
  }))(TextField);
// Customizable Area End

export class Invoice extends InvoiceController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    customerSelected({classes}){
        return<>
            {this.state.isCustomerSelected ?
                <Grid container justifyContent="space-between">
                    <Grid item xs={12}>
                        <CustomerDetails
                            modeType={`${t('invoice.invoiceFor')}`}
                            
                            crudStatus={!this.state.isEditable?'edit':"create"}
                            details={this.state.selectedCustomerDetails?.attributes}
                            siteDetails={this.state.siteDetails}
                            customerSelectedStatus={this.getCustomerDailogStatus1}
                            dailogOpen={this.handleOpenDialog}
                            clearCustomer={this.clearCustomerSite}
                            converted={false}
                            t={this.props.t}
                        />
                    </Grid>
                </Grid>
                :
                <SelectCustomerButton
                    variant="contained"
                    onClick={this.handleOpenDialog}
                >
                    {t('invoice.button.selectCus')}
                </SelectCustomerButton>
            }
            <FormHelperText id="standard-weight-helper-text" className={`${classes.errorText} ${classes.display}`}>{this.state.siteDetails?.customer_id ?'':this.state.isErrorFormData.customer_id}</FormHelperText>                  
        </>
    }
    editTitle({ classes }) {
        return <Grid item xs={12} md={8}>
            <>
                <Grid item xs={12} className={classes.gutterBottom3}>
                    <Typography variant="subtitle1" className={`${classes.fontW600} ${classes.lightBrown} ${classes.mb_1}`}>{t('invoice.jobReference')}
                        <span className={`${classes.errorText} ${classes.font_20}`}>*</span>
                    </Typography>
                    {this.state.isSelectedJob === true ?
                        <StyledTextFieldRequest
                            variant="outlined"
                            style={{ marginBottom: "20px" }}
                            onClick={this.handleSelectJob}
                            onChange={this.handleViewJob}
                            value={this.state.selectedJob}
                            className={this.state.isEditable && classes.jobEditText}
                            disabled={this.state.isEditable}
                        /> :
                        <SelectCustomerButton
                            variant="contained"
                            onClick={this.handleSelectJob}
                            data-testid="openJobPopup"
                        >
                            {t('invoice.button.selectJob')}
                        </SelectCustomerButton>
                    }
                    <FormHelperText id="standard-weight-helper-text" className={`${classes.errorText} ${classes.display}`}>{this.state.JobId?'':this.state.isErrorFormData.selectedJob}</FormHelperText>

                </Grid>
                <Grid item xs={12}>
                    <Typography color="textPrimary" className={classes.subTitle20}>{t('invoice.title')}
                        <span className={classes.errorText}>*</span>
                    </Typography>
                </Grid>
                <StyledTextFieldRequest
                    variant="outlined"
                    style={{ marginBottom: "20px" }}
                    onChange={this.handleInvoiceChange}
                    onBlur={this.handleOnBlur}
                    name={"title"}
                    value={this.state.invoiceForm.title}
                    disabled={!this.state.isEditable}
                />
                <FormHelperText id="standard-weight-helper-text" className={`${classes.errorText} ${classes.display}`}>{this.state.invoiceForm.title ==='' || (this.state.invoiceForm.title && this.state.invoiceForm.title.length > 40) ? this.state.isErrorFormData.title : ''}</FormHelperText>
            </>
        </Grid>
    }
   
    invoiceLocation({ webStyle ,classes}) {
        return <>
            {this.state.isCustomerSelected &&
                <Grid container className={classes.marginTop_54} direction="row" justifyContent="flex-start" spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" className={`${classes.fontW600} ${classes.lightBrown}`}>{t('invoice.location')}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.mb_1}>
                        <GoogleMap
                            mapContainerStyle={webStyle.CreateCustomerMapContainerStyle}
                            center={this.state.getCustomerPlace}
                            zoom={5}
                            data-test-id="mapComponent"
                            onLoad={(map) => {
                                this.onMapLoad(map);
                            }}
                            options={{
                                disableDefaultUI: false,
                                streetViewControl: false,
                                mapTypeControl: false,
                                fullscreenControl: false,
                                zoomControl:false
                              }}
                        >
                            {this.state.getCustomerPlace && (
                                <Marker position={this.state.getCustomerPlace} />
                            )}
                        </GoogleMap>
                    </Grid>
                </Grid>
            }
        </>
    }
    issueDate({classes}){
        return <Grid item xs={12} sm={6} className={classes.issue_datePicker}>

            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="body1"
                        className={`${classes.lightGrey} ${classes.mb_1}`}
                    >
                        {t('invoice.issueDate')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <CustomDatePicker
                        disabled={!this.state.isEditable}
                       
                        selectedDate={this.state.invoiceForm.issue_date}
                        dateChange={this.handleIssueDate}
                    />
                </Grid>
            </Grid>
        </Grid> 
    }
    paymentDue({ classes}) {
        return <Grid item xs={12} sm={6} className={classes.issue_datePicker}>
            <Grid container>
                <Typography variant="body1"
                    className={`${classes.lightGrey} ${classes.mb_1}`}
                >
                    {t('invoice.paymentDue')}
                </Typography>
            </Grid>
            <CustomDatePicker
                disabled={!this.state.isEditable}
                minDate={this.state.invoiceForm.issue_date}
                selectedDate={this.state.invoiceForm.payment_due}
                dateChange={this.handlePaymentDueChange}


            />
        </Grid>
    }
    cancelButton({ classes }) {
        return <Grid container direction="row"  className={classes.justifyContent_start}>
                {this.state.SaveInvoiceStatus && !this.state.EditInvoiceStatus &&
                    <Button variant="outlined" className={`${classes.outlinedCancelButton24} ${classes.btnWidth} ${classes.cancelBtn} ${classes.cancelBtnV1}`}
                        onClick={this.handleCancel}>
                        <Typography variant="subtitle2" className={classes.fontW_400}>
                            {t('invoice.button.cancel')}
                        </Typography>
                    </Button>
                    } 
                {this.state.UpdateInvoiceStatus &&
                    <Button variant="outlined" className={`${classes.outlinedDeleteButton24} ${classes.btnWidth} ${classes.deleteBtn}`} onClick={this.handleArchivedDialogue}>
                        <Typography variant="subtitle2" className={`${classes.lineHeight} ${classes.fontW_400}`}>
                            {t('invoice.button.cancelInvoice')}
                        </Typography>
                    </Button>
                }
            </Grid>
    }
    collectedPaymentModal() {
        return <>
            {this.state.collectedPayment &&
                <CollectedPaymentModal 
                invoicePayment={this.invoicePayment}
                handleCollectionPaymentSave={this.handleCollectionPaymentSave}
                handleChange={this.handleChange}
                handleTransactionDate={this.handleTransactionDate}
                calculateTotal={this.calculateTotal}
                onClose={this.handlePaymentModalClose}
                collectedPaymentForm={this.state.collectedPaymentForm}
                openNextModal={this.state.openNextModal}
                currency={this.state.currency}
                selectedLang={this.state.selectLan}
                handleChangePaymentMethodRef={this.handleChangePaymentMethodRef}
                handleSavePaymentAndEmailReceipt={this.handleSavePaymentAndEmailReceipt}
                handleCollectionPaymentModal={this.handleCollectionPaymentModal} 
                handleCollectionPaymentClose={this.handleCollectionPaymentClose} />
            }
        </>
    }
    invoicePdf() {
        return <>
            {
                this.state.nextModal === 'pdf' &&
                <InvoicePdf
                    type="Invoice"
                    onClose={this.handlePdfModalClose}
                    openPdf={this.handleOpenInvoicePdf}
                    closePdf={this.handleCloseInvoicePdf}
                    invoicePdfId={this.state.createdInvoiceData?.id}
                />
            }
        </>
    }
    buttonMenu(classes) {
        return <>
            <StyledMenu
                id="customized-menu"
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleButtonClose}
            >
                <MenuItem>
                    <div className={classes.saveAndBtn}>
                        <Typography variant="subtitle2" className={`${classes.lightBrown} ${classes.fontW_400}`}>{t('invoice.button.updateAnd')}</Typography>
                        <div>
                            <IconButton onClick={this.handleButtonClose}>
                                <ClearIcon />
                            </IconButton>
                        </div>
                    </div>
                </MenuItem>
                <MenuItem>
                    <ListItemIcon style={webStyle.width_35}>
                        <img src={mail} width="20px" height="20px" />
                    </ListItemIcon>
                    <Typography variant="subtitle2" className={`${classes.lightBrown} ${classes.fontW_400} ${classes.submitModal}`} onClick={this.handleEmailSendOpenDialog}>{t('invoice.menuLabel.Email')}</Typography>
                </MenuItem>
                <MenuItem>
                    <ListItemIcon style={webStyle.width_35}>
                        <img src={collectionPayment} width="20px" height="20px" />
                    </ListItemIcon>
                    <Typography variant="subtitle2" className={`${classes.lightBrown} ${classes.fontW_400} ${classes.submitModal}`} onClick={this.handleCollectionPaymentModal}>{t('invoice.menuLabel.collectedPayment')}</Typography>
                </MenuItem>
                <MenuItem>
                    <ListItemIcon style={webStyle.width_35}>
                        <img src={pdf} width="20px" height="20px" />
                    </ListItemIcon>
                    <Typography variant="subtitle2" className={`${classes.lightBrown} ${classes.fontW_400} ${classes.submitModal}`} onClick={this.handleOpenInvoicePdf}>{t('invoice.menuLabel.pdf')}</Typography>
                </MenuItem>
                {this.state.SaveInvoiceStatus &&
                    <MenuItem>
                        <ListItemIcon style={webStyle.width_35}>
                            <img src={badDebt} width="20px" height="20px" />
                        </ListItemIcon>
                        <Typography data-testid='badDebt' variant="subtitle2" className={`${classes.lightBrown} ${classes.fontW_400} ${classes.submitModal}`} onClick={this.handleBadDept}>{t('invoice.menuLabel.badDebt1')}</Typography>
                    </MenuItem>
                }
                {this.emailModal()}
                {this.collectedPaymentModal()}
                {this.invoicePdf()}
            </StyledMenu>
        </>
    }
    updateInvoiceStatus({ classes }) {
        return <>
            {this.state.UpdateInvoiceStatus &&
                <>
                    <Button
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        variant="contained"
                        color="primary"
                        onClick={this.handleButton}
                        className={`${classes.containedButton24} ${classes.btnWidth} ${classes.buttonSize}`}
                    >
                        <Typography variant="subtitle2" className={`${classes.lineHeight} ${classes.fontW_400}`}>
                            {t('invoice.button.updateAnd')}
                        </Typography>
                        <img style={this.state.anchorEl ?
                            { ...webStyle.dropDownArrow,
                                transform: "rotate(180deg)"} :
                            webStyle.dropDownArrow}
                            src={dropDownArrow} 
                            alt=""
                        />
                    </Button>
                    {this.buttonMenu(classes)}
                </>
            }
        </>
    }
    editInvoiceStatus({classes}){
        return<>
        {this.state.EditInvoiceStatus && 
            <>
                <Button
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="contained"
                    color="primary"
                    onClick={this.handleButton}
                    className={`${classes.containedButton24} ${classes.btnWidth} ${classes.buttonSize}`}
                >
                    <Typography variant="subtitle2" className={` ${classes.fontW_400} ${classes.lineHeight}`}>{t('invoice.button.moreAction')}</Typography>
                    <img style={this.state.anchorEl ?
                        {
                            ...webStyle.dropDownArrow,
                            transform: "rotate(180deg)"
                        } :
                        webStyle.dropDownArrow
                    }
                        src={dropDownArrow} alt=""
                    />
                </Button> 
                {this.buttonMenu(classes)}
            </>
        }                    
        </>
    }
    emailModal(){
        return<>
            {this.state.quoteSendEmail &&
                <EmailModal
                    open={this.state.quoteSendEmail}
                    EmailData={this.state.EmailData}
                    handleClose={this.handleEmailClose}
                    onClose={this.handleEmailModalClose}
                    handleEmailFileChange={this.handleEmailDocument}
                    handleEmailChange={this.handleEmailChange}
                    isEditable={this.state.isEditable}
                    createdInvoiceData={this.state.createdInvoiceData}
                    customerDetails={this.state.selectedCustomerDetails}
                    extensionIconsEmail={this.state.extensionIconsEmail}
                    progress={this.state.emailProgress}
                    files={[this.state.EmailData.attachments]}
                    clientAttachments={this.state.clientAttachments}
                    handleRemoveFile={this.handleRemoveEmailFile}
                    completed={this.state.emailCompleted}
                    handleDownloadFile={this.handleDownloadFile}
                    languageSelected={this.state.selectLan}
                />          
            }
        </>
    }
    customerSelectBtn({classes}){
        return<>
          <Grid container direction="row" className={classes.justifyContent}>
                  <Button variant="outlined"
                      onClick={this.handleSaveInvoice}
                      data-testid='saveInvoice'
                      className={`${classes.outlinedEditButton24} ${classes.btnWidth} ${classes.outlinedSaveBtn}`}
                  >
                      <Typography variant="subtitle2" className={`${classes.lineHeight} ${classes.fontW_400}`}>{t('invoice.button.saveInvoice')}</Typography>
                  </Button>
                  <Button
                      aria-controls="customized-menu"
                      aria-haspopup="true"
                      variant="contained"
                      color="primary"
                      onClick={this.handleButton}
                      data-testid='saveAndButton'
                      className={`${classes.containedButton24} ${classes.btnWidth} ${classes.buttonSize}`}
                  >
                      <Typography variant="subtitle2" className={`${classes.lineHeight} ${classes.fontW_400}`}>{t('invoice.button.saveAnd')}</Typography>
                      <img style={this.state.anchorEl ? {...webStyle.dropDownArrow, transform: "rotate(180deg)"} : webStyle.dropDownArrow} src={dropDownArrow} alt="" />

                  </Button>
                  {this.buttonMenu(classes)}
          </Grid>
        </>
    }
    beforeSaveInvoice({ classes }) {
        return <>
            {
                <Grid container direction="row"  className={classes.marginTopBottom}>
                    <Grid item className={classes.btn_hide_smallScreen} sm={5} md={6}>
                        <Button variant="outlined" onClick={this.Invoices} className={`${classes.outlinedCancelButton24} ${classes.btnWidth} ${classes.cancelBtn}`}>
                            <Typography variant="subtitle2" className={classes.fontW_400}>{t('invoice.button.cancel')}</Typography>
                        </Button>
                    </Grid>
                    
                    <Grid item xs={12} sm={7} md={6}>
                        <Grid container direction="row">
                            <Grid item container justifyContent="flex-end" className={classes.smLayout}>
                                {this.customerSelectBtn({ classes })}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.btn_hide_largeScreen} style={{marginTop:'8px'}}>
                        <Button variant="outlined" onClick={this.Invoices} className={`${classes.outlinedCancelButton24} ${classes.btnWidth} ${classes.cancelBtn}`}>
                            <Typography  className={classes.fontW_400}>{t('invoice.button.cancel')}</Typography>
                        </Button>
                    </Grid>
                </Grid>
            }
        </>
    }
    afterSaveInvoice({ classes }) {
        return <>
            {
                (<Grid container className={classes.marginTopBottom}>
                    <Grid item sm={6} xs={12} className={classes.btn_hide_smallScreen}>
                    {this.cancelButton({ classes })}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container direction="row" className={classes.justifyContent}>
                            {this.state.EditInvoiceStatus && <Button variant="outlined" className={`${classes.outlinedEditButton24} ${classes.btnWidth} ${classes.updateBtn}`}
                                onClick={this.handleEditInvoice}
                            ><Typography variant="subtitle2" className={classes.fontW_400}>{t('invoice.button.edit')}</Typography></Button>}
                            {this.state.UpdateInvoiceStatus &&
                                <Button variant="outlined"
                                    className={`${classes.outlinedEditButton24} ${classes.btnWidth} ${classes.updateBtn}`}
                                    onClick={this.handleUpdateInvoice}>
                                    <Typography variant='subtitle2' className={` ${classes.fontW_400} ${classes.lineHeight}`}>{t('invoice.button.updateInvoice')}</Typography></Button>}
                            {this.editInvoiceStatus({ classes })}
                            {this.updateInvoiceStatus({ classes })}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.btn_hide_largeScreen}>
                    {this.cancelButton({ classes })}
                    </Grid>
                </Grid>)
            }
        </>
    }
    customerModal() {
        return <>
            {
                this.state.dialogOpen &&
                <CustomerModal
                    open={this.state.dialogOpen}
                    saveMapData={this.saveSiteMapData}
                    getCustomerDailogStatus={this.getCustomerDailogStatus}
                    getCustomerDetails={this.getEditCustomerDetails}
                    data-test-id="searchCustomer"
                    getCustomerSelectedStatus={this.getCustomerSelectedStatus}
                    selectedCustomerButtonStatus={this.getCustomerDailogStatus1}
                    getSiteDetails={this.getSiteDetails}
                    customerDetails={this.state.selectedCustomerDetails?.attributes}
                    siteDetails={this.state.siteDetails}
                    t={this.props.t}
                    handleCusValidation={this.handleCusValidation}
                />
            }
        </>
    }
    siteModal() {
        return <>
            {
                this.state.siteDailog &&
                <SiteModalWeb
                    siteDailog={this.state.siteDailog}
                    customerSites={this.state.customerSitesList}
                    handleSiteClose={this.handleSiteClose}
                    searchSites={this.searchSites}
                    selectedSiteCustomer={this.selectedSiteCustomer}
                    t={this.props.t}
                />
            }
        </>
    }
    selectJobPopup() {
        return <>
            {
                this.state.openSelectJob &&
                <JobReference
                    open={this.handleSelectJob}
                    close={this.handleCloseSelectJob}
                    statusModal={this.state.statusModal}
                    checkedItems={this.state.checkedItems}
                    handleStatusJobModalOpen={this.handleStatusJobModalOpen}
                    handleStatusJobModalClose={this.handleStatusJobModalClose}
                    handleSelectedStatus={this.handleSelectStatus}
                    selectedDate={this.state.selectedDate}
                    status={this.state.dateStatus}
                    dateModal={this.state.dateModal}
                    sortDirection={this.state.sortDirection}
                    sortColumn={this.state.sortColumn}
                    datePickerRef={this.datePickerRef}
                    languageSelected={this.state.selectLan}
                    searchJobs={this.state.searchJobs}
                    handleDateChange={this.handleDateChanges}
                    handleSelectStatus={this.handleSelectDateStatus}
                    handleDateModalOpen={this.handleDateModalOpen}
                    handleStatusModalClose={this.handleStatusModalClose}
                    handleSort={this.handleSort}
                    handleSearchJobs={this.handleSearchJobs}
                    handleViewJob={this.handleViewJob}
                    allJobsData={this.state.allJobsData}
                    loading={this.state.invoicePdfLoading}
                    t={this.props.t}
                />
            }
        </>
    }
    deleteModal() {
        return <>
            {
                this.state.openDeleteDailog &&
                <DeleteModel
                    deleteType={`${t('invoice.cancelInvoice')}`}
                    subType={`${t('invoice.cancelInvoiceDes')}`}
                    handleArchivedDialogue={this.handleArchivedDialogue}
                    handleCloseDeleteDialog={this.handleCloseDeleteDialog}
                    deleteAPI={this.deleteInvoice}
                    selectLan={this.state.selectLan}
                />
            }
        </>
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props
        const { dialogOpen } = this.state
        const curDate = moment(new Date()).format("YYYY-MM-DD").toString();
        
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <DashboardSideBar history={undefined} {...this.props} navMenuItem={`${t('invoice.header')}`} active={6} active1={false} active2={false} />
                <StyledRoot languageSelected={this.state.selectLan}>
                    <Box sx={{ marginTop: "8rem" }} >
                        <Container maxWidth={false}>
                            <Grid item xs={12} style={{ marginBottom: "25px" }}>
                                <CreationProcess active={3} t={this.props.t} selectLanguage={this.state.selectLan}/>
                            </Grid>
                        </Container>
                    </Box>
                    <Grid item xs={12} className={classes.mainDiv}>
                        <Grid container direction="row" spacing={3}>
                           
                            <Grid item xs={12} sm={12} md={8} className={classes.mb_2}>
                                <Grid item  className={classes.backIconContainer}>
                                    <Hidden smUp>
                                        <img width={"12px"} height={"24px"} data-test-id="handleBack" onClick={this.handleBackIconV1} src={backIconMobile} alt="" />
                                    </Hidden>
                                </Grid>

                                {this.editTitle({ classes })}
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={3}>
                            <Grid item xs={12} sm={12} md={8}>
                                {this.customerSelected({ classes })}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                {this.invoiceLocation({ webStyle, classes })}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} className={classes.mt_2}>
                            <Typography variant="subtitle1" className={`${classes.fontW600} ${classes.lightBrown}`}>{t('invoice.invoiceDetails')}</Typography>
                            <Grid container style={{ marginBottom: "20px" }} spacing={2}>
                                {this.issueDate({ classes, curDate })}
                                {this.paymentDue({ classes, curDate })}
                            </Grid>
                        </Grid>
                        <Grid>
                            <LoadingBackdrop
                                loading={this.state.getInvoiceLoading}
                            />
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} md={9}>
                                <ProductServices
                                    productServices={this.state.productServices}
                                    productData={this.state.productData}
                                    handleProductChange={this.handleChangeInput}
                                    handleProductDocument={this.handleProductDocument}
                                    addField={this.addField}
                                    handleChangeSearchInput={this.handleChangeSearchInput}
                                    removeField={this.removeField}
                                    completed={this.state.completed}
                                    files={this.state.files}
                                    history={this.props.history}
                                    progress={this.state.progress}
                                    handleRemoveFile={this.handleRemoveFile}
                                    serviceData={this.state.serviceData}
                                    handleChangeProductFiles={this.handleChangeProductFiles}
                                    value={this.state.productServices}
                                    isEditable={this.state.isEditable}
                                    isErrorFormData={this.state.isErrorFormData}
                                    currency={this.state.currency}
                                    productOnBlur={this.productOnBlur}
                                    languageSelected={this.state.selectLan}
                                /></Grid>
                        </Grid>
                        <Grid item 
                            xs={12} md={9}>
                            <Divider className={classes.mt_48}/>
                        </Grid>
                        <Grid container className={classes.mt_1}>
                            <Grid item xs={12} 
                                md={9}>
                                <Typography
                                    className=
                                    {`${classes.fontW600} ${classes.lightBrown} ${classes.headingText}`}
                                    variant="subtitle1"> {t('suppliers.price')}</Typography>
                                <PriceSectionWeb
                                    data={this.state.productServices}
                                    priceSectionData={this.state.priceSectionData}
                                    emptyPriceSectionData={this.emptyPriceSectionDataInvoice}
                                    selectTaxRate={this.selectTaxRateInvoice}
                                    handlePriceSection={this.handlePriceSection}
                                    isEditable={this.state.isEditable}
                                    calculateTotal={this.calculateTotal}
                                    getSubTotal={this.getSubTotal}
                                    invoicePayment={this.invoicePayment}
                                    handleCollectionPaymentSave={this.handleCollectionPaymentSave}
                                    handleChange={this.handleChange}
                                    handleTransactionDate={this.handleTransactionDate}
                                    onClose={this.handlePaymentModalClose}
                                    discountDialog={this.state.discountDialog}
                                    handleDiscountDialogOpen={this.handleDiscountDialogOpen}
                                    collectedPaymentForm={this.state.collectedPaymentForm}
                                    handleChangePaymentMethodRef={this.handleChangePaymentMethodRef}
                                    handleCollectionPaymentModal={this.handleCollectionPaymentModal}
                                    handleCollectionPaymentClose={this.handleCollectionPaymentClose}
                                    currency={this.state.currency}
                                    selectCurrency={this.selectCurrency}
                                    selectedLang={this.state.selectLan}
                                    selectedCurrency={this.state.selectCurrency}
                                    handlePay_balence={this.handlePay_balence}
                                    handleCollected={this.handleCollected}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.marginTop_20}>
                            <Grid item xs={12} md={9}>
                                <Typography className={`${classes.fontW600} ${classes.lightBrown} ${classes.headingText}`}
                                    variant="subtitle1"
                                >
                                    {t('invoice.invoiceNote')}
                                </Typography>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={`${classes.lightGrey} ${classes.mb_1}`}>
                                        {t('invoice.invoiceNoteLabel')}
                                    </Typography>
                                    <StyledTextFieldQuote
                                        variant="outlined"
                                        multiline
                                        minRows={5}
                                        maxRows={5}
                                        inputProps={{'data-testid':'invoiceNotes'}}
                                        className={classes.notesInput1}
                                        disabled={!this.state.isEditable}
                                        value={this.state.invoiceNote}
                                        onChange={this.handleChangeNotes}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.mt_2}>
                            <Grid item xs={12} md={9}>
                                <InternalNotes
                                    isEditable={this.state.isEditable}
                                    updateInternalNotes={this.updateInternalNotes}
                                    updateInternalAttachments={this.updateInternalAttachments}
                                    internalNotes={this.state.internalNotes}
                                    files={this.state.files}
                                    completed={this.state.completed}
                                    progress={this.state.progress}
                                    removeUploadAttachmentFile={this.removeUploadAttachmentFile}
                                    uploadAttachmentFile={this.uploadAttachmentFile}
                                    handleDownloadFile={this.handleDownloadFile}
                                    extensionIcons={this.state.extensionIcons}
                                    t={this.props.t}
                                    languageSelected={this.state.selectLan}
                                />
                            </Grid>
                        </Grid>
                        {this.state.SaveInvoiceStatus &&
                            this.afterSaveInvoice({ classes })
                        }
                        {!this.state.SaveInvoiceStatus && this.beforeSaveInvoice({ classes })}

                    </Grid>
                </StyledRoot>
                {this.customerModal({ dialogOpen })}
                {this.selectJobPopup()}
                {this.siteModal()}
                {this.deleteModal()}
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    CreateCustomerMapContainerStyle: {
        width: "100%",
        height: "320px", 
        borderRadius: "10px",
    },
    dropDownArrow: {
        width: "16px",
        height: "8px",
        marginLeft:"15px"
    },
    width_35:{
        minWidth:"35px"
    }
}
export const InvoiceStyles = (theme: any) => ({
    btn_hide_smallScreen: {
        display: 'flex',
        [theme.breakpoints?.down("xs")]: {
            display: 'none'
        }
    },
    jobEditText: {
        "& .MuiInputBase-input": {
            color: CONSTANTS.color.darkBlue,
            fontWeight:CONSTANTS.fontWeight.fontWeight500,
            textDecorationLine: "underline",
            cursor: "pointer"
        }
    },
    btn_hide_largeScreen: {
        display: 'flex',
        [theme.breakpoints?.between("sm", 'lg')]: {
            display: 'none'
        },
    },
    marginTopBottom: {
        marginTop: "4rem",
        marginBottom: "4rem",
        [theme.breakpoints?.down("xs")]: {
            marginTop: "0rem",
        }
    },
    notesInput1: {
        width: "100%",
        height: "130px",
        borderRadius: "8px",
    },
    closeButton: {
        color: CONSTANTS.color.lightGrey,
        position: 'absolute',
        width: "15px",
        height: "16px",
    },
    mainDiv: {
        padding: '10px 24px'
    },
    padding:{
        padding:'0px 7px'
    },
    
    issue_datePicker:{
        marginTop:'0px',
        [theme.breakpoints?.down("xs")]: {
            marginTop:'10px',
        },
    },
    mt_1:{
        marginTop:'10px'
    },
    mt_48:{
        marginTop:'48px'
    },
    mt_2:{
        marginTop:'20px',
        [theme.breakpoints?.down("xs")]: {
            marginTop: '0px'
        },
    },
    marginTop_20:{
        marginTop:"20px"
    },
    marginTop_54: {
        marginTop: '-54px',
        [theme.breakpoints?.down("sm")]: {
            marginTop: '-17px'
        },
    },
    mb_1:{
        marginBottom:'10px'
    },
    fontW_400: {
        fontWeight: CONSTANTS.fontWeight.fontWeight400,
      },
    lineHeight:{
        lineHeight:'initial'
    },
    lightBrown:{
        color:CONSTANTS.color.lightBrown
    },
    lightGrey:{
        color:CONSTANTS.color.lightGrey
    },
    fontW600:{
        fontWeight:CONSTANTS.fontWeight.fontWeight600
    },
    justifyContent:{
        justifyContent:'flex-end',
        [theme.breakpoints?.down("xs")]: {
            justifyContent:'space-between',
        }
    },
    justifyContent_start:{
        justifyContent:'start',
        [theme.breakpoints?.down("xs")]: {
            justifyContent:'space-between',
        }
    },
    locationImage: {
        borderRadius: "5px",
        width: "100%",
        [theme.breakpoints?.up("xl")]: {
            borderRadius: "5px",
            width: "367px",
            height: "336px"
        }
    }, 

    backIconContainer:{
        display:"flex",
        alignItems:"center",
        gap:"1rem",
        marginBottom:"10px"
    },
   
    invoiceNo: {
       
        textDecoration: 'underline'
    },
   
    errorText: {
        marginTop: "5px",
        color: CONSTANTS.color.crimsonRed,
      },
    display:{
        display:"flex"
    },  
    saveAndBtn: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center"
    },
    pb_1:{
        paddingBottom:"10px"
    },
    cancelBtn: {
        borderRadius: "10px",
        border: `1px solid ${CONSTANTS.color.lightGrey}`,
        color: CONSTANTS.color.lightGrey,
        textTransform: "capitalize",
        [theme.breakpoints?.down("xs")]: {
            marginTop:"10px",
            width:"100% !important"
        }
    },
    cancelBtnV1: {
       
        [theme.breakpoints?.down("xs")]: {
           
            width:"45% !important"
        }
    },
    buttonSize: {
        [theme.breakpoints?.down("xs")]: {
            marginTop:"10px",
            width:"100% !important"
        }
    },
    caret: {
        marginTop: "-15px",
        height: '40px',
        position: 'absolute',
        width: '20px',
        color: CONSTANTS.color.white,
        opacity: '1.0'
    },
   
    font_20: {
        fontSize: '20px'
    },
    btnWidth: {
        width: "230px",
        height: "67px",
        [theme.breakpoints?.down("xs")]: {
            width: "100%",
        },
        [theme.breakpoints?.between("sm", 'md')]: {
            width: "44%",
        },
    }, 
    outlinedSaveBtn: {
        marginRight: "0.5em",
        marginLeft:"0.5em",
        [theme.breakpoints?.down("xs")]: {
            marginTop:"12px",
            width:"100% !important",
            marginRight: "0em",
            marginLeft: "0em"
        },
    },
    deleteBtn: {
        marginLeft:"0.7em",
        marginRight:"0.7em",
        [theme.breakpoints?.down("xs")]: {
            marginLeft: "0em",
            marginRight: "0em",
            marginTop: "10px",
            width:"45% !important"
        }
    },
    updateBtn: {
        marginRight: "0.7em",
        marginLeft:"0.7em",
        [theme.breakpoints?.down("xs")]: {
            marginTop:"10px",
            width:"100% !important",
            marginRight: "0em",
            marginLeft: "0em"
        },
    },
    submitModal :{
        [theme.breakpoints?.down("xs")]: {
            fontSize:"1.03rem !important"
        },
    }
});

const InvoiceRouter = withRouter(Invoice)
const InvoiceStyle = withCommonStyles(InvoiceRouter)
const HOCStyle= withStyles(InvoiceStyles)(InvoiceStyle);
const LanguageComponent= withSnackbar(HOCStyle);
export default withTranslation()(LanguageComponent);
// Customizable Area End
