import React from "react";
import {
    Container,
    Box,
    // Customizable Area Start
    Theme, withStyles, Grid, Typography,  MenuItem, ListItemIcon, Paper, Drawer,Hidden
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import CustomTeamMember from "../../../components/src/CustomTeamMember.web"
import JobActionsController, { Props, configJSON } from "./JobActionsController.web";
import withCommonStyles from "../../../components/src/withCommonStyles.web";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { StyledRoot } from "../../dashboard/src/utility.web";
import CreationProcess from "../../RequestManagement/src/CreationProcess.web";
import { convertToQuote, rightArrow } from "../../RequestManagement/src/assets";
import CustomerDetails from "../../RequestManagement/src/CustomerDetails.web";
import CustomerModal from "../../RequestManagement/src/CustomerModal.web";
import DateTimeScheduling from "./DateTimeScheduling.web";
import JobsCalender from "./JobsCalender.web";
import InternalNotesAttachements from "../../RequestManagement/src/InternalNotesAttachements.web";
import ClearIcon from '@material-ui/icons/Clear';
import { downloadIcon, emailIcon, leftArrow } from "./assets";
import { badDebt } from "../../InvoiceBilling/src/assets"
import ProductServices from "../../QuoteManagement/src/ProductService.web";
import PriceSectionWeb from "../../QuoteManagement/src/PriceSection.web";
import EmailModal from "./EmailModal.web";
import JobPdf from "../../saveaspdf/src/JobPdf.web";
import { withSnackbar } from "../../../components/src/toastweb";
import ContentDrawer from "./ContentDrawer.web";
import { CancelButton, ContainedButton, CustomizedTextField, MoreActionStyledMenu, OutlinedButton, SelectCustomerButton } from "../../../components/src/CustomizableComponents.web";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import ErrorMessage from "../../../components/src/ErrorMessage.web";
import { backIconMobile } from "../../CustomisableUserProfiles/src/assets";
import withRequestStyles from "../../RequestManagement/src/RequestStyles.web"
import { goBack } from "../../../components/src/CommonFunction";
import { CustomGoogleMap } from "../../../components/src/CustomGoogleMap.web";
import { CONSTANTS } from "../../../components/src/theme"
import { dropDownArrow } from "../../ProjectReporting/src/assets";
import { withTranslation } from "react-i18next";
// Customizable Area End

export class CreateJob extends JobActionsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    manageConvertedJobCustomerDetails = (type: boolean,t:(key: string) => string) => {
        return (
            <>
                {type ?
                    <>
                        <CustomerDetails
                            modeType={t('jobs.jobFor')}
                            details={this.state.customerDetails.attributes}
                            customerSelectedStatus={this.selectedJobCustomerButtonStatus}
                            crudStatus={'create'}
                            siteDetails={this.state.siteDetails}
                            dailogOpen={this.handleOpenDialog}
                            clearCustomer={this.clearJobCustomer}
                            converted={true}
                            t={t}

                        />
                    </>
                    :
                    <>

                        {this.state.selctedCustomerJob ?
                            <>
                                <CustomerDetails
                                    modeType={t('jobs.jobFor')}
                                    details={this.state.customerDetails.attributes}
                                    customerSelectedStatus={this.selectedJobCustomerButtonStatus}
                                    crudStatus={'create'}
                                    siteDetails={this.state.siteDetails}
                                    dailogOpen={this.handleOpenDialog}
                                    clearCustomer={this.clearJobCustomer}
                                    t={t}
                                />
                            </> :
                            <SelectCustomerButton
                                data-test-id="customerSelect"
                                onClick={this.handleOpenDialog}>
                                {t('invoice.button.selectCus')}
                            </SelectCustomerButton>
                        }
                        <ErrorMessage
                            message={this.state.siteDetails && this.state.customerDetails.attributes?'':this.state.isJobErrorFormData?.customerDetails}
                        />
                    </>
                }
            </>
        )

    }

    manageConvertedLocation = (type: boolean) => {
        const { classes,t } = this.props
        return (
            <>
                {type &&
                        <Grid container direction="row" justifyContent="flex-end">
                            <Grid item xs={12} sm={1}>
                                {this.state.drawerStatus ?
                                    <Paper elevation={2} data-test-id="drawer_close" onClick={this.handleDrawerClose} className={classes.paper}>
                                        <img src={rightArrow} width="17px" height={"10px"} />
                                    </Paper>
                                    :
                                    <Paper elevation={2} data-test-id="drawer_open" onClick={this.handleDrawerOpen} className={classes.paper}>
                                        <img src={leftArrow} width="17px" height={"10px"} />
                                    </Paper>
                                }
                            </Grid>
                            {this.state.drawerStatus &&
                                <Grid item xs={12} 
                                    md={11} className={classes.jobDrawerLayout}>
                                    <Drawer
                                        elevation={2}
                                        className={classes.drawer}
                                        variant="persistent"
                                        anchor="right"
                                        open={this.state.drawerStatus}
                                        classes={{
                                            paper: classes.drawerPaper,
                                        }}
                                    >
                                        <Grid container direction="row">
                                            <ContentDrawer
                                                details={this.state.details}
                                                modeType={this.props.navigation?.history?.location.state?.convertedType}
                                                t={t}
                                            />

                                        </Grid>
                                    </Drawer>
                                </Grid>
                            }
                        </Grid>}
                                {type && this.locationDetails(type)}
                        <Hidden only={'xs'}>
                            {this.state.selctedCustomerJob &&
                                this.locationDetails(type)
                            }
                        </Hidden>



            </>
        )
    }

    manageBottomButtons = (type: any, details: any) => {
        const { classes,t } = this.props
        const convertedType = this.props?.navigation.history?.location?.state?.convertedType
        return (
            <>
                {type ?
                    <>
                        {!details?.attributes?.converted_to_quote &&
                            <>
                                {convertedType != "QuoteTo" &&
                                    <MenuItem>
                                        <ListItemIcon style={webStyle.width35}>
                                            <img src={convertToQuote} width="20px" height="20px" />
                                        </ListItemIcon>
                                        <Typography component={'div'} data-test-id="provideQuote" onClick={() => this.saveJob('saveMoreQuote')} className={classes.popupBtnText} >{t('jobs.quote')}</Typography>
                                    </MenuItem>
                                }
                            </>

                        }
                        <MenuItem>
                            <ListItemIcon style={webStyle.width35}>
                                <img src={downloadIcon} width="20px" height="20px" />
                            </ListItemIcon>
                            <Typography component={'div'} data-test-id="jobPdf" className={classes.popupBtnText} onClick={() => this.saveJob('saveMorePdf')}>{t('invoice.menuLabel.pdf')}</Typography>
                        </MenuItem>
                    </>
                    :
                    <>
                        <MenuItem>
                            <ListItemIcon style={webStyle.width35}>
                                <img src={emailIcon} width="20px" height="20px" />
                            </ListItemIcon>
                            <Typography component={'div'} data-test-id="email_modal" onClick={() => this.saveJob('saveMoreEmail')} className={classes.popupBtnText} >{t('jobs.email')}</Typography>
                        </MenuItem>
                        <MenuItem>
                            <ListItemIcon style={webStyle.width35}>
                                <img src={downloadIcon} width="20px" height="20px" />
                            </ListItemIcon>
                            <Typography component={'div'} data-test-id="job_pdf" className={classes.popupBtnText} onClick={() => this.saveJob('saveMorePdf')}>{t('invoice.menuLabel.pdf')}</Typography>
                        </MenuItem>
                        <MenuItem>
                            <ListItemIcon style={webStyle.width35}>
                                <img src={badDebt} width="20px" height="20px" />
                            </ListItemIcon>
                            <Typography component={'div'} data-test-id="generate_invoice" className={classes.popupBtnText} onClick={() => this.saveJob('saveMoreMarkAsComplete')}>{t('Calendar.markAsCompleted')}</Typography>
                        </MenuItem>
                    </>
                }

            </>
        )
    }
    locationDetails = (type:boolean) => {
        const { classes,t } = this.props
        return ( 
            <>
            <Typography variant="subtitle1" 
                className={ this.state.drawerStatus && type ?
                    `${classes.titleHeading26} ${classes.mb_10} ${classes.mt_129}`
                : `${classes.titleHeading26} ${classes.mb_10} ${classes.mt_29}`}
            > 
                {t('invoice.location')}
            </Typography>
            <CustomGoogleMap
                location={this.state.defaultLocation}
                reference={this.mapRef}
                getCustomerPlace={this.state.getCustomerPlace}
                classes={{}}
                t={this.props.t}

            />
            </>
        )
    }
    // Customizable Area End

    render() {
        const { classes,t } = this.props;
        const convertedType = this.props?.navigation.history?.location?.state?.convertedType
        const type = convertedType === "RequestTo" || convertedType == "QuoteTo"
        const startDate = localStorage.getItem("startDate");
        const endDate = localStorage.getItem("endDate");
        return (
            // Customizable Area Start
            <>
                <DashboardSideBar history={undefined} permissions={undefined} oldProps={undefined} {...this.props} navMenuItem={t('dashboardNavbar.jobs')} active={5} active1={false} active2={false} />
                <StyledRoot languageSelected={this.state.selectedLanguage}>
                    <Box
                        sx={{
                            marginTop: "8rem",
                        }}
                        className={classes.boxContainer}
                    >
                        <Container maxWidth={false}>
                            <Grid item xs={12} style={{ marginBottom: "25px" }}>
                                <CreationProcess active={2} t={t} selectLanguage={this.state.selectedLanguage}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent="flex-start">
                                    <Grid item xs={12} xl={9}>
                                        <Grid container direction="row" justifyContent="flex-start" spacing={2}>
                                            <Grid item xs={12} >
                                                <Grid container direction="row" justifyContent="flex-start" spacing={3}>
                                                    <Hidden smUp>
                                                        <Grid item xs={12}>
                                                            <img src={backIconMobile} width={12} height={24} 
                                                            data-test-id='createJob_backIcon'
                                                            onClick={() => goBack(this.props.navigation)} alt="" />
                                                        </Grid>
                                                    </Hidden>
                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle1"
                                                            className={classes.titleHeading26}>
                                                            {t('jobs.tableRow.title')}
                                                            <span style={webStyle.errorText}>*</span>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} md={7}>
                                                        <CustomizedTextField
                                                            variant="outlined"
                                                            fullWidth
                                                            onChange={this.handleJobChange}
                                                            onBlur={this.handleOnBlur}
                                                            name={"jobTitle"}
                                                            value={this.state.jobForm.jobTitle}
                                                            data-test-id="jobTitleField" />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                           <Grid item xs={12}>
                                         <ErrorMessage message={this.state.jobForm.jobTitle === '' || (this.state.jobForm.jobTitle && this.state.jobForm.jobTitle.length > 40) ? this.state.isJobErrorFormData.title : ''} />

                                            </Grid>
                                            </Grid>
                                            </Grid>
                                            <Grid container direction="row" spacing={2}>
                                            <Grid item xs={12} md={8} className={classes.gutterTop2}>
                                                {this.manageConvertedJobCustomerDetails(type,t)}
                                            </Grid>
                                            <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                                {this.state.selctedCustomerJob &&
                                                    this.locationDetails(type) 
                                                }
                                            </Hidden>
                                            <Grid item xs={12} md={4}>
                                                {this.manageConvertedLocation(type)}
                                            </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <DateTimeScheduling
                                                    editStatus={true}
                                                    getOneOffJobDetails={this.getOneOffJobDetails}
                                                    getActiveJobs={this.getActiveJobs}
                                                    getRecurringJobDetail={this.getRecurringJobDetail}
                                                    getJobType={this.state.jobScheduling}
                                                    t={t}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    languageSelected={this.state.selectedLanguage}

                                                />
                                            </Grid>
                                            {type && <Grid item xs={12}>
                                                <JobsCalender
                                                    mode={"create"}
                                                    sechudlingJob={this.state.JobTypeDetails}
                                                    jobEditform={this.state.jobForm}
                                                    history={undefined} navigation={undefined}
                                                    id={""}
                                                    t={t}
                                                    getEmployeeDetail={undefined}
                                                    jobEditStatus={false}
                                                    getEmployeeData={this.state.getEmployeeData}
                                                    getJobData={this.state.getJobData}
                                                    employeeName={""} siteId={""} employeeDetails={this.state.employeeDetails} />
                                            </Grid>}
                                        </Grid>

                                   
                                    
                            </Grid>
                        </Container>
                        <Container maxWidth={false}>
                            <Grid item xs={12}>
                                <Grid container direction="row" justifyContent="flex-start" spacing={3}>
                                <Grid item xs={12} md={8} className={classes.gutterTop3}>
                                        <Grid container>
                                            <Typography variant="subtitle1"
                                                className={classes.titleHeading26}
                                            >
                                                {t('Calendar.label.assignTeamMember')}
                                            </Typography>
                                        </Grid>
                                        <CustomTeamMember
                                            getEmployeeData={this.state.getEmployeeData}
                                            values={this.state.teamMember}
                                            data-test-id='teamMember'
                                            name="employee_id"
                                            selectLanguage={this.state.selectedLanguage}
                                            onChange={this.handleChangeTeam}
                                            t={t}
                                            onClick={(employeeId: string) =>
                                                this.handleRemoveTeam(employeeId)}
                                        />
                                   </Grid>
                                    {!type &&
                                        <Grid item xs={12} md={9}>
                                            <JobsCalender
                                                mode={"create"}
                                                sechudlingJob={this.state.JobTypeDetails}
                                                jobEditform={this.state.jobForm}
                                                navigation={undefined}
                                                id={""}
                                                history={undefined}
                                                jobEditStatus={false}
                                                employeeName={""}
                                                t={t}
                                                getEmployeeData={this.state.getEmployeeData}
                                                getJobData={this.state.getJobData}
                                                siteId={""} getEmployeeDetail={undefined} employeeDetails={this.state.employeeDetails} />
                                        </Grid>}
                                                                            
                                    <Grid item xs={12}
                                        md={8}>
                                        <ProductServices
                                            productServices={this.state.productServices}
                                            productData={this.state.productData}
                                            handleProductChange={this.handleChangeProductInput}
                                            productOnBlur={this.productOnBlur}
                                            handleProductDocument={this.handleProductDocument}
                                            addField={this.addField}
                                            handleChangeSearchInput={this.handleChangeSearchInput}
                                            removeField={this.removeField}
                                            completed={this.state.completed}
                                            files={this.state.files}
                                            progress={this.state.progress}
                                            handleRemoveFile={this.handleRemoveFile}
                                            handleChangeProductFiles={this.handleChangeProductFiles}
                                            value={this.state.productServices}
                                            isEditable={true}
                                            isErrorFormData={this.state.isJobErrorFormData}
                                            history={this.props.navigation.history}
                                            currency={this.state.currencySym}
                                            t={t}
                                            languageSelected={this.state.selectedLanguage}
                                        />

                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography
                                            className={classes.titleHeading26}
                                            variant="subtitle1">
                                            {t('suppliers.price')}
                                        </Typography>
                                        <PriceSectionWeb
                                            data={this.state.productServices}
                                            priceSectionData={this.state.priceSectionData}
                                            emptyPriceSectionData={this.emptyPriceSectionDataInvoice}
                                            selectTaxRate={this.selectTaxRateInvoice}
                                            handlePriceSection={this.handlePriceSection}
                                            isEditable={this.state.isEditable}
                                            handleChangePaymentMethodRef={this.handleChangePaymentMethodRef}
                                            handleCollectionPaymentModal={this.handleCollectionPaymentModal}
                                            handleCollectionPaymentClose={this.handleCollectionPaymentClose}
                                            currency={this.state.currencySym}
                                            selectCurrency={this.selectCurrency}
                                            selectedLang={this.state.selectedLanguage}
                                            selectedCurrency={this.state.selectCurrency}
                                            handlePay_balence={this.handlePay_balance}
                                            handleCollected={this.handleCollected}
                                            calculateTotal={this.calculateTotal}
                                            getSubTotal={this.getSubTotal}
                                            handleChange={this.handleChange}
                                            handleTransactionDate={this.handleTransactionDate}
                                            onClose={this.handlePaymentModalClose}
                                            discountDialog={this.state.discountDialog}
                                            handleDiscountDialogOpen={this.handleDiscountDialogOpen}
                                            collectedPaymentForm={this.state.collectedPaymentForm}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={8} className={classes.gutterTop3}>
                                        <InternalNotesAttachements
                                            editStatus={true}
                                            updateInternalNotes={this.updateInternalNotes}
                                            updateInternalAttachments={this.updateInternalAttachments} 
                                            t={t}
                                            languageSelected={this.state.selectedLanguage}
                                        />
                                        <Typography component={'div'} className={classes.errorText}>{this.state.isJobErrorFormData.internalNotes}</Typography>
                                    </Grid>
                                  
                                    <Grid item xs={12}>
                                        <Grid container direction="row" className={`${classes.flexDirection} ${classes.margin20}`}>
                                            <Grid item xs={12} sm={5} md={6} className={classes.mt_1}>
                                                <CancelButton
                                                    data-test-id="cancelButton"
                                                    variant="outlined"
                                                    onClick={this.cancelCreateRequest}>
                                                    <Typography variant='subtitle2' className={classes.buttonText}>{t('invoice.button.cancel')}</Typography>
                                                </CancelButton>
                                            </Grid>
                                            <Grid item xs={12} sm={7} md={6} className={`${classes.flexDirection}`} container direction='row' justifyContent="flex-end">
                                                <OutlinedButton
                                                    className={`${classes.mr_1} ${classes.ml_1}`}
                                                    variant="outlined"
                                                    data-test-id="savejob"
                                                    data-testid='saveJob'
                                                    onClick={() => this.saveJob('save')}
                                                >
                                                    <Typography variant='subtitle2' className={`${classes.buttonText} ${classes.lineHeight}`}>{type ? `${t('request.popupBtn.job')}` : `${t('jobs.button.saveJob')}`}</Typography>
                                                </OutlinedButton>
                                                <ContainedButton
                                                    className={classes.mt_1}
                                                    aria-controls="customized-menu"
                                                    aria-haspopup="true"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.handleOpenSaveMore}
                                                    data-testid="saveAndJob"
                                                    data-test-id="saveAndJob"
                                                >
                                                    <Typography
                                                        variant='subtitle2'
                                                        className={`${classes.buttonText} ${classes.lineHeight}`}
                                                    >
                                                        {t('request.button.saveAnd')}
                                                    </Typography>
                                                    <img style={
                                                        this.state.anchorEl ?
                                                            { ...webStyle.dropDownArrow, transform: "rotate(180deg)" } :
                                                            webStyle.dropDownArrow
                                                        }
                                                        src={dropDownArrow} alt=""
                                                    />
                                                </ContainedButton>
                                                <MoreActionStyledMenu
                                                    id="customized-menu"
                                                    anchorEl={this.state.anchorEl}
                                                    keepMounted
                                                    open={Boolean(this.state.anchorEl)}
                                                    onClose={this.handleCloseSaveMore}
                                                    data-test-id="moreinfo"
                                                >
                                                    <div className={classes.iconLayout}>
                                                        <ClearIcon onClick={this.handleCloseSaveMore} />

                                                    </div>
                                                    {this.manageBottomButtons(type, this.state.details)}
                                                </MoreActionStyledMenu>
                                            </Grid>
                                        </Grid>
                                    </Grid> 
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </StyledRoot>
                {this.state.customerDailog &&
                    <CustomerModal
                        open={this.state.customerDailog}
                        saveMapData={this.saveMapData}
                        getCustomerDetails={this.getCustomerDetails}
                        getSiteDetails={this.getJobSiteDetails}
                        getCustomerSelectedStatus={this.getCustomerSelectedStatus}
                        handleCusValidation={this.handleCusValidation}
                        selectedCustomerButtonStatus={this.selectedJobCustomerButtonStatus}
                        data-test-id="searchCustomer"
                        customerDetails={this.state.customerDetails.attributes}
                        siteDetails={this.state.siteDetails}
                        navigation={this.props.navigation}
                        id={""}
                        type={""}
                        t={t}
                        history={this.props.navigation.history}
                        crudStatus={""} location={this.props.navigation.history.location} requestId={""} />
                }
                {
                    this.state.sendEmailDailog &&
                    <EmailModal
                        open={this.state.sendEmailDailog}
                        jobDetails={this.state.createdJobDetails}
                        closeEmailModal={this.getEmailModal}
                        t={t}
                        customerEmail={this.state.customerDetails.attributes?.email}
                    />
                }
                {this.state.jobPdfDailog
                    &&
                    <JobPdf
                        jobId={this.state.createdJobDetails?.id}
                        openPdf={this.state.jobPdfDailog}
                        closePdf={this.handlePdfclose}
                        currency={this.state.currencySym}
                        type="Job"
                        t={t}
                    />}

                <LoadingBackdrop
                    loading={this.state.jobLoading}
                />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
    employeeInput: {
        width: "808px",
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        }
    },
    bottomBtnsLayout: {
        marginTop: "4rem"
    },
});
const webStyle = {
    selectedCustomerMapStyles: {
        width: "100%",
        minHeight: "336px",
        borderRadius: "10px",
    },
    width35:{
        minWidth:"35px"
    },
    dropDownArrow: {
        width: "16px",
        height: "8px",
        marginLeft:"15px"
    },
    jobNo: {
        color: CONSTANTS.color.lightBrown,
        textDecoration: 'underline',
        marginBottom: "15px"
    },
    errorText:{
        color: CONSTANTS.color.crimsonRed,
        fontSize: "20px",
        fontWeight: 400,
    }

}
const commonStyles:any = withRequestStyles(CreateJob)
const CreateJobStyles = withStyles(styles)(commonStyles);
const CreateJobToaster = withSnackbar(CreateJobStyles)
export default withTranslation()(withCommonStyles(CreateJobToaster))
// Customizable Area End
