import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Button,
  Typography,
} from "@material-ui/core";
import {
  withStyles,
  Theme
} from "@material-ui/core/styles";
import {
  StyledBox,
  StyledGrid,
  StyledGridItem,
  StyledGridItem2,
} from "./CreateSupplier.web";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { DeleteDialog } from "./DeleteDialog.web";
export const configJSON = require("./config");
import { CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
import DeleteModel from "../../RequestManagement/src/DeleteModal.web"; 
// Customizable Area End

import CreateSupplierController, {
  Props,
} from "./CreateSupplierController.web";

// Customizable Area Start
// Customizable Area End

export class SupplierData extends CreateSupplierController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const {
      supplierName,
      contactName,
      supplierOf,
      mobile,
      email,
      address,
      city,
      state,
      zip,
      currency,
      companyInformation
    } = this.state.formDataEdit;
    const { t } = this.props;
    const { languageSelected } = this.state;
    const { classes }: any = this.props;
    return (
      <>
        <Box className={classes?.suppliersDataContainer}>
          <Typography className={classes?.headerText} variant="subtitle1">
            {t('suppliers.personalInformation')}
          </Typography>
          <StyledBox className={languageSelected !=="ar" ? `${classes?.formData}` : `${classes?.formDataAr}`}>
            <Grid item lg={9} md={12} xs={12}>
              <StyledGrid container spacing={10} className={classes?.styledGridContainer}>
                <Grid item lg={6} md={6} xs={12}>
                  <Typography
                    className={classes?.contactFormTitle}
                    variant="body1"
                    gutterBottom
                    color="textPrimary"
                  >
                    {t('suppliers.supplierName')}
                  </Typography>
                  <Typography
                    style={webStyle.supplierData}
                    className={classes?.supplierData}
                    variant="subtitle2"
                    color="textPrimary"
                  >
                    {supplierName}
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <Typography
                    className={classes?.contactFormTitle}
                    variant="body1"
                    gutterBottom
                    color="textPrimary"
                  >
                    {t('suppliers.contactName')}
                  </Typography>
                  <Typography
                    style={webStyle.supplierData}
                    className={classes?.supplierData}
                    variant="subtitle2"
                    color="textPrimary"
                  >
                    {contactName}
                  </Typography>
                </Grid>
              </StyledGrid>
            </Grid>
            <Grid item lg={9} md={12} xs={12}>
              <StyledGrid container spacing={10}>
                <Grid item lg={12} md={12} xs={12}>
                  <Typography
                    className={classes?.contactFormTitle}
                    variant="body1"
                    gutterBottom
                    color="textPrimary"
                  >
                    {t('suppliers.supplierOf')}
                  </Typography>
                  <Typography
                    style={webStyle.supplierData}
                    className={classes?.supplierData}
                    variant="subtitle2"
                    color="textPrimary"
                  >
                    {supplierOf}
                  </Typography>
                </Grid>
              </StyledGrid>
            </Grid>
          </StyledBox>
          <Grid item lg={9} md={12} xs={12}>
            <div className={classes?.hr}></div>
          </Grid>
          <Typography className={classes?.headerText} variant="subtitle1">{t('suppliers.contactInformation')}
          </Typography>
          <StyledBox className={languageSelected !=="ar" ? `${classes?.formData}` : `${classes?.formDataAr}`}>
            <Grid item lg={9} md={12} xs={12}>
              <StyledGrid
                container
                spacing={10}
              >
                <Grid item lg={6} md={6} xs={12}>
                  <Typography className={classes?.contactFormTitle} variant="body1"
                    gutterBottom
                    color="textPrimary"
                  >
                    {t('suppliers.mobileNumber')}
                  </Typography>
                  <Typography style={webStyle.supplierData} className={classes?.supplierData}
                    variant="subtitle2"
                    color="textPrimary"
                  >
                    {this.state.countryCodeEdit} {mobile}
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <Typography
                    className={classes?.contactFormTitle}
                    variant="body1"
                    gutterBottom
                    color="textPrimary"
                  >
                    {t('suppliers.emailAddress')}
                  </Typography>
                  <Typography
                    style={webStyle.supplierData}
                    className={classes?.supplierData}
                    variant="subtitle2"
                    color="textPrimary"
                  >
                    {email}
                  </Typography> </Grid>
                  
                   </StyledGrid>
            </Grid>
          </StyledBox>
          <Grid item lg={9}
           md={12} xs={12}>
            <div 
            className={classes?.hr}>

            </div>
          </Grid>
          <Typography variant="subtitle1" 
          className={classes?.headerText}>
            {t('suppliers.companyInformation')}
          </Typography>
          <StyledBox style={languageSelected !== "ar" ? 
          webStyle.addressContainer : webStyle.addressContainerAr}>
            <Grid item lg={9}
             md={12} xs={12}>
              <StyledGrid container
               spacing={10}>
                <Grid item lg={12} md={12} 
                xs={12}>
                  <Typography
                    className={classes?.contactFormTitle}
                    variant="body1"
                    color="textPrimary"
                    gutterBottom
                    dangerouslySetInnerHTML={{ __html: t('suppliers.supplierof') }}
                  >
                    
                  </Typography>
                  <Typography
                    style={webStyle.supplierData}
                    className={classes?.supplierData}
                    variant="subtitle2"
                    color="textPrimary"
                  >
                    {companyInformation}
                 
                    </Typography> </Grid>
              
                    </StyledGrid>  </Grid>
            </StyledBox>
          <Grid item lg={9} 
          md={12} xs={12}>
            <div className={classes?.hr}>

            </div>
          </Grid>
          <Typography 
          variant="subtitle1" className={classes?.headerText}>
            {t('suppliers.address')}
          </Typography>
          <StyledBox style={languageSelected !== "ar" ?
           webStyle.addressContainer : webStyle.addressContainerAr}>
            <Grid item lg={9} md={12} 
            xs={12}>
              <StyledGrid 
              container
               spacing={10}>
                <Grid item 
                lg={12}
                 md={12} xs={12}>
                  <Typography
                    className={classes?.contactFormTitle}
                    gutterBottom
                    variant="body1"
                    color="textPrimary"
                  >
                    {t('suppliers.address')}
                  </Typography>
                  <Typography
                    style={webStyle.supplierData}
                    className={classes?.supplierData}
                    variant="subtitle2"
                    color="textPrimary"
                  >
                    {address}
                  </Typography>
                </Grid>
              </StyledGrid>
            </Grid>
            <Grid item lg={9} md={12} xs={12}>
              <StyledGrid
                container
                spacing={10}
              >
                <StyledGridItem item lg={6} md={6} xs={12}>
                  <Typography className={classes?.contactFormTitle} variant="body1"
                    gutterBottom
                    color="textPrimary"
                  >
                    {t('suppliers.city')}
                  </Typography>
                  <Typography style={webStyle.supplierData} className={classes?.supplierData}
                    variant="subtitle2"
                    color="textPrimary"
                  >{city}
                  </Typography>
                </StyledGridItem>
                <StyledGridItem2 item lg={6} md={6} xs={12}>
                  <Typography
                    className={classes?.contactFormTitle}
                    variant="body1"
                    gutterBottom
                    color="textPrimary"
                  >
                    {t('suppliers.state')}
                  </Typography>
                  <Typography
                    style={webStyle.supplierData}
                    className={classes?.supplierData}
                    variant="subtitle2"
                    color="textPrimary"
                  >
                    {state}
                  </Typography>
                </StyledGridItem2>
              </StyledGrid>
            </Grid>
            <Grid
              item
              lg={9}
              md={12}
              xs={12}
            >
              <StyledGrid
                container spacing={10}
              >
                <StyledGridItem item lg={6} md={6} xs={12}>
                  <Typography className={classes?.contactFormTitle} variant="body1" gutterBottom color="textPrimary">{t('suppliers.zipCode')}</Typography>
                  <Typography style={webStyle.supplierData} className={classes?.supplierData}
                    variant="subtitle2" color="textPrimary">{zip}
                  </Typography>
                </StyledGridItem>
                <StyledGridItem2 item lg={6} md={6} xs={12}>
                  <Typography
                    className={classes?.contactFormTitle}
                    variant="body1"
                    gutterBottom
                    color="textPrimary"
                  >
                    {t('suppliers.currency')}
                  </Typography>
                  <Typography
                    style={webStyle.supplierData}
                    className={classes?.supplierData}
                    variant="subtitle2"
                    color="textPrimary"
                  >
                    {currency}
                  </Typography>
                </StyledGridItem2>
              </StyledGrid>
            </Grid>
          </StyledBox>
        </Box>
        
        <Box style={webStyle.buttonContainer} className={classes?.buttonContainer}>
        {this.state.status === "archived" ?
          <Button
          className={`${classes.buttonStyleEdit} ${classes.buttonSaveSuppliers} ${classes.buttonStyleCommon}`}
          onClick={this.handleOpenUnarchiveDialog}
          data-testid='unArchiveSupplier'
        >
          <Typography
            style={webStyle.buttonTextEdit}
            className={classes?.buttonText}
            variant="subtitle2"
          >
            {t('customers.formData.unArchive')}
          </Typography>
          </Button> : 
          <Button
            className={`${classes?.buttonStyleEdit} ${classes.buttonSaveSuppliers} ${classes?.buttonStyleCommon}`}
            onClick={this.handleEditButton}
          >
            <Typography
              style={webStyle.buttonTextEdit}
              className={classes?.buttonText}
              variant="subtitle2"
            >
              {t('suppliers.editSupplier')}
            </Typography>
          </Button>
        }
          {this.state.status === "archived" &&
            <Box className={classes?.buttonsInnerContainer}>
              <Button
                className={`${classes?.buttonStyleMobile}  ${classes.btnBoxCancel} ${classes?.buttonStyleCommon}`}
                onClick={this.handleCancelButton}
              >
                <Typography
                  style={webStyle.buttonCancel}
                  className={classes?.buttonText}
                  variant="subtitle2"
                >
                  {t('suppliers.cancel')}
                </Typography>
              </Button>
              {this.state.status !== "archived" ? 
                <DeleteDialog
                  {...this.props}
                  status={this.state.status}
                  title={t('suppliers.archiveSupplier')}
                  description={t('suppliers.archiveSupplierConfirmation')}
                  deleteDialogOpen={this.state.deleteDialogOpen}
                  handleClickOpenDialog={this.handleClickOpenDialog}
                  handleCloseDialog={this.handleCloseDialog}
                  handleClickProceedDelete={this.handleClickProceedArchive}
                  loader={this.state.archiveSuppliersLoading && (
                    <Box>
                      <LoadingBackdrop
                        loading={this.state.archiveSuppliersLoading}
                      />
                    </Box>
                  )}
                />
              :
                <DeleteDialog
                  {...this.props}
                  title={t('suppliers.deleteSupplier')}
                  description={t('suppliers.deleteSupplierConfirmation')}
                  handleClickOpenDialog={this.handleClickOpenDialog}
                  deleteDialogOpen={this.state.deleteDialogOpen}
                  handleCloseDialog={this.handleCloseDialog}
                  handleClickProceedDelete={this.handleClickProceedDelete}
                  loader={this.state.deleteSuppliersLoading && (
                    <Box>
                      <LoadingBackdrop
                        loading={this.state.deleteSuppliersLoading}
                      />
                    </Box>
                  )}
                />
              }
            </Box>
          }
          {this.state.openUnarchiveDialog &&
          <DeleteModel
            deleteType={this.props.t("suppliers.unarchiveSupplier")}
            subType={this.props.t("suppliers.unarchiveSupplierConfirmation")}
            open={this.state.openUnarchiveDialog}
            onClose={this.handleCloseUnarchiveDialog}
            handleDelete={this.unArchiveSupplier}
            t={this.props.t}
          />
        }
        </Box>
        <LoadingBackdrop
          loading={this.state.getSingleSupplierLoading}
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({

});

const webStyle = {
  addressContainer: {
    padding: "1.5rem 1rem 5rem 3rem"
  },
  addressContainerAr: {
    padding: "1.5rem 3rem 5rem 1rem"
  },
  buttonContainer: {
    padding: "3rem 0",
    display: "flex",
    flexDirection: "row-reverse" as const,
    justifyContent: "space-between",
    alignItems: "center",
    gap: "1rem",
  },
  buttonCancel: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textAlign: "center" as const,
  },
  buttonTextEdit: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
  },
  supplierData: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    marginTop: "1.5rem"
  }
}

export default withTranslation()(withStyles(styles)(SupplierData));
// Customizable Area End