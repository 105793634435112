import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Container,
  Typography,
  InputBase,
  TextField,
  MenuItem,
  InputAdornment,
  TableContainer,
  TableRow,
  CircularProgress,
  Backdrop,
  ClickAwayListener,
  Hidden,
  Checkbox,
  Divider
} from "@material-ui/core";
import { withStyles ,} from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { StyledRoot } from "../../dashboard/src/utility.web";
import { StyledCheckbox } from "../../Settings/src/CompanyInformation.web";
import { searchIcon, dropDownSelectIcon } from "./assets";
import { withSnackbar } from "../../../components/src/toastweb";
import withPermission from "../../../components/src/withPermission.web";
import {  CONSTANTS } from "../../../components/src/theme";
import { goBack } from "../../../components/src/CommonFunction";
import { mobileArrow } from "../../email-account-login/src/assets";
import { withTranslation } from "react-i18next";
import {getDividerTextStyleCustomer,  getStatusTextStyleCustomer} from "./utility.web"
import ListPopover from "../../../components/src/CommonPopover";
import { CreateQuote } from "../../../components/src/CustomizableComponents.web";
import { StyledGrid } from "../../RequestManagement/src/Requests.web"
import { StyledTextField } from "./Suppliers.web";
import moment from "moment";

// Customizable Area End

import CustomersController, { Props } from "./CustomersController.web";



// Customizable Area Start
export const CustStyledTableContainer = withStyles({
  root: {
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
})(TableContainer);
 
export const StyledTableRow = withStyles({
  root: {
    "& .MuiTableCell-body": {
      color: `#626262 !important`,
    },
    "&:hover": {
      backgroundColor: `${CONSTANTS.color.blueViolet} !important`,
      "& .MuiTableCell-body": {
        color: `${CONSTANTS.color.white} !important`,
      },
    },
  }
})(TableRow);
 
export interface SiteProp {
  id: number;
  site_name: string;
  email: string | null;
  phone_number: string | null;
  site_address: string;
  state: string;
  city: string;
  zip_code: string;
  customer_id: number;
  longitude: string;
  latitude: string;
  created_at: string;
  updated_at: string;
  country: string | null;
}
interface CustomerStatusData {
  id:number,
  title:string,
  statusName:string
}



export interface ToggleDataProp {
  id:number,
  title:string
  hidden:boolean,
}

export interface File {
  id: number;
  url: string;
  name: string;
  content_type: string;
  size: number;
}

interface Attributes {
  name: string;
  email: string;
  phone_number: string;
  account_id: number;
  employee_id: number | null;
  status: string;
  notes: string;
  quote_follow_up: boolean;
  job_follow_up: boolean;
  invoice_follow_up: boolean;
  created_at: string;
  updated_at: string;
  files: File[];
  sites: SiteProp[];
}

interface CustomerMockDataProp {
  id:number,
  key:string,
  title:string
}
export interface Customer {
  id: string;
  type: string;
  attributes: Attributes;
}


enum CSSClass {
  marginTopHeadings = "marginTopHeadings",
  tableCellLayout = "tableCellLayout",
  padding_16 = "padding_16",
  customersHeadLayoutMargin = "customersHeadLayoutMargin",
  menuItemStyle = "menuItemStyle",
  CustboxContainer = "CustboxContainer",
  CustbuttonContainer = "CustbuttonContainer",
  CustbuttonText = "CustbuttonText",
  CustdropDownSelectIcon = "CustdropDownSelectIcon",
  CusttableHeadText = "CusttableHeadText",
  CusttableCell = "CusttableCell",
  CustmenuItem = "CustmenuItem",
  customerBackIconContainer = "customerBackIconContainer"
}
interface ComponentClasses {
  [key: string]: CSSClass;
}
interface WebStyleProp {
  CustsearchContainer: React.CSSProperties;
  CustsearchIcon: React.CSSProperties;
  CustinputBase: React.CSSProperties;
  CustdropDownSelectIcon: React.CSSProperties;
  CustdropDownSelectIconTable: React.CSSProperties;
  CuststatusModalContainer: React.CSSProperties;
  CuststatusModal: React.CSSProperties;
  CustmenuItem: React.CSSProperties;
  CustapplyButtonContainer: React.CSSProperties;
  CustbuttonContainerApply: React.CSSProperties;
  CustbuttonTextApply: React.CSSProperties;
  CusttoggleModalContainer: React.CSSProperties;
  CusttoggleModal: React.CSSProperties;
  CusttoggleIconsContainer: React.CSSProperties;
  CusttoggleIcons: React.CSSProperties;
  CustsuppliersContainer: React.CSSProperties;
  CusttableCell: React.CSSProperties;
  CusttableCellBody: React.CSSProperties;
  CusttableHeadSpan: React.CSSProperties;
  CusttableHeadText: React.CSSProperties;
  CustbuttonsContainer: React.CSSProperties;
  CustbuttonTextCreateSupplier: React.CSSProperties;
  CustnoSupplierText: React.CSSProperties;
}

// Customizable Area End

export class Customers extends CustomersController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  CustsortData = (item: CustomerMockDataProp) => this.onSort(item.key);

  showStatusModal = (webStyle: WebStyleProp,classes:ComponentClasses,CustmockStatusData:CustomerStatusData[]) => {  
    return this.state.statusModal ? (
      <ClickAwayListener onClickAway={this.handleStatusModalClose}>
        <Typography>
          <Box style={webStyle.CuststatusModalContainer}>
            <Box style={webStyle.CuststatusModal}>
              {CustmockStatusData.map((item) => (
                <Box key={item.id} display={"flex"} mb={1} gridGap={"0.5rem"}>
                <Checkbox
                  data-tes-id="handleSelectStatus"
                  checked={this.state.checkedItems.some((subItem) => item.statusName === subItem)}
                  onChange={this.handleSelectStatus.bind(this,item,CustmockStatusData)}
                  style={{ padding: 0, color: "#1c18af" }} />
                <Typography className={classes.menuItemStyle} variant="body1">
                  {item.title}
                </Typography>
              </Box>
              ))}
            </Box>
           
          </Box>
        </Typography>
      </ClickAwayListener>
    ) : null;
  };

  showToggleModal = (webStyle: WebStyleProp) => {
 
    return this.state.toggleModal ? (
      <ClickAwayListener onClickAway={this.handleToggleModalClose}>
        <Typography>
          <Box style={this.state.lang==="en"? webStyle.CusttoggleModalContainer:{...webStyle.CusttoggleModalContainer,width:"237px"}}>
            <Box style={webStyle.CusttoggleModal}>
              {this.state.toggleData.map((item: ToggleDataProp) => (
                <Box style={webStyle.CusttoggleIconsContainer} key={item.id}>
                  <StyledCheckbox
                    data-test-id="handleToggleColumn"
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                    checked={this.state.checkedColumn.some((subItem) => item.id === subItem)}
                    onChange={this.handleCheckedColumn.bind(this,item)}
                  />
                  <MenuItem
                    style={webStyle.CustmenuItem}
                    value={item.title}
                    disableRipple
                  >
                    <Typography variant="body1"> {item.title}</Typography>
                  </MenuItem>
                </Box>
              ))}
            </Box>
          </Box>
        </Typography>
      </ClickAwayListener>
    ) : null;
  };

  renderCircularBackdrop = () => {
    return this.state.getCustomersLoading ? (
      <Backdrop
        style={{
          zIndex: 1000 + 1,
          color: "#05037a",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={this.state.getCustomersLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    ) : null;
  };

  renderStyleStatusModal = (webStyle: WebStyleProp) => {
    return !this.state.statusModal
      ? webStyle.CustdropDownSelectIcon
      : {
          ...webStyle.CustdropDownSelectIcon,
          transform: "rotate(180deg)",
        };
  };

  renderStyleToggleModal = (webStyle: WebStyleProp) => {
    return !this.state.toggleModal
      ? webStyle.CustdropDownSelectIcon
      : {
        ...webStyle.CustdropDownSelectIcon,
        transform: "rotate(180deg)",
      };
  };
  renderTypo = (rowItem: any, subItem: CustomerMockDataProp, classes: ComponentClasses) => {
    let valueToShow = rowItem.attributes[subItem.key];

    if (subItem.key === "site_name") {
      return (
        rowItem.attributes.sites.length > 1 ?
          <Typography
            data-test-id="openListPopover"
            onClick={this.handleTooltipOpen.bind(this, rowItem.attributes.sites)}
            onMouseEnter={this.handleTooltipOpen.bind(this, rowItem.attributes.sites)}
            variant="body1"
            className={classes.textTableCell}
          >
            {rowItem.attributes.sites[0]?.site_name}...
          </Typography> :
          <Typography
            variant="body1"
            className={classes.textTableCell}
          >
            {rowItem.attributes.sites[0]?.site_name}
          </Typography>
      );
    } else if (subItem.key === "created_at") {
      return (<Typography variant="body1" className={classes.textTableCell}>
        {moment(valueToShow).format("DD/MM/YYYY").toString()}
      </Typography>)
    }

    return (
      <Typography variant="body1" className={classes.textTableCell}>
        {valueToShow}
      </Typography>
    );
  }

  getActiveLangStatus = (lang: string, t: (key: string) => string, status: string) => {
    return lang === "en" ? status : t(`customers.statuses.${0}`)
  }

  getArchivedLangStatus = (lang: string, t: (key: string) => string, status: string) => {
    return lang === "en" ? status : t(`customers.statuses.${1}`)
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t } = this.props;
    const { classes }: any = this.props;
    const CustmockStatusData = [
      { id: 1, title: t(`customers.mockStatus.${0}.title`),statusName:"all"},
      { id: 2, title: t(`customers.mockStatus.${1}.title`) ,statusName:"active"},
      { id: 3, title: t(`customers.mockStatus.${2}.title`),statusName:"archived" },
    ];
    const CustmockTableRow = [
      { id: 1, key: "name", title: t(`customers.mockTableRow.${0}.title`) },
      { id: 2, key: "phone_number", title: t(`customers.mockTableRow.${1}.title`) },
      { id: 3, key: "site_name", title: t(`customers.mockTableRow.${2}.title`)},
      { id: 4, key: "created_at", title: t(`customers.mockTableRow.${3}.title`) },
    ];

    const mockStatus = ["active","archived"];
    return (
      <>
        <ListPopover
          anchorEl={this.state.anchorEl}
          anchorId={this.state.anchorId}
          siteData={this.state.listData}
          listData={undefined}
          popoverHeading={this.state.popoverHeading}
          handleTooltipClose={this.handleTooltipClose}
        />
        <DashboardSideBar
          permissions={undefined}
          {...this.props}
          navMenuItem={t("landingPage.Pricing.customers")}
          active={false}
          active1={1}
          active2={false}
        />
        <StyledRoot languageSelected={this.state.lang}>
          <Box
            sx={{
              marginTop: "9rem",
            }}
            className={classes.CustboxContainer}
          >
            <Container maxWidth={false}>
              <Grid container spacing={1}>
                <Grid item lg={7} md={6} xs={12}>
                  <Box className={classes.customerBackIconContainer}>
                  <Hidden only={['sm', 'md', 'lg', 'xl']}>
                    <img src={mobileArrow}
                        data-test-id="tabs_back_icon"
                        onClick={() => goBack(this.props.navigation)}
                        width={12} height={24} alt="mobile arrow" />
                    </Hidden>
                  
                  <Box style={webStyle.CustsearchContainer} >
                    <Box>
                      <img
                        style={{...webStyle.CustsearchIcon,marginTop:"3px"}}
                        src={searchIcon}
                        alt=""
                      />
                    </Box>
                    <InputBase
                      className={classes.CusttableCell}
                      data-test-id="handleChangeSearchCustomer"
                      style={webStyle.CustinputBase}
                      placeholder={t('customers.searchCustomer')}
                      inputProps={{ "aria-label": "search" }}
                      value={this.state.searchCustomer}
                      onChange={this.handleChangeSearchCustomer}
                    />
                  </Box>
                  </Box>
                </Grid>
                <Grid item lg={2} md={6} xs={5}>
                  <StyledTextField
                    fullWidth
                    variant="outlined"
                    className={this.state.lang === 'ar' ? 'language-ar' : ''}
                    defaultValue={t('customers.status')}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <img
                            style={this.renderStyleStatusModal(webStyle)}
                            src={dropDownSelectIcon}
                            alt=""
                          />
                        </InputAdornment>
                      ),
                    }}
                    onClick={this.handleStatusModalOpen}
                  ></StyledTextField>
                  {this.showStatusModal(webStyle,classes,CustmockStatusData)}
                </Grid>
                <Grid item lg={3} md={6} xs={7}>
                  <StyledTextField
                    fullWidth
                    variant="outlined"
                    className={this.state.lang === 'ar' ? 'language-ar' : ''}
                    defaultValue={t('customers.toggleColumns')}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <img
                            style={this.renderStyleToggleModal(webStyle)}
                            src={dropDownSelectIcon}
                            alt=""
                          />
                        </InputAdornment>
                      ),
                    }}
                    onClick={this.handleToggleModalOpen}
                  ></StyledTextField>
                  {this.showToggleModal(webStyle)}
                </Grid>
              </Grid>
             
              <Box>
                <Grid style={webStyle.CustsuppliersContainer}>
                  <Grid item xs={12} style={{ overflow: 'scroll', minWidth: "fit-content" }}>
                    <Grid container
                      style={{
                        display: 'flex',
                        width: '100%',
                        flexWrap: 'nowrap',

                      }}
                    >
                      {CustmockTableRow.map((rowItem: CustomerMockDataProp) => {
                         const isIdChecked = this.state.checkedColumn.includes(rowItem.id);
                        return (
                          <>
                            {isIdChecked  &&
                              <Grid item
                                className={classes.padding_16}
                                key={rowItem.id}
                                onClick={this.CustsortData.bind(this, rowItem)}
                                style={
                                  this.state.sortingProperty === rowItem.key
                                    ? {
                                      backgroundColor: "rgba(232,232,232,0.4)",
                                      width:
                                        `${(100 / CustmockTableRow.length)}%`
                                    }
                                    : {

                                      width: `${(100 / this.state.checkedColumn.length)}%`
                                    }
                                }

                              >
                                <Box
                                  style={webStyle.CusttableHeadSpan}
                                >
                                  <Typography

                                    variant="body1"
                                    align="left"
                                    style={{whiteSpace:"nowrap"}}
                                  >
                                    {rowItem.title}
                                  </Typography>
                                  <img
                                    style={
                                      this.state.sortingProperty === rowItem.key &&
                                        this.state.sortingOrder === "asc"
                                        ? {
                                          ...webStyle.CustdropDownSelectIconTable,
                                          transform: "rotate(180deg)",
                                        }
                                        : webStyle.CustdropDownSelectIconTable
                                    }
                                    src={dropDownSelectIcon}
                                    className={
                                      this.props.classes.RequestRequestdropDownSelectIcon
                                    }
                                    alt=""
                                  />

                                </Box>

                              </Grid>
                            }
                          </>
                        );
                      })}
                    </Grid>
                    <Divider className={classes.customersHeadLayoutMargin} />

                    {mockStatus.map((status: string) => {
                      return (
                        <>
                      
                          { Object.keys(this.state.customersData).includes(status) &&
                            <>
                            <Grid className={classes.padding_16} container>
                              <Typography variant="body2" style={getStatusTextStyleCustomer(status)}>
                                {status === "active" ?
                                  this.getActiveLangStatus(this.state.lang, t, status)

                                  : this.getArchivedLangStatus(this.state.lang, t, status)}
                              </Typography>
                            </Grid>
                              <Divider style={getDividerTextStyleCustomer(status)} className={classes.marginTopHeadings} />
                            </>
                          }  
                         
                          {this.state.customersData[status]?.data.length === 0 &&
                            <Grid container justifyContent="center"
                              alignItems="center"
                              style={{ height: '150px' }}
                            >
                              <Grid item xs={12}>
                                <Typography variant="subtitle1"
                                  style={
                                    {
                                      ...webStyle.CustnoSupplierText,
                                     
                                    }}
                                  align="center">{t('customers.noDataFound')}.</Typography>
                              </Grid>
                            </Grid>
                          }
                          <Box style={{
                            maxHeight: '300px', overflow: 'scroll', minWidth: "fit-content"
                          }}>
                            {this.state.customersData[status]?.data.map((item: Customer) => {
                             
                              return (
                                <Grid container style={{
                                  maxHeight: '300px', overflow: 'scroll', minWidth: "fit-content"
                                }}>
                                  <Grid item xs={12} onClick={this.handleViewCustomerData.bind(this,item)}>
                                    <StyledGrid container
                                      style={{
                                        display: 'flex',
                                        width: '100%',
                                        flexWrap: 'nowrap',
                                        gridAutoFlow: 'column',
                                        zIndex: 0,
                                        position: 'relative',
                                        color:"#626262"
                                       
                                      }}
                                      data-test-id="moveHover"
                                      key={item.id}
                                    >
                                      {CustmockTableRow.map((subItem: CustomerMockDataProp) => {
                                        const isIdChecked = this.state.checkedColumn.includes(subItem.id);
                                        return (

                                          isIdChecked &&
                                          <Grid item data-test-id="viewData"
                                            style={{ width: `${(100 / this.state.checkedColumn.length)}%`,minWidth: "185px" }}
                                            className={this.props.classes.tableCellLayout}

                                          >
                                            {this.renderTypo(item, subItem, classes)}
                                          </Grid>


                                        )
                                      })}


                                    </StyledGrid>
                                    <Divider />
                                  </Grid>
                                </Grid>
                              )
                            })}
                          </Box>
                        </>
                      )
                    })}
                  </Grid>
                </Grid>
              </Box>
              <Box style={webStyle.CustbuttonsContainer}>
                <CreateQuote
                  style={{minWidth:"260px"}}
                  onClick={this.handleCreateCustomer}
                >
                  <Typography
                  variant="subtitle2"
                    style={webStyle.CustbuttonTextCreateSupplier}
                   
                  >
                 {t("customers.createCustomer")}
                  </Typography>
                </CreateQuote>
              </Box>
            </Container>
          </Box>
        </StyledRoot>
        {this.renderCircularBackdrop()}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: any) => ({
  marginTopHeadings:{marginTop:"8px", backgroundColor: "#74ab39"},
  tableCellLayout:{
  
    padding: '28px 16px 28px 22px !important'
  },
  padding_16: {
    padding: '16px 16px 0px 16px'
  },
  customersHeadLayoutMargin: {
    marginTop: '0.5rem'
  },
  menuItemStyle: {
    color: CONSTANTS.color.grey,
    fontWeight: theme.typography.fontWeightRegular,
  
  },
  CustboxContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 0.25rem 2rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "8rem !important",
    },
  },
  CustbuttonContainer: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100% !important",
      minHeight: "52px !important",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "145px",
      minHeight: "52px !important",
    },
  },
  CustbuttonText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px !important",
    },
  },
  CustdropDownSelectIcon: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0.5rem !important",
      width: "11px !important",
      height: "7px !important",
    },
  },
  CusttableHeadText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px !important",
    },
  },
  CusttableCell: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.0625rem !important",
    },
  },
  CustmenuItem: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.0625rem !important"
    }
  },
  customerBackIconContainer: {
    display: "flex",
    alignItems: "center",
    gap: "1rem"
  }
});

const webStyle = {
  CustsearchContainer: {
    display: "flex",
    alignItems: "center",
    minHeight: "56px",
    width: "100%",
    borderRadius: "8px",
    border: "1.5px solid rgba(0,0,0,0.1)",
  },
  CustsearchIcon: {
    height: "20px",
    width: "20px",
    margin: "0 0.6rem 0 1.3rem",
  },
  CustinputBase: {
    width: "100%",
    fontFamily: "Rubik",
    fontSize: "1.1667",
    fontWeight: 400,
    lineHeight: "16px",
    color:CONSTANTS.color.grey
  },
  CustdropDownSelectIcon: {
    width: "13px",
    height: "9px",
    cursor: "pointer",
  },
  CustdropDownSelectIconTable: {
    width: "13px",
    height: "9px",
    cursor: "pointer",
    marginLeft: "2rem",
    transform: "rotate(0deg)",
  },
  CuststatusModalContainer: {
    position: "absolute" as const,
    zIndex: 9,
    width: "182px",
    minHeight: "140px",
    borderRadius: "5px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
  },
  CuststatusModal: {
    padding: "1rem",
  },
  CustmenuItem: {
    color: CONSTANTS.color.grey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  CustapplyButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "1rem",
  },
  CustbuttonContainerApply: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#1c18af",
    cursor: "pointer",
    borderRadius: "3px",
    minHeight: "40px",
    maxWidth: "84px",
    width: "100%",
  },
  CustbuttonTextApply: {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  CusttoggleModalContainer: {
    position: "absolute" as "absolute",
    padding: "1rem",
    zIndex: 9,
    width: "254px",
    minHeight: "194px",
    borderRadius: "5px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
  },
  CusttoggleModal: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
  },
  CusttoggleIconsContainer: {
    display: "flex",
    alignItems: "center",
  },
  CusttoggleIcons: {
    width: "24px",
    height: "24px",
  },
  CustsuppliersContainer: {
    marginTop: "2rem",
    maxWidth: "100%",
    backgroundColor: "#ffffff",
    boxShadow: "0px 16px 17px rgba(28,24,175,0.07)",
    minHeight: "400x",
    overflow: 'scroll',
  },
  CusttableCell: {
   
    fontFamily: "Rubik",
    fontSize: "1.1667rem",
    fontWeight: 400,
    width:"20%",
    padding:"0.8rem",
    textAlign: "left" as const,
  },
  CusttableCellBody: {
    fontFamily: "Rubik",
    fontSize: "1.1667rem",
    fontWeight: 400,
    width:"20%",
    padding:"0.8rem",
    wordBreak:"break-word" as const
  },
  CusttableHeadSpan: {
    cursor: "pointer",
    borderRadius: "2px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#626262",
  },
  CusttableHeadText: {
    fontSize: "18px",
  },
  CustbuttonsContainer: {
    paddingTop: "3rem",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingBottom: "3rem",
  },
  CustbuttonTextCreateSupplier: {
    color: "#ffffff",
    fontWeight: 400,
    textAlign: "center" as "center",
  },
  CustnoSupplierText: {
    color: "#535353",
    fontWeight: 500,
    textAlign: "center" as const,
  },
};

const customerRouter :any = withStyles(styles)(Customers);
const customersOpenToast = withRouter(customerRouter);
const customersSnackBar = withSnackbar(customersOpenToast);
const customerPermission = withPermission(customersSnackBar);
export default withTranslation()(customerPermission)
// Customizable Area End
