import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
/* istanbul ignore file */
import DatePicker from "react-datepicker";
import React from "react";
import moment from "moment";
import {  filterListData } from "../../../components/src/CommonFunction";
import { handleViewData } from "../../CustomisableUserProfiles/src/utility.web";
import { getStorageData,setStorageData } from "../../../framework/src/Utilities";
export interface ToggleData{
  id:number,
  title:string,
  hidden:boolean
}

interface RowData {
  jobs: string;
  days: {
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
    sunday: string;
  };
  totalTime: string;
  status: string;
}

export interface Jobb {
  jobTitle: string;
  customerName: string;
  startDate: string;
  endDate: string;
}

export const configJSON = require("./config");

export interface TimesheetAttributes {
    id: string;
    type: string;
    attributes: {
      id: number;
      account_id: number;
      associate_id: number;
      associate_type: string;
      duration: string;
      status: string;
      date: string;
      start_time: string;
      end_time: string;
      employee_id: number | null;
      description: string | null;
      rate: number | null;
      billable: boolean;
      created_at: string;
      updated_at: string;
      employee_name: string | null;
      employee_email: string | null;
      customer_name: string;
      email: string;
      full_name: string
    };
  };

  export interface JobAttributes {
    visits: { data: [] };
    job_title: string;
    account_id: number;
    employee_id: number | null;
    request_id: number | null;
    site_id: number;
    customer_id: number;
    status: string;
    scheduling: string;
    custmoer_name: string;
    pdf_url: string | null;
    duration: number | null;
    summary: string | null;
    every: number;
    frequency: number | null;
    start_date: string;
    end_date: string;
    site_address: SiteAddress;
    quote: string | null;
    created_at: string;
    updated_at: string;
    internal_notes: string;
    product_and_services: string | null;
    employees: {name:string,img: string, mail:string}[];
    employee_image: [];
    company_logo: string | null;
    location: Location;
    start_time: string;
    end_time: string;
    files: unknown | null;
    products: Products;
    job_id: string;
  }

  export interface Location {
    address: string | null;
    latitude: string | null;
    longitude: string | null;
    city: string | null;
    zip_code: string | null;
  }
  
  export interface SiteAddress {
    address: string;
    latitude: string;
    longitude: string;
    city: string;
    state: string;
    zip_code: string;
  }
  
  export interface ProductAttributes {
    product_name: string;
    product_id: number;
    service_id: number | null;
    quantity: number;
    unit_price: string;
    currency: string;
    total: string;
    description: string;
    job_id: number;
    quote_id: number | null;
    visit_id: number | null;
  }
  
  export interface ProductItem {
    attributes: ProductAttributes;
    id: string;
    type: string;
    
  }
  
  export interface Products {
    data: ProductItem[];
  }
  
  export interface Jobs {
    id: string | number;
    type: string;
    attributes: JobAttributes;
    product?: {
      id: string;
      type: string;
      attributes: {
        product_id: number;
        service_id: number | null;
        quantity: number;
        unit_price: string;
        currency: string;
        total: string;
        description: string;
        job_id: number;
        quote_id: number | null;
        visit_id: number | null;
        product_name: string;
        service_name: string;
      };
    };
  }
  
export interface JobState {
    data: Jobs[];

}
interface TimeValues {
  hours: number;
  minutes: number;
}
  export interface JobsData {
    upcoming: JobState;
    in_progress: JobState;
    completed: JobState;
    to_be_invoiced: JobState;
    late: JobState;
    cancelled: JobState;
    message?:string
  }
  interface Request {
    id: string;
    type: string;
    attributes: {
      request_title: string;
      account_id: number;
      employee_id: number | null;
      status: string;
      site_id: number;
      service_detail: string;
      customer_id: number;
      on_site: boolean;
      appointment_date: string;
      all_day: boolean;
      internal_notes: string;
      converted_to_job: boolean;
      converted_to_quote: boolean;
      created_at: string;
      updated_at: string;
      appointment_time: string | null;
      job_id: number | null;
      other_source: string | null;
      source: string;
      custmoer_name: string;
      company_logo: string | null;
      appointment_id: number | null;
      files: string[] | null;
      pdf_url: string | null;
      employees: string[];
      employee_image: string[];
      request_id: string;
    };
  }
  
  interface RequestsByStatus {
    data: Request[];
  }
  
  interface RequestsData {
    pending: RequestsByStatus;
    quoted: RequestsByStatus;
    converted_to_job: RequestsByStatus;
    overdue: RequestsByStatus;
    cancelled: RequestsByStatus;
  }
  interface DayData {
    date: string;
    duration: string;
    associate_id: string;
    associate_type: string;
  }
  export interface ValueObject{
    attributes: {
      "admin_address": [],
      request_title?:string;
      'company_logo': null,
      'created_at': Date,
      'currency': string,
      'customer_id': number,
      'customer_name': string,
      'discount': string,
      'files': null,
      'invoice_deposits': [],
      'invoice_id': string,
      'issue_date': string,
      'job_id': string,
      'job_title': string,
      'newEngStatus': string,
      'notes': string,
      'payment_due': string,
      'pdf_url': {},
      'products': [],
      'quote_id': string,
      'site_id': string,
      'status': string
      'sub_total': number,
      'tax': number,
      'total': number
      'tax_name': string,
      'title': string,
      'updated_at': string,
      'id': string,
      'type': string,
      account_id: number;
      employee_id: number | null;
      request_id: number | null;
      scheduling: string;
      frequency: number | null;
      duration: number | null;
      summary: string | null;
      every: number;
      start_date: string;
      end_date: string;
      internal_notes: string;
      product_and_services: string | null;
      quote: string | null;
      employees: { name: string, img: string, mail: string }[];
      employee_image: [];
      location: Location;
      site_address: SiteAddress;
      start_time: string;
      end_time: string;
      custmoer_name: string;
    };
    id: string;
    type: string;
    product?: ProductItem;
  }

  
  interface Employee {
    data:{
    attributes: {
        full_name: string;
        phone_number: string | null;
        email: string;
        reset_password_token: string;
        status: string;
        first_name: string;
        last_name: string | null;
        country_code: string | null;
        country: string | null;
        city: string | null;
        street_address: string | null;
        language: string | null;
        post_code: string | null;
        latitude: number | null;
        longitude: number | null;
        terms: boolean | null;
        local: string | null;
        use_email_as_username: boolean;
        resend_email_invitation: boolean;
        online: boolean;
        default_billing_rate: string;
        employee_image: {
          url: string
        },
        profile_image: string
    }
  }
}
export interface ProductItem {
  id: string;
  type: string;
  attributes: ProductAttributes;
}

export interface ProductAttributes {
  product_id: number;
  service_id: number | null;
  quantity: number;
  unit_price: string;
  currency: string;
  total: string;
  description: string;
  job_id: number;
  quote_id: number | null;
  visit_id: number | null;
  product_name: string;
  service_name:string;
}

  export interface AttributesArray {
    data: ValueObject[]
  }
  interface Permission {
    id: number;
    section: string;
    feature_name: string;
    created_at: string;
    updated_at: string;
    plan: string;
    feature: boolean;
  }
  interface InputValues {
    [dayIndex: number]: TimeValues;
  }
  export interface EmailAccount {
    id: string;
    type: string;
    attributes: {
      full_name: string;
      first_name: string;
      last_name: string;
      full_phone_number: string;
      country_code: number;
      phone_number: number;
      email: string;
      country_name: string;
      company_name: string;
      company_size: string;
      post_code: string | null;
      street_address: string;
      activated: boolean;
      city: string;
      language: string;
      terms: boolean;
      local: string | null;
      tap_account_id: string;
      industrie: string | null;
      other_industry: string | null;
      other_profile_question: string | null;
      heard_from: string | null;
      company_logo: string | null;
      profile_image: string;
      subscription_plan: string;
      subscription_plan_id: number;
      permissions: Permission[];
      employee_image:{
        url: string;
      }
    };
  }
  
  export interface EmailAccountProps {
    data: EmailAccount[];
  }
// Customizable Area End



export interface Props {
  // Customizable Area Start
  t: (keys: string) => string;
  isPopupOpen: boolean;
  cancelBtnText: string;
  saveBtnText: string;
  languageSelected: string
  openToastHandler:Function
  isTextFieldVisible: boolean,


  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: null | HTMLElement;
  isPopupOpen: boolean;
  cancelBtnText: string;
  saveBtnText: string;
  startTime: string 
  timeFormat: string,
  isTextFieldVisible: boolean,
  languageSelected:string,
  showSelectJobRequestButton:boolean,
  openJobRequestDialog: boolean,
  startDate:Date,
  timeSheetState:TimesheetAttributes[];
  timeSheetDuplicate: TimesheetAttributes[];
  selectedEmployee:  EmailAccount | null;
  token:string
  open: boolean;
  selectedType: string;
  jobButtonColor: "default" | "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | undefined;
  requestButtonColor: "default" | "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | undefined;
  searchJob: string;
  selectedDateRange: string;
  selectDate:Date | string;
  anchorEls: null | HTMLElement;
  statusModal: boolean;
  dateModal: boolean;
  endDate: Date | string;
  teamMemberModal: null | HTMLElement;
  toggleModal: boolean;
  information: boolean;
  purchases: boolean;
  status: string;
  suppliersData: [];
  getJobsLoading: boolean;
  sortDirection: string;
  sortColumn: string;
  statusToggleData: { 
    id: number;
    title: string;
    statusName: string;
     hidden: boolean }[];
  searchJobs: string;
  allJobs: Record<string,AttributesArray> | JobsData | {};
  statusName: string
  checkStatusName: string
  sigleStatusJob: []
  selectJobRowId: string | number
  hardDeleteStatus: boolean
  toasterStatus: boolean
  toasterText: string
  jobDeletedStatus: boolean
  hoverJobRow: number | string;
  selectedLanguage: string
  selectedDate: {
    startDate: Date,
    endDate: null | Date
  },
  dateStatus: string;
  dayDuration:string;
  requestData:{
    requestTitle: string;
    requestDescription: string;
  }[]
  getRequestsLoading: boolean;
  searchRequest: string;
  allrequest: Record<string,AttributesArray>  | {} | RequestsData;
  timeSheetStatus:string
  role:string
  employeeName:string
  employeeEmail:string
  editTime:boolean
  selectLanguage:string
  endTime:string 
  duration:string
  editId: string
  currentStatus:string,
  customerName:string,
  additionalRows:number,
  addDuration:string,
  jobAsscociateId: string | number,
  saveButton:boolean,
  empImg:string,
  employeeState: EmailAccount[],
  addToggle:boolean,
  inputValues:InputValues,
  totalHours:string,
  dayData:DayData[],
  dateSelects:Date | string,
  typeJob:string,
  boName:string,
  boLastName:string | null,
  boProfilImage:string,

  // Customizable Area End
}

interface SS {
  // Customizable Area Start

  datePickerRef: React.RefObject<DatePicker>
  // Customizable Area End
}

export default class TimeSheetWeeklyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  searchJobsApiCallId: string = "";
  jobsStatusApiCallId: string = "";
  apiCallIdTimesheet :string = "";
  apiCallIdTimesheetMenu: string = "";
  deleteJobApiCallId: string = "";
  datePickerRef: React.RefObject<DatePicker>
  searchRequestApiCallId: string = "";
  getStatusRequestsApiCallId: string = "";
  userProfileDataCallApiId:string = "";
  editTimesheetApiCallId:string = "";
  deleteDataCallApiId:string = "";
  statusEmployeeApiCallId:string = "";
  addTimesheetApiCallId:string = "";
  employeeDataCallApiId:string = "";
  getMyTimeSheetApiCallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      timeSheetStatus:"Employee",
      anchorEl: null,
      isPopupOpen: false,
      cancelBtnText: 'Cancel',
      saveBtnText: 'Save',
      startTime: "",
      timeFormat: '',
      isTextFieldVisible: false,
      languageSelected:'en',
      startDate:new Date(),
      timeSheetState: [] ,
      timeSheetDuplicate: [],
      selectedEmployee: null,
      token:"",
      showSelectJobRequestButton: false,
      openJobRequestDialog: false,
      open: false,
      selectedType: "Job",
      jobButtonColor: "primary",
      requestButtonColor: "default",
      searchJob: "",
      selectedDateRange: "Today",
      selectDate:new Date(),
      anchorEls: null,
      statusModal: false,
      dateModal: false,
      teamMemberModal: null,
      endDate: new Date(),
      toggleModal: false,
      information: false,
      purchases: false,
      status: "all",
      suppliersData: [],
      getJobsLoading: false,
      sortDirection: "asc",
      sortColumn: "",
      statusToggleData: [
        { id: 1, title: `${this.props.t('request.statusMenu.all')}`, statusName: "all", hidden: false },
        { id: 2, title: `${this.props.t('jobs.statusMenu.upcoming')}`, statusName: "upcoming", hidden: false },
        { id: 3, title: `${this.props.t('jobs.statusMenu.inProgress')}`, statusName: "in_progress", hidden: false },
        { id: 4, title: `${this.props.t('jobs.statusMenu.invoiced')}`, statusName: "invoiced", hidden: false },
        { id: 5, title: `${this.props.t('jobs.statusMenu.completed')}`, statusName: "completed", hidden: false },
        { id: 6, title: `${this.props.t('jobs.statusMenu.late')}`, statusName: "late", hidden: false },
        { id: 7, title: `${this.props.t('request.statusMenu.cancelled')}`, statusName: "cancelled", hidden: false },
      ],
      searchJobs: "",
      allJobs: {},
      statusName: "All",
      checkStatusName: "All",
      sigleStatusJob: [],
      selectJobRowId: "",
      hardDeleteStatus: false,
      toasterStatus: false,
      toasterText: "",
      jobDeletedStatus: false,
      hoverJobRow: 0,
      selectedLanguage: '',
      selectedDate: {
        startDate: new Date(),
        endDate: null
      },
      dateStatus: "Today",
      dayDuration: "Today",
      getRequestsLoading: false,
      searchRequest: "",
      allrequest:{},
      role:"",
      requestData:[
        {
          requestTitle: "Request 1",
          requestDescription: "This is request 1",
        },
        {
          requestTitle: "Request 2",
          requestDescription: "This is request 2",
        },
        {
          requestTitle: "Request 3",
          requestDescription: "This is request 3",
        },
      ],
      employeeName:"",
      employeeEmail:"",
      editTime:false,
      selectLanguage:'',
      endTime:'',
      duration:'',
      editId: '',
      currentStatus:"",
      customerName:"",
      additionalRows:0,
      addDuration:"00:00",
      jobAsscociateId:"0",
      saveButton:false,
      empImg:'',
      employeeState:[],
      addToggle:false,
      inputValues:{} as InputValues,
      totalHours:"",
      dayData:[],
      dateSelects:new Date(),
      typeJob:"Job",
      boName:"",
      boLastName:"",
      boProfilImage:""
    };
    this.datePickerRef = React.createRef<DatePicker>();
   

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    // Customizable Area Start
    const authToken = await getStorageData("authToken");
    const employeeRole = await getStorageData("isEmployee")
    this.setState({role:employeeRole})
    this.setState({ token:authToken  });
    this.addRole()
    this.getAllEmployee()
    this.handleJobSorting();
    this.sortRequestManage();
    this.getEmployee();
    const timeSheetStatus = await getStorageData("timeSheetStatus")
    // istanbul ignore next
    if(timeSheetStatus === "Employee"){
      this.setState({timeSheetStatus:"Employee"})
    }
    const selectedLanguage = await getStorageData("lang");
    this.setState({ selectedLanguage: selectedLanguage })
    window.scrollTo(0, 0);
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.apiCallIdTimesheet || apiRequestCallId === this.getMyTimeSheetApiCallId) {
      if (responseJson && !responseJson.errors) {
        this.handleTimeSheet(responseJson)
      } 
    }
    this.callingReciveFun(apiRequestCallId, responseJson)


  }

  let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  if (apiRequestCallId === this.jobsStatusApiCallId) {
    if (responseJson && !responseJson.errors) {
      this.handleMessage(responseJson)
    }
  }
  this.callReceive(apiRequestCallId,responseJson)
    // Customizable Area End
  }

  // Customizable Area Start
  callingReciveFun = (apiRequestCallId:string|undefined, responseJson: {data:TimesheetAttributes[],errors?:string}) => {
    if (apiRequestCallId === this.apiCallIdTimesheetMenu) {
      if (responseJson && !responseJson.errors) {
        this.handleTimeSheet(responseJson)
      }
    }

    if (apiRequestCallId === this.getStatusRequestsApiCallId) {
      if (responseJson && !responseJson.errors) {
        this.handleRequestData(responseJson as unknown as JobsData)
      }
    }
    if (apiRequestCallId === this.userProfileDataCallApiId) {
      if (responseJson && !responseJson.errors) {
       
         this.handleEmployee(responseJson as unknown  as Employee)
      }
    }
  }
  //istanbul ignore next
  addRole = async() => {
    if(this.state.role === "true"){
      this.getMyTimeSheetDataAPI()
      this.setState({addToggle:true})
    }else{
      this.setState({addToggle:false})
      const stable = await getStorageData("timesheetVieww")
      if(stable === "0"){
        this.setState({timeSheetStatus:"Time"})
        this.getTimesheet()
      }
     
    }
  }
  getJobHeadingStyle = (keyValue: string) => {
    let textColor, dividerColor;
    switch (keyValue) {
        case "in_progress":
            textColor = "orange";
            dividerColor = "orange";
            break;
        case "completed":
            textColor = "green";
            dividerColor = "green";
            break;
        case "to_be_invoiced":
            textColor = "pink";
            dividerColor = "pink";
            break;
        case "upcoming":
            textColor = "red";
            dividerColor = "red";
            break;
        case "late":
            textColor = "chocolate";
            dividerColor = "chocolate";
            break;
        default:
            textColor = "gray";
            dividerColor = "gray";
            break;
    }
    return { textColor, dividerColor };
}
// istanbul ignore next
callReceive=(apiRequestCallId:string|undefined, responseJson: {data:TimesheetAttributes[],errors?:string}) =>{

  this.employeeReciveCall(apiRequestCallId,responseJson)

  if (apiRequestCallId === this.addTimesheetApiCallId) {
    if (responseJson && !responseJson.errors) {
      this.setState({
        additionalRows:0, 
      }, () => {
        if(this.state.role === "true"){
          this.getMyTimeSheetDataAPI()
        }else{
          this.getTimesheet();

        }
      });
    } 

  
}
this.employeeListRecive(apiRequestCallId, responseJson)
this.editTimesheetApi(apiRequestCallId, responseJson)
}
/* istanbul ignore next */
employeeReciveCall=(apiRequestCallId:string|undefined, responseJson: {data:TimesheetAttributes[],errors?:string}) => {
  if (apiRequestCallId === this.statusEmployeeApiCallId) {
    if (responseJson && !responseJson.errors) {
      this.getTimesheet();
    } 
  }
  if (apiRequestCallId === this.deleteDataCallApiId) {
    if (responseJson && !responseJson.errors) {
      if(this.state.role === "true"){
        this.getMyTimeSheetDataAPI()
      }else{
      this.getTimesheet();
      }
    } 
  }
}
editTimesheetApi = (apiRequestCallId:string|undefined, responseJson: {data:TimesheetAttributes[],errors?:string}) => {
  if (apiRequestCallId === this.editTimesheetApiCallId) {
    if (responseJson && !responseJson.errors) {
      if(this.state.role === "true"){
        this.getMyTimeSheetDataAPI()
      }else{
      this.getTimesheet();
      }
    } 
  }
}
employeeListRecive=(apiRequestCallId:string|undefined, responseJson: {data:TimesheetAttributes[],errors?:string})=>{
  if (apiRequestCallId === this.employeeDataCallApiId) {
    if (responseJson && !responseJson.errors) {
      this.handleAllEmployee(responseJson as unknown as EmailAccountProps)
    } 
  }
}
handleAllEmployee = (responseJson:EmailAccountProps)=> {
  this.setState({employeeState:responseJson.data})
}
//istanbul ignore next
handleEmployee = (responseJson : Employee) => {
  this.setState({
    boLastName:responseJson.data?.attributes?.last_name,
    boName:responseJson.data.attributes.first_name,
    employeeName:responseJson.data.attributes.full_name,
    employeeEmail:responseJson.data.attributes.email,
    empImg:responseJson.data.attributes.employee_image && responseJson.data.attributes.employee_image.url,
    boProfilImage:responseJson.data.attributes.profile_image
  })
}
getRequestHeadingStyle = (keyValue: string) => {
  let textColor, dividerColor;
  switch (keyValue) {
      case "pending":
          textColor = "#6764d6";
          dividerColor = "#6764d6";
          break;
      case "quoted":
          textColor = "#fa6400";
          dividerColor = "#fa6400";
          break;
      case "converted_to_job":
          textColor = "#74ab39";
          dividerColor = "#74ab39";
          break;
      case "overdue":
          textColor = "#e02020";
          dividerColor = "#e02020";
          break;
      case "cancelled":
          textColor = "#8C8C8C";
          dividerColor = "#8C8C8C";
          break;
      default:
          textColor = "gray";
          dividerColor = "gray";
          break;
  }
  return { textColor, dividerColor };
}

  handleMessage = (responseJson: JobsData) => {
    if (responseJson.message === "you are not authorized") {
      this.setState({ getJobsLoading: false });
      this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
    } else {
      this.setState({ getJobsLoading: false });
      this.setState({ allJobs: filterListData(responseJson) }, () => {
        this.handleStatusJobModalClose()
      })
    }
  }
  
  handleRequestData = (responseJson: JobsData) => {
      this.setState({ getJobsLoading: false });
      this.setState({ allrequest: responseJson}, () => {
        this.handleStatusJobModalClose()
      })
    }

  handleClick = (event: React.MouseEvent<HTMLElement>, editId: string) => {
    this.setState({ anchorEl: event?.currentTarget, editId });
  };

  handleClickss = (event: React.MouseEvent<HTMLElement>) => {
    if(this.state.role === "false"){
    this.setState({ anchorEls: event?.currentTarget });
    }
  };

  handleTimeSheet = (response: {data:TimesheetAttributes[]}) => {
    this.setState({timeSheetState:response.data || [], timeSheetDuplicate: response.data || []})
  }

  handleEdit = () => {
    const findData = this.state.timeSheetState.find((item) => item.id == this.state.editId)
    this.setState({ anchorEl: null,startTime: this.convertTo12HourFormatWithoutAmPm(findData?.attributes.start_time || ""),endTime:this.convertTo12HourFormatWithoutAmPm(findData?.attributes.end_time || "")});
    this.setState({ anchorEls: null });
    this.setState({ editTime: true });
  };
   


  handleDeleteId = async() => {
    const findData = this.state.timeSheetState.find((item) => item.id == this.state.editId)?.attributes?.associate_id
    const idArr = this.state.timeSheetState.filter((item) => findData === item.attributes?.associate_id)
    await Promise.all(idArr.map((element) => this.handleDeleteEmployee(element?.id || "")))
    
    this.setState({ anchorEl: null });
    this.setState({ anchorEls: null });
    this.setState({ showSelectJobRequestButton: false ,editTime :false});
  };
  
  handleClose = () => {
    this.setState({ anchorEl: null });
    this.setState({ anchorEls: null });
    this.setState({ showSelectJobRequestButton: false ,editTime :false});
  };

  handleCloseJobRequestDialog = () => {
    this.setState({ openJobRequestDialog: false });
  };

  handleOpenJobRequestDialog = () => {
    this.setState({ openJobRequestDialog: true });
  };

  handleAddEntriesClick = () => {
    this.setState({ showSelectJobRequestButton: true });
  }

  dateChange = (dates : string) => {
    this.setState({selectDate:dates,dateSelects:dates})
    if(this.state.timeSheetStatus === "Time"){
      this.getTimesheet(dates)
    }else{
      this.timesheetClick(dates)
    }
  }

  handleClickNextDate = () => {
    const newDate = new Date(this.state.selectDate);
    const newDate1 = new Date(this.state.dateSelects)
    newDate.setDate(newDate.getDate() + 7);
    newDate1.setDate(newDate1.getDate() + 7)
    this.setState({selectDate:newDate,dateSelects:newDate1})
    
    if(this.state.timeSheetStatus === "Time"){
      this.getTimesheet(newDate)
    }else{
      this.timesheetClick(newDate)
    }
  };

  handleClickPrevDate = () => {
    const newDate = new Date(this.state.selectDate);
    const newDate1 = new Date(this.state.dateSelects);
    newDate.setDate(newDate.getDate() - 7);
    newDate1.setDate(newDate1.getDate() - 7);

    this.setState({selectDate:newDate,dateSelects:newDate1})
    if(this.state.timeSheetStatus === "Time"){
      this.getTimesheet(newDate)
    }else{
      this.timesheetClick(newDate)
    }
    
  };
  TotalHoursCountFunction = (date:string) => {
    
    const fillterData = this.state.timeSheetState.filter((items:TimesheetAttributes) => items.attributes.date === date).map((items:TimesheetAttributes) => items.attributes.duration)
    let totalMinutes = 0;
    fillterData.forEach((time:string) => {
      const [hours, minutes] = time.split(':').map(Number);
      totalMinutes += hours * 60 + minutes;
    });
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const total = `${hours}:${minutes.toString().padStart(2, '0')}`

    return total
  }

  dateSelectWeek = ( weekdays ?: string | Date ) => {
    const startOfWeek = weekdays ? moment(weekdays).startOf('isoWeek') : moment().startOf('isoWeek');
    const endOfWeek = weekdays ?  moment(weekdays).endOf('isoWeek') : moment().endOf('isoWeek');

    const days = [];
    let dayOf = startOfWeek;

    while (dayOf <= endOfWeek) {
      days.push(dayOf.clone());
      dayOf.add(1, 'd');
    }

    return days;
  }
  dateFormateFunction = (date:string| Date) => {
    const dates = moment(date).format("MMM")
    return dates
  }

  curretDate = () => {
    const dates = moment().format("YYYY-MM-DD")
    return dates
  }

  handleTypeChange = (type: string) => {
    this.setState({typeJob:type})
    const jobs = [
      { id: 1, title: `${this.props.t('request.statusMenu.all')}`, statusName: "all", hidden: false },
      { id: 2, title: `${this.props.t('jobs.statusMenu.upcoming')}`, statusName: "upcoming", hidden: false },
      { id: 3, title: `${this.props.t('jobs.statusMenu.inProgress')}`, statusName: "in_progress", hidden: false },
      { id: 4, title: `${this.props.t('jobs.statusMenu.invoiced')}`, statusName: "invoiced", hidden: false },
      { id: 5, title: `${this.props.t('jobs.statusMenu.completed')}`, statusName: "completed", hidden: false },
      { id: 6, title: `${this.props.t('jobs.statusMenu.late')}`, statusName: "late", hidden: false },
      { id: 7, title: `${this.props.t('request.statusMenu.cancelled')}`, statusName: "cancelled", hidden: false },
    ]
    const Request = [
      { id: 1, title: `${this.props.t('request.statusMenu.all')}`, statusName: "all",hidden: false },
      { id: 2, title: `${this.props.t('request.statusMenu.pending')}`, statusName: "pending",hidden: false },
      { id: 3, title: `${this.props.t('request.statusMenu.quoted')}`, statusName: "quoted",hidden: false},
      { id: 4, title: `${this.props.t('request.statusMenu.convertedToJob')}`, statusName: "converted_to_job", hidden: false  },
      { id: 5, title: `${this.props.t('request.statusMenu.overdue')}`, statusName: "overdue",hidden: false },
      { id: 6, title: `${this.props.t('request.statusMenu.cancelled')}`, statusName: "cancelled",hidden: false },
    ]
    this.setState({
      selectedType: type,
      jobButtonColor: type === "Job" ? "success" : "primary",
      requestButtonColor: type === "Request" ? "success" : "primary",
      statusToggleData:type === "Job" ? jobs : Request
    });
  };
  getTimesheet = async(dates?:string | Date) => {
    const token =await getStorageData("authToken")
    const header = {
        "Content-Type": "application/json",
        "token": token
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.apiCallIdTimesheet = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.timeSheetEndPoint}/timesheets?date=${moment(dates ?? this.state.selectDate)}&view=weekly`,
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleChangeSearchJobs = (event:  React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchJobs: event.target.value }, () => {
      this.handleJobSorting();
    });
  }

  handleStatusJobModalOpen = () => {
    this.setState({
      statusModal: !this.state.statusModal,
      searchJobs: ""
    });
    this.setState({ toggleModal: false, dateModal: false });
  }

  handleDateModalOpen = () => {
    this.setState({
      dateModal: !this.state.dateModal,
    });
    this.setState({ toggleModal: false, statusModal: false });
  }

  handleJobSelectStatus = (jobs: ToggleData) => {
    const newToggleData = this.state.statusToggleData.map((item) => {
      if (item.id === jobs.id) {
        return { ...item, hidden: !item.hidden };
      }
      return item;
    });

    const anyHidden = newToggleData.some((item) => item.hidden);

    const selectedStatusNames = anyHidden
      ? newToggleData
        .filter((item) => item.hidden)
        .map((item) => item.statusName)
        .join(',')
      : "all";

    this.setState({
      statusToggleData: newToggleData,
      status: selectedStatusNames,
      checkStatusName: jobs.title
    }, () => {
      this.handleJobSorting();
    });
  }

  handleSortJob = (columnKey: string) => {
    if (!(columnKey === "team" || columnKey === "product_name")) {
      let newSortDirection: "asc" | "desc" = "asc";
      if (columnKey === this.state.sortColumn) {
        newSortDirection = this.state.sortDirection === "asc" ? "desc" : "asc";
      }
      this.setState({
        sortDirection: newSortDirection,
        sortColumn: columnKey
      }, () => {
        this.handleJobSorting()
      });
    }
  };

  handleViewJob = async (item: string | number) => {
    const isAuthorized = await handleViewData("job");
    this.handleViewJobDataCallback(isAuthorized, item)
  }

  handleViewJobDataCallback = (isAuthorized?: string[], item?: string | number) => {
    if (!(isAuthorized as string[]).includes("VIEW")) {
      this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
    }
  }

  handleStatusJobModalClose = () => {
    this.setState({
      statusModal: false,
    })
  }

  handleStatusModalClose = () => {
    const { t } = this.props;
    if (this.state.dateStatus !== t('dashboard.custom')) {
      this.setState({ dateModal: false });
    }
  }
  handleDateChange = (dates: [Date, null | Date]) => {
    const [startDate, endDate] = dates;
    this.setState({
      selectedDate: { ...this.state.selectedDate, startDate: startDate, endDate: endDate },
    }, () => {
      if (startDate && endDate) {
        this.handleJobSorting();
        this.setState({ getJobsLoading: true });
        this.setState({ dateModal: false });
      }
    });
  };
  handleSelectDateStatus = (item: { title: string; }) => {
    const { t } = this.props;
    const { startDate, endDate } = this.state.selectedDate;
    this.setState({ dateStatus: item.title }, () => {
      if (item.title === t('dashboard.custom')) {
        if (startDate && endDate) {
          this.handleJobSorting();
          this.sortRequestManage();
          this.setState({ getJobsLoading: true });
        }
      } else {
        this.handleJobSorting();
        this.sortRequestManage();
        this.setState({ getJobsLoading: true });
      }
    });
    if (item.title !== t('dashboard.custom')) {
      this.handleStatusModalClose()
    }
  }

  goToDailyPage = async () => {
    setStorageData("timeSheetStatus",this.state.timeSheetStatus)
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "Timesheet"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage),
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };
  /* istanbul ignore next */
  goToWeekly = () => {
    if(this.state.role === "true"){
      this.getMyTimeSheetDataAPI()
    }else{
      this.timesheetClick()
    }
  }

  timesheetClick = (dates?:string | Date, search?:string) => {
    const header = {
      "Content-Type": "application/json",
      "token": this.state.token
  };

  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  
  this.apiCallIdTimesheetMenu = requestMessage.messageId;

  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.timeSheetEmpEndPoint}/employee_timesheets?date=${moment(dates ?? this.state.selectDate)}&view=weekly&query=${search}`,
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  statusAr = (status: string) => {
  let {dayDuration} =this.state
    if (status === "'DJHE") {
      dayDuration = "today";
    } else if (status === "G0' 'D'3(H9") {
      dayDuration = "this_week";
    } else if (status === "G0' 'D4G1") {
      dayDuration = "this_month";
    } else if (status === "G0' 'D9'E") {
      dayDuration = "this_year";
    } else if (status === "E.55") {
      dayDuration = "custom";
    }
    return dayDuration;
  }

  handleSearchTimeSheet = (event: EmailAccount) => {
    this.setState({selectedEmployee: event})
    this.timesheetClick(this.state.selectDate, event?.attributes?.full_name)
  }

  handleJobSorting = async () => {
    const language = await getStorageData("lang");
    const { startDate, endDate } = this.state.selectedDate;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    const apiEndPoint = configJSON.JobsEndPoint
    let jobsUrl
    if (this.state.searchJobs) {
      jobsUrl = `${apiEndPoint}?status=${this.state.status}&query=${this.state.searchJobs}&lang=${language}&start_date=${startDate}&end_date=${endDate}&date_filter=${language === "ar" ? this.statusAr(this.state.dateStatus) : this.state.dateStatus.toLowerCase().replace(/ /g, "_")}`
    } else if (this.state.sortColumn) {
      jobsUrl = `${apiEndPoint}?status=${this.state.status}&field=${this.state.sortColumn}&order=${this.state.sortDirection}&lang=${language}&start_date=${startDate}&end_date=${endDate}&date_filter=${language === "ar" ? this.statusAr(this.state.dateStatus) : this.state.dateStatus.toLowerCase().replace(/ /g, "_")}`
    } else {   
      jobsUrl = `${apiEndPoint}?status=${this.state.status}&lang=${language}&start_date=${startDate}&end_date=${endDate}&date_filter=${language === "ar" ? this.statusAr(this.state.dateStatus).toLowerCase().replace(/ /g, "_") : this.state.dateStatus.toLowerCase().replace(/ /g, "_")}`
    }
    this.setState(
      { getJobsLoading: true }
    )

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.jobsStatusApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), jobsUrl);
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  sortRequestManage = async () => {
    const { startDate, endDate } = this.state.selectedDate;
    const language = await getStorageData("lang");
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": this.state.token
    };
    const apiEndPoint = configJSON.getAllRequest
    let requestsUrl
    if (this.state.searchRequest&&this.state.sortColumn) {
      requestsUrl = `${apiEndPoint}?status=${this.state.status}&query=${this.state.searchRequest}&field=${this.state.sortColumn}&order=${this.state.sortDirection}&lang=${language}&start_date=${startDate}&end_date=${endDate}&date_filter=${language === "ar" ? this.statusAr(this.state.dateStatus) : this.state.dateStatus.toLowerCase().replace(/ /g, "_")}`
    }else if(this.state.searchRequest){
      requestsUrl = `${apiEndPoint}?status=${this.state.status}&query=${this.state.searchRequest}&lang=${language}&start_date=${startDate}&end_date=${endDate}&date_filter=${language === "ar" ? this.statusAr(this.state.dateStatus) : this.state.dateStatus.toLowerCase().replace(/ /g, "_")}`
    }else if (this.state.sortColumn) {
      requestsUrl = `${apiEndPoint}?status=${this.state.status}&field=${this.state.sortColumn}&order=${this.state.sortDirection}&lang=${language}&start_date=${startDate}&end_date=${endDate}&date_filter=${language === "ar" ? this.statusAr(this.state.dateStatus) : this.state.dateStatus.toLowerCase().replace(/ /g, "_")}`
    }else{
      requestsUrl = `${apiEndPoint}?status=${this.state.status}&lang=${language}&start_date=${startDate}&end_date=${endDate}&date_filter=${language === "ar" ? this.statusAr(this.state.dateStatus) : this.state.dateStatus.toLowerCase().replace(/ /g, "_")}`
    }
    this.setState(
        {getRequestsLoading:true}
    )

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getStatusRequestsApiCallId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage), requestsUrl);
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  findClassesJobs = (keys: string) => {
    return keys === "in_progress" ? "statusKeysText" : this.findJobsClasses(keys)
  }

  findJobsClasses = (keys: string) => {
    return keys === "completed" ? "statusKeysTextQuoted" : this.findJobsClassesv1(keys)
  }

  findJobsClassesv1 = (keys: string) => {
    return keys === "to_be_invoiced" ? "statusKeysTextConverted" : this.findJobsClassesv2(keys)
  }

  findJobsClassesv2 = (keys: string) => {
    return keys === "late" ? "statusKeysTextOverdue" : "statusKeysTextCancelled"
  }

  renderHandleViewJob = (item: Jobs | ValueObject) => {
    this.setState({openJobRequestDialog:false,customerName:item.attributes.custmoer_name,
      showSelectJobRequestButton:false,jobAsscociateId:item.id,saveButton:true
    })
    this.setState((prevState) => ({
      additionalRows: prevState.additionalRows + 1,
    }));
  }

  onClickStatusChange = async(value: string) => {
    this.setState({ timeSheetStatus: value })

    if (value === "Time") {
      this.setState({addToggle:true})
      this.getTimesheet();   
      this.getEmployee();
    } else {  
      this.setState({addToggle:false})
      this.timesheetClick()
    }
  }
  /* istanbul ignore next */
   getEmployee = async () => {
    const employee_id = await getStorageData("employeeId");
    const account_id = await getStorageData("account_id");
    let urles; 
    const headers = {
      "Content-Type": "application/json",
      "token": this.state.token
    };
    if(this.state.role === "false"){
    urles = `account_block/accounts/${account_id}`;
    }else{
       urles = `account_block/employees/${employee_id}`
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileDataCallApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${urles}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handlePopupClose = () => {
    this.setState({ isPopupOpen: false });
    this.setState({ isTextFieldVisible: false });
  };
  handlePopupDelete = () => {
  
    this.setState({ isPopupOpen: false, cancelBtnText: 'Delete', saveBtnText: 'Save' });
  };
  handleChangeHours =(timevalue:string)=>{
    this.setState({
      startTime:timevalue
    }, this.calculateTotalTime)
  }
  handleChangeHoursendTime =(timevalue:string)=>{
    this.setState({
      endTime:timevalue
    }, this.calculateTotalTime)
  }
  calculateTotalTime = () => {
    const { startTime, endTime } = this.state;

    if (startTime && endTime) {
      const startMinutes = this.convertTimeToMinutes(startTime);
      const endMinutes = this.convertTimeToMinutes(endTime);
      let totalMinutes = endMinutes - startMinutes;
      if (totalMinutes < 0) {
        totalMinutes += 24 * 60; 
      }
      const totalTime = this.convertMinutesToTime(totalMinutes);
      this.setState({duration : totalTime });
    }
  }
  convertTimeToMinutes = (time : string):number => {
    if (!time || typeof time !== 'string') {
      return 0
      
  }else{
    const [timePart, modifier] = time && time.split(' ');
    let [hours, minutes] = timePart.split(':').map(Number);

    if (modifier === 'PM' && hours < 12) {
      hours += 12;
    } else if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }

    return hours * 60 + minutes;
  }
   
  }
  convertMinutesToTime = (totalMinutes : number): string => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${Math.abs(hours).toString().padStart(2, '0')}:${Math.abs(minutes).toString().padStart(2, '0')}`;
  }

  employeeeStatus = (statusId:string) => {
   this.setState({editId:statusId})
   
  }

   convertTo12HourFormatWithoutAmPm(dateString: string): string {
    
    const date = new Date(dateString);
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; 

    const minutesStr = minutes < 10 ? '0' + minutes : minutes;
    const hoursStr = hours < 10 ? '0' + hours : hours;

    return `${hoursStr}:${minutesStr} ${ampm}`;
}
parseTime = (time: string): TimeValues => {
  const [hoursStr, minutesStr] = time.split(':').map(Number);
  const hours = isNaN(hoursStr) ? 0 : hoursStr;
  const minutes = isNaN(minutesStr) ? 0 : minutesStr;
  return { hours, minutes };
};
formatTime = (hours: number, minutes: number): string => {
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};
formatDate = (date: Date): string => {
  return date.toISOString().split('T')[0];
};


// istanbul ignore next
handleHoursDurations = (event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, dayIndex: number) => {
  const { value } = event.target;
  const timeValues = this.parseTime(value);

  this.setState(
    (prevState) => {
      const inputValues = {
        ...prevState.inputValues,
        [dayIndex]: timeValues,
      };
      return { inputValues };
    },
    () => {
      this.updateTotalHours();
      this.updateDayData(dayIndex);
    }
  );
};

// istanbul ignore next
updateTotalHours = () => {
  const { inputValues } = this.state;
  let totalHourss = 0;
  let totalMinutes = 0;
  (Object.values(inputValues) as TimeValues[]).forEach(({ hours, minutes }) => {
    totalHourss += hours;
    totalMinutes += minutes;
  });
  totalHourss += Math.floor(totalMinutes / 60);
  totalMinutes = totalMinutes % 60;

  const totalFormattedTime = this.formatTime(totalHourss, totalMinutes);
  this.setState({ totalHours: totalFormattedTime });
};
// istanbul ignore next
updateDayData = (dayIndex: number) => {
  const { inputValues, dayData,dateSelects } = this.state;
  const date = new Date(dateSelects);
  const dayOfWeek = date.getDay();
  date.setDate(date.getDate() + dayIndex - dayOfWeek + 1);

  const dayDuration = inputValues[dayIndex];
  const duration = this.formatTime(dayDuration?.hours || 0, dayDuration?.minutes || 0);

  
  const updatedDayData = dayData.filter((dfax) => dfax.date !== this.formatDate(date));

  updatedDayData.push({
    date: this.formatDate(date),
    duration: duration,
    associate_id: String(this.state.jobAsscociateId),
    associate_type: `BxBlockRequestManagement::${this.state.typeJob}`,
  });

  this.setState({ dayData: updatedDayData });
};
  handleHoursDuration = (event: React.ChangeEvent<HTMLInputElement>) => {
    
    this.setState({ addDuration: event.target.value })
  }
  /* istanbul ignore next */
  handleSave = (editId:string) => {
    this.setState({editTime :false}); 
    const {endTime,startTime,token,duration } = this.state
    
    const header = {
      token: token,
    };
   const editUrls = `${configJSON.getEditTimesheetAPiEndPoint}/${editId}`
    const formData = new FormData();
    
    formData.append("data[start_time]",startTime);
    formData.append("data[end_time]",endTime);
    formData.append("data[duration]",duration);
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.editTimesheetApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
         editUrls
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PATCH"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
     

  }

  handleDeleteEmployee = (deleteId:string) => {
    const {token} = this.state
    
    const headers = {
    
      token: token,
    };
   const deleteUrls = `${configJSON.getEditTimesheetAPiEndPoint}/${deleteId}`
  
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteDataCallApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      deleteUrls
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
     

  }

  handleEmployeeeStatus = async(status:string , editId:string) => {
    this.setState({ anchorEls: null });
    const {token} = this.state

    this.setState({ currentStatus: status });
    const header = {
      
      token: token,
    };
   const editUrls = `${configJSON.getEditTimesheetAPiEndPoint}/${editId}/change_status`
   const formData = new FormData();
    
   formData.append("status",status);
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.statusEmployeeApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        editUrls
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PATCH"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  createDataTimesheet = () => {
    this.setState({dayData:[]})
    this.setState({ showSelectJobRequestButton: false, saveButton: false, totalHours: "" ,inputValues:{}})
    const { token } = this.state

    const headers = {
      'Content-Type': 'application/json',
      token: token,
    };
    const addTimeSheetUrls = `${configJSON.getAddTimesheetAPiEndPoint}`

    const payload = JSON.stringify({ data: this.state.dayData });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addTimesheetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      addTimeSheetUrls
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      payload
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAllEmployee = async () => {
    
    const headers = {
      "Content-Type": "application/json",
      "token": this.state.token
    };
    const urles = `bx_block_appointment_management/appointments/get_employees`;
         
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.employeeDataCallApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${urles}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getMyTimeSheetDataAPI = async() => {
    const token =await getStorageData("authToken")
      
    const header = {
      token:token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMyTimeSheetApiCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTimeSheetAPIEndPoint}?lang=en&view=weekly`
    ); 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
// Customizable Area End